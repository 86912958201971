import { StreamRef } from '../../model/Video';
import { FaceEmotionsInfo, FaceEmotionsMap } from '../../reducers/reportReducer';
import { logErrorResponse } from '../figaroApiActions';
import {
  ReportActionTypes,
  REPORT_CLIENT_NAME,
  REPORT_COACH_EMAIL,
  REPORT_COACH_NAME,
  REPORT_COACH_ROLE,
  REPORT_COMPANY_ADDRESS,
  REPORT_COMPANY_NAME,
  REPORT_COMPANY_URL,
  REPORT_COVER_LETTER_ENABLED,
  REPORT_COVER_LETTER_INTRO,
  REPORT_COVER_LETTER_SALUTATION,
  REPORT_FACES_INITIAL,
  REPORT_FACE_EMOTIONS_CHART,
  REPORT_FACE_EMOTIONS_TEXT,
  REPORT_FACE_ENABLED,
  REPORT_HL_ADDED,
  REPORT_HL_CHART,
  REPORT_HL_COMMENT_ENABLED,
  REPORT_HL_ENABLED,
  REPORT_HL_PICTURE,
  REPORT_HL_TOGGLED,
  REPORT_SUMMARY_ENABLED,
  REPORT_SUMMARY_TEXT,
  REPORT_VERBAL_EMOTIONS_CHART,
  REPORT_VERBAL_EMOTIONS_TEXT,
  REPORT_VERBAL_ENABLED,
} from './types';

export const setCommentEnabled =
  (highlightId: string, commentId: string, enabled: boolean) => async (dispatch: (action: ReportActionTypes) => void) => {
    try {
      dispatch({
        type: REPORT_HL_COMMENT_ENABLED,
        payload: {
          highlightId,
          commentId,
          enabled,
        },
      });
    } catch (err) {
      logErrorResponse(err, 'setHighlightEnabled failed ');
    }
  };

export const setHighlightEnabled = (highlightId: string, enabled: boolean) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_HL_ENABLED,
      payload: {
        id: highlightId,
        enabled,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setHighlightEnabled failed ');
  }
};

export const setHighlightChart =
  (
    highlightId: string,
    chart: any,
    faceSelections: StreamRef[],
    voiceSelections: StreamRef[],
    seriesColors: { [seriesName: string]: string }
  ) =>
  async (dispatch: (action: ReportActionTypes) => void) => {
    try {
      dispatch({
        type: REPORT_HL_CHART,
        payload: {
          id: highlightId,
          chart,
          faceSelections,
          voiceSelections,
          seriesColors,
        },
      });
    } catch (err) {
      logErrorResponse(err, 'setHighlightChart failed ');
    }
  };
export const setHighlightPicture = (highlightId: string, picture: any) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_HL_PICTURE,
      payload: {
        id: highlightId,
        picture,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setHighlightPicture failed ');
  }
};

export const toggleSelectHighlight4Reporting = (highlightId: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_HL_TOGGLED,
      payload: {
        id: highlightId,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'toggleSelectHighlight4Reporting failed ');
  }
};
export const addHighlight = (highlightId: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_HL_ADDED,
      payload: {
        id: highlightId,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'addHighlight failed ');
  }
};
export const deleteHighlight =
  (videoId: string, highlightId: string, token: string) => async (dispatch: (action: ReportActionTypes) => void) => {};

export const setCoachName = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COACH_NAME,
      payload: {
        coachName: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCoachName failed ');
  }
};
export const setCoachEmail = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COACH_EMAIL,
      payload: {
        coachEmail: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCoachEmail failed ');
  }
};
export const setCoachRole = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COACH_ROLE,
      payload: {
        coachRole: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCoachROle failed ');
  }
};
export const setCompanyName = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COMPANY_NAME,
      payload: {
        companyName: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCompanyName failed ');
  }
};
export const setCompanyAddress = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COMPANY_ADDRESS,
      payload: {
        companyAddress: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCompanyAddress failed ');
  }
};
export const setCompanyUrl = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COMPANY_URL,
      payload: {
        companyUrl: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCompanyUrl failed ');
  }
};

export const setCoverLetterIntro = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COVER_LETTER_INTRO,
      payload: {
        intro: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCoverLetterIntro failed ');
  }
};
export const setClientName = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_CLIENT_NAME,
      payload: {
        clientName: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setClientName failed ');
  }
};
export const setCoverLetterSalutation = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COVER_LETTER_SALUTATION,
      payload: {
        salutation: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCoverLetterSalutation failed ');
  }
};
export const setCoverLetterEnabled = (newValue: boolean) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_COVER_LETTER_ENABLED,
      payload: {
        enabled: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setCoverLetterEnabled failed ');
  }
};

export const setSummaryEnabled = (newValue: boolean) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_SUMMARY_ENABLED,
      payload: {
        enabled: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setSummaryEnabled failed ');
  }
};
export const setSummaryText = (newValue: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_SUMMARY_TEXT,
      payload: {
        text: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setSummaryText failed ');
  }
};

export const setInitialFaceEmotions =
  (
    speakersInfo: {
      analysisIndex: number;
      analysisTag: string;
      analysisFriendlyTag: string;
      speakerHidden: boolean;
    }[]
  ) =>
  async (dispatch: (action: ReportActionTypes) => void) => {
    const rez = {} as FaceEmotionsMap;
    speakersInfo.forEach(
      (speaker) =>
        (rez[speaker.analysisFriendlyTag] = {
          id: speaker.analysisFriendlyTag, //analysisTag
          enabled: false, //
          chart: null, //
          text: '', //
          detailedText: '',
        } as FaceEmotionsInfo)
    );

    try {
      dispatch({
        type: REPORT_FACES_INITIAL,
        payload: {
          map: rez,
        },
      });
    } catch (err) {
      logErrorResponse(err, 'setInitialFaceEmotions failed ');
    }
  };

export const setFaceEmotionsText =
  (newValue: string, faceTag: string, detail: boolean) => async (dispatch: (action: ReportActionTypes) => void) => {
    try {
      dispatch({
        type: REPORT_FACE_EMOTIONS_TEXT,
        payload: {
          text: newValue,
          faceTag: faceTag,
          detail: detail,
        },
      });
    } catch (err) {
      logErrorResponse(err, 'setSummaryFaceText failed ');
    }
  };
export const setFaceEmotionsChart = (newValue: any, faceTag: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_FACE_EMOTIONS_CHART,
      payload: {
        chart: newValue,
        faceTag: faceTag,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setSummaryFaceText failed ');
  }
};
export const setVerbalChart = (newValue: any) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_VERBAL_EMOTIONS_CHART,
      payload: {
        chart: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setVerbalCHart failed ');
  }
};

export const setFaceEmotionsEnabled = (newValue: boolean, faceTag: string) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_FACE_ENABLED,
      payload: {
        enabled: newValue,
        faceTag: faceTag,
      },
    });
    return Promise.resolve();
  } catch (err) {
    logErrorResponse(err, 'setSummaryFaceText failed ');
    return Promise.reject(err);
  }
};

export const setVerbalEnabled = (newValue: boolean) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_VERBAL_ENABLED,
      payload: {
        enabled: newValue,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setVerbalEnabled failed ');
  }
};
export const setVerbalText = (newValue: string, detail: boolean) => async (dispatch: (action: ReportActionTypes) => void) => {
  try {
    dispatch({
      type: REPORT_VERBAL_EMOTIONS_TEXT,
      payload: {
        text: newValue,
        detail,
      },
    });
  } catch (err) {
    logErrorResponse(err, 'setSummaryVoiceText failed ');
  }
};
