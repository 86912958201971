import { Button } from '@material-ui/core';
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { getAuthedHomePath } from '../../../../app';
import sprites from '../../../../icons/fig-icons.svg';
import { graphicsSideWidthUpdated, playerHeightUpdated, setGlobalAlert, windowHeightUpdated } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import {
  deleteSubmissionShare,
  getDatagroups,
  getDisfluencies,
  getGlobalRubrics,
  getSubmissionANDEnhancedOrUploadInfo,
  getSubmissionEnhancedInfo,
  getSubmissionUploadInfo,
  shareSubmission,
  uploadFile,
} from '../../actions/figaroApiActions';
import {
  setFaceAnalysisDrawerVisibility,
  setReportDrawerVisibility,
  setSelectedGraphicsSideTab,
  setSelectedTextSideTab,
  setVoiceAnalysisDrawerVisibility,
} from '../../actions/selectionActions';
import { noAnnotationSelected, noTagCreation, noTagSelected, videoSelected } from '../../actions/videoActions';
import TabsComponent from '../../components/tabs/TabsComponent';
import VideoRenderer, { IVideoPlayer } from '../../components/video/VideoRenderer';
import config from '../../config/constants';
import { AWSUploadFields, Submission, SubmissionState, SubmissionUploadInfo } from '../../model/ApiTypes';
import { formatFigaroDate } from '../../utils/utils';
import AnalysisOptions from '../echarts/AnalysisOptions';
import { Avatars } from '../shared/Avatars';
import SharedWith from '../shareVideo/SharedWith';

type IProps = PropsFromRedux & { match: any; history: any };
interface IState {
  img: any;
  file: File;
  uploading: boolean;
  usersListAnchor: any /* if null, popper with Users is not rendered*/;

  horizontallyResizing: boolean;
  //on Firefox no coordinates clientX, clientY... are available at onDrag events, but they are present on omDragOver
  //workaround: keep trak of coordinates via onDragOver of the parent container
  mouseX: number;
  mouseY: number;
}
class SelectedVideoPanel extends React.PureComponent<IProps, IState> implements IVideoPlayer {
  // videoComponent: React.RefObject<IVideoPlayer> = null;
  videoComponent: React.RefObject<any> = null;
  anchorElement = null; //used as anchor for Users Popper
  containerElement = null; //container for the left/right sides
  constructor(props: IProps) {
    super(props);
    console.log('SelectedVideo ctor,  props ', props);
    this.videoComponent = React.createRef();
    this.state = {
      usersListAnchor: null, //if not null, show Users popper
      img: null,
      file: null,
      horizontallyResizing: false,
      uploading: false,
      mouseX: 0,
      mouseY: 0,
    };
  }

  play(): void {
    if (this.videoComponent.current != null) {
      this.videoComponent.current.play();
    }
  }
  seek(seconds: number, cb?: any): void {
    if (this.videoComponent.current != null) {
      this.videoComponent.current.seek(seconds, cb);
    }
  }
  pause(): void {
    if (this.videoComponent.current != null) {
      this.videoComponent.current.pause();
    }
  }
  hasStarted(): boolean {
    return this.videoComponent.current != null && this.videoComponent.current.hasStarted();
  }
  getBoundingClientRect = () => {
    if (this.videoComponent.current !== null) {
      return this.videoComponent.current.getBoundingClientRect();
    }
    return null;
  };
  getCrtImage = () => {
    if (this.videoComponent.current !== null) {
      return this.videoComponent.current.getCrtImage();
    }
    return null;
  };

  handleGlobalWindowResize = () => {
    const playerRelCoords = this.videoComponent && this.videoComponent.current ? this.videoComponent.current.getBoundingClientRect() : null;
    if (playerRelCoords && this.props.playerHeight !== playerRelCoords.height) {
      console.log(
        'handle window resize,  videoComponentCoords/height/oldHeight: ',
        playerRelCoords,
        playerRelCoords.height,
        this.props.playerHeight
      );
      this.props.playerHeightUpdated(playerRelCoords.height);
    } else {
      this.props.windowHeightUpdated(window.innerHeight);
    }
  };

  componentDidMount = () => {
    console.log('SelectedVideo did mount, loading Submission,  props ', this.props);
    this.loadSubmission();
    window.addEventListener('resize', this.handleGlobalWindowResize);
    this.handleGlobalWindowResize();
    this.forceUpdate(); // rerender with known anchor element
  };
  componentWillUnmount = () => {
    console.log('SelectedVideo will unmount ', this.props);
    window.removeEventListener('resize', this.handleGlobalWindowResize);
    this.clearSelections();
  };

  clearSelections = () => {
    this.props.noAnnotationSelected();
    this.props.noTagSelected();
    this.props.noTagCreation();
  };

  componentDidUpdate(prevProps: IProps, prevState: IState) {
    const id = Number.parseInt(this.props.match.params.id, 10);
    const prevId = Number.parseInt(prevProps.match.params.id, 10);
    if (id !== prevId) {
      // new ID received directly from the browser
      this.clearSelections();
      this.loadSubmission();
    } else if (this.props.graphicsSideWidth !== prevProps.graphicsSideWidth) {
      const playerRelCoords =
        this.videoComponent && this.videoComponent.current ? this.videoComponent.current.getBoundingClientRect() : null;
      if (playerRelCoords) {
        console.log('after sidesSeparatorPos updated,  videoComponentCoords/height: ', playerRelCoords, playerRelCoords.height);
        this.props.playerHeightUpdated(playerRelCoords.height);
      }
    }
  }

  loadSubmission() {
    const id = Number.parseInt(this.props.match.params.id, 10);
    if (id && this.props.submissions && this.props.submissions.submissionsMap[id]) {
      const submission = this.props.submissions.submissionsMap[id];
      if (submission.state === SubmissionState.available || submission.state === SubmissionState.processing) {
        // TODO FFS refesh submission meta everytime?
        this.props.videoSelected(id); // better make sure we set this , in case route came directly  from browser
        this.props.getSubmissionEnhancedInfo(this.props.submissions.submissionsMap[id], this.props.authentication.token);
      } else if (submission.state === SubmissionState.requested) {
        this.props.getSubmissionUploadInfo(this.props.submissions.submissionsMap[id], this.props.authentication.token);
        //TODO FFS on this if branch we have both metainfo and uploadinfo for the submission
        // but on the other we'll just have uploadinfo, is there any problem ?
      }
    } else {
      this.loadSettings();
      if (id) {
        this.props.videoSelected(id); // better make sure we set this , in case route came directly  from browser
        this.props.getSubmissionANDEnhancedOrUploadInfo(id, this.props.authentication.token);
      } else {
        this.props.history.push(getAuthedHomePath());
      }
    }
  }

  loadSettings() {
    if (!this.props.disfluenciesLoaded) this.props.getDisfluencies(this.props.authentication.token);
    if (!this.props.dataGroupsLoaded) this.props.getDatagroups(this.props.authentication.token);
    if (!this.props.globalRubricsLoaded) this.props.getGlobalRubrics(this.props.authentication.token);
  }

  renderUploadForm(submissionUploadInfo: SubmissionUploadInfo) {
    if (submissionUploadInfo) {
      let hasRight2Upload = submissionUploadInfo.user.id === this.props.authentication.user.id;
      return (
        <div className="row no-gutters">
          <div className="col-12">
            <div className="fig-upload-form-header">
              <span> {hasRight2Upload ? 'Upload Video' : 'Requested Video Details'}</span>
            </div>
            <div className="fig-upload-form-content col">
              <div className="row">
                <div className="col-6">{this.renderSubmissionInfo(submissionUploadInfo)}</div>
                {hasRight2Upload ? (
                  <div className="col-6">
                    <form className="margin-top--60" onSubmit={this.onUploadVideo(submissionUploadInfo)}>
                      <input
                        className="col-6 "
                        type="file"
                        name="file"
                        accept="video/*"
                        onChange={this.onFileChange}
                        disabled={this.state.uploading}
                      />
                      {/* <label>
                        <input style={{ display: 'none' }} accept='video/*' type="file" onChange={(e) => this.onFileChange(e)} />
                        <Fab color="secondary" size="small" component="span" aria-label="add" variant="extended">
                          <AddIcon /> Select Video 
                        </Fab>
                      </label> */}

                      {/* <Input
                        id="import-button"
                        inputProps={{
                          accept: 'video/*',
                        }}
                        onChange={this.onFileChange}
                        type="file"
                        style={{ display: 'none' }}
                      /> */}

                      <Button
                        className={this.state.uploading ? 'col-6 fig-button rubric-primary opacity-5' : 'fig-button rubric-primary col-6'}
                        disabled={!this.state.file || this.state.uploading}
                        onClick={this.onUploadVideo(submissionUploadInfo)}
                      >
                        {this.state.uploading ? 'Uploading' : 'Upload'}
                      </Button>
                      <div
                        style={{
                          fontSize: '1.875rem',
                          fontWeight: 600,
                          color: '#cccccc',
                          display: 'flex',
                          marginTop: '10%',
                        }}
                      >
                        {this.state.uploading ? `UPLOADING ${this.state.file.name} ...` : ''}
                      </div>
                    </form>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      );
    } else
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
          }}
        >
          UPLOAD FORM
        </div>
      );
  }

  renderSubmissionInfo(submissionUploadInfo: SubmissionUploadInfo | Submission) {
    console.log('  renderUploadSubmissionInfo ', this.props);
    return (
      <div className="row no-gutters">
        <div className="col-12">
          <div className="fig-upload-form-text-row">
            <span>Title:</span> <span>{submissionUploadInfo.name}</span>
          </div>
          <div className="fig-upload-form-text-row">
            <span>Instructions:</span> <span></span>
          </div>
          <textarea rows={10} className="fig-upload-form-textarea" value={submissionUploadInfo.details} disabled />
          <div className="fig-upload-form-text-row">
            <span>Deadline:</span> <span>{formatFigaroDate(submissionUploadInfo.deadline)}</span>
          </div>
          <div className="fig-upload-form-text-row">
            <span>Requested By:</span>{' '}
            <span>
              {submissionUploadInfo.createdby.username}
              {' on '}
              {formatFigaroDate(submissionUploadInfo.created_at)}
            </span>
          </div>
          <div className="fig-upload-form-text-row">
            <span>Updated:</span> <span>{formatFigaroDate(submissionUploadInfo.updated_at)}</span>
          </div>
          <div className="fig-upload-form-text-row">
            <span>User:</span> <span>{submissionUploadInfo.user ? submissionUploadInfo.user.username : submissionUploadInfo.email}</span>
          </div>
        </div>
      </div>
    );
  }

  onFileChange = (e) => {
    let files = e.target.files;
    if (files && files.length) {
      this.setState({ img: null, file: files[0] });
      //TODO FFS IS READING NEEDED ?
      // let reader = new FileReader();
      // reader.onload = (img) => {
      //   console.warn('onload img data', img.target.result);
      //   this.setState({ img: img.target.result, file: files[0] });
      // };  //onAbort, onErr?
      // reader.readAsDataURL(files[0]);
    } else {
      this.setState({ img: null, file: null });
    }
  };

  onUploadVideoAfterReading = (submissionUploadInfo: SubmissionUploadInfo) => (event) => {
    let info: AWSUploadFields = submissionUploadInfo.analysis['aws-upload'];
    if (this.state.img) {
      let file = this.state.file;
      console.warn('try upload  ', file);
      this.setState({ uploading: true }, () => {
        this.props.uploadFile(file, info, submissionUploadInfo.id, this.props.authentication.token).then(
          (okId) => {
            console.warn('after upload, success!');
            this.setState({ uploading: false });
          },
          (err) => {
            console.log('after upload attempt,  error: ', err);
            this.setState({ uploading: false });
          }
        );
      });
    } else {
      console.warn('ERR no current file');
    }
  };

  static MAX_UPLOAD_SIZE = 1048576 * 500;
  onUploadVideo = (submissionUploadInfo: SubmissionUploadInfo) => /* async */ (event) => {
    let info: AWSUploadFields = submissionUploadInfo.analysis['aws-upload'];
    if (this.state.uploading) return;
    if (this.state.file) {
      let file = this.state.file;
      if (this.state.file.size <= SelectedVideoPanel.MAX_UPLOAD_SIZE) {
        console.warn('trying upload ', file);
        this.setState({ uploading: true }, () => {
          this.props.uploadFile(file, info, submissionUploadInfo.id, this.props.authentication.token).then(
            (okId) => {
              console.warn('after upload, success');
              this.setState({ uploading: false });
            },
            (err) => {
              console.log('after upload,  err ', err);
              this.setState({ uploading: false });
            }
          );
        });
      } else {
        this.props.setGlobalAlert('Maximum allowed file size is ' + SelectedVideoPanel.MAX_UPLOAD_SIZE);
      }
    } else {
      console.warn('ERR no file selected');
    }
  };

  renderProcessingForm(submissionInfo: Submission) {
    return (
      <div className="row no-gutters">
        <div className="col-12">
          <div className="fig-upload-form-header">
            <span> Processing video. Please check status later</span>
          </div>
          <div className="fig-upload-form-content col-6">{this.renderSubmissionInfo(submissionInfo)}</div>
        </div>
      </div>
    );
  }

  // renderOldWithOnlyAvailableSubmissions() {
  //   console.log('SelectedVideo render ', this.props);
  //   const id = Number.parseInt(this.props.match.params.id, 10);
  //   if (!id || !this.props.submissions || !this.props.submissions.submissionsMap[id]) {
  //     return null;
  //   }

  //   const options = {
  //     year: 'numeric',
  //     month: 'short',
  //     day: 'numeric',
  //   } as Intl.DateTimeFormatOptions;
  //   const optionsTime = {
  //     hour: 'numeric',
  //     minute: '2-digit',
  //     hour12: false,
  //   } as Intl.DateTimeFormatOptions;

  //   return (
  //     <div
  //       className="row"
  //       style={{
  //         cursor: this.props.currentAnnotatingStatus || this.props.currentTaggingStatus ? 'crosshair' : 'default',
  //       }}
  //     >
  //       <div className="col-8 no-padding">
  //         <div className="fig-video-details-left-side">
  //           <div className="fig-video-details-left-side__header">
  //             <div className="fig-direction-column">
  //               <span className="fig-page-title">{this.props.selectedVideo ? this.props.selectedSubmission.name : ''}</span>
  //               <span className="fig-date">
  //                 {this.props.selectedVideo
  //                   ? `${new Date(this.props.selectedSubmission.updated_at).toLocaleDateString(undefined, options)} @ ${new Date(
  //                       this.props.selectedSubmission.updated_at
  //                     ).toLocaleTimeString(undefined, optionsTime)}`
  //                   : ''}
  //               </span>
  //             </div>
  //             <div className="fig-icons" onClick={this.closeUsersList}>
  //               <div
  //                 ref={(e) => {
  //                   this.anchorElement = e;
  //                 }}
  //               >
  //                 <Avatars
  //                   hasToolTip={this.state.usersListAnchor === null}
  //                   onClick={this.toggleUsersList}
  //                   style={{
  //                     width: 83,
  //                     height: 32,
  //                     fill: 'none',
  //                     margin: 'auto',
  //                     display: 'block',
  //                     marginLeft: 'auto',
  //                     marginRight: 'auto',
  //                   }}
  //                   viewBox="0 0 83 32"
  //                 />
  //               </div>
  //               <SharedWith
  //                 anchorRef={this.state.usersListAnchor} /* if null, popper is not rendered*/
  //                 sharesMap={this.props.selectedVideo.submissionSharesMap}
  //                 submission={this.props.selectedSubmission}
  //                 token={this.props.currentToken}
  //                 removeShare={(id) => this.props.deleteSubmissionShare(id, this.props.currentToken)}
  //                 addShare={this.addShare}
  //               ></SharedWith>
  //             </div>
  //           </div>
  //           <div>
  //             <TabsComponent theVideoPlayer={this} initialSelected={0} graphicsSide />
  //           </div>
  //         </div>
  //       </div>
  //       <div className="col-4 no-padding">
  //         <VideoRenderer ref={this.videoComponent} />
  //         <div className="fig-video-details-right-side__transcript">
  //           <TabsComponent theVideoPlayer={this} initialSelected={0} graphicsSide={false} />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  toggleUsersList = (event) => {
    console.log('toggleUSersList called on SelectedVideoPanel, event: ', event);
    const opened = this.state.usersListAnchor !== null;
    this.setState({ usersListAnchor: opened ? null : this.anchorElement });
  };
  closeUsersList = (event) => {
    console.log('closeUSersList called on SelectedVideoPanel, event: ', event);
    if (this.state.usersListAnchor) this.setState({ usersListAnchor: null });
  };
  addShare = (addresses: string[]) => {
    addresses &&
      addresses.forEach((address) => {
        this.props.shareSubmission(this.props.selectedSubmission.id, address, this.props.currentToken);
      });
  };
  render() {
    console.log('SelectedVideo render ', this.props);
    const id = Number.parseInt(this.props.match.params.id, 10);
    if (
      !id ||
      !this.props.submissions ||
      (!this.props.submissions.submissionsMap[id] && !this.props.submissions.submissionsUploadInfosMap[id])
    ) {
      return null;
    }
    if (this.props.submissions.submissionsMap[id]) {
      const submission = this.props.submissions.submissionsMap[id];
      if (submission.state === SubmissionState.requested) {
        return this.renderUploadForm(this.props.submissions.submissionsUploadInfosMap[id]);
      } else if (submission.state === SubmissionState.processing) {
        return this.renderProcessingForm(submission);
      } else {
        const sharingAllowed = submission.createdby.id === this.props.authentication.user.id;

        let showGraphOptions =
          this.props.selectedGraphicsSideTab === TabsComponent.TAB_IDX_ANALYSIS &&
          this.props.selectedSubmission &&
          ((this.props.selectedLegendTab < this.props.speakersCount && this.props.faceRightMenuOpen) ||
            (this.props.selectedLegendTab === this.props.speakersCount && this.props.voiceRightMenuOpen));
        let showReport =
          this.props.selectedGraphicsSideTab === TabsComponent.TAB_IDX_REPORT &&
          this.props.selectedSubmission &&
          this.props.reportRightMenuOpen;
        return (
          <div
            className="row"
            style={{
              cursor:
                this.props.currentAnnotatingStatus || this.props.currentTaggingStatus || this.props.currentTagCreationStatus
                  ? 'crosshair'
                  : // : this.state.horizontallyResizing
                    // ? 'move'
                    'default',
              position: 'relative',
            }}
            // onMouseMove={this.recalcHorizontalPosition}
            ref={(e) => {
              this.containerElement = e;
            }}
            onDragOver={(event: any) => {
              //console.log('dragover event/X/Y', event, event.clientX, event.clientY);
              this.setState({ mouseX: event.clientX, mouseY: event.clientY });
            }}
          >
            <div
              className="vertical-resize-iconbox"
              id="resizeHandle"
              style={{
                position: 'absolute',
                //left: `${this.props.graphicsSideWidth}%`,
                // width: 4,
                // height: '100%',
                left: `calc(${this.props.graphicsSideWidth}% - 16px)`,
                zIndex: 200,
              }}
              draggable
              //onClick={this.toggleResizeState}
              onDrag={(event) => {
                console.log('SelectedVideoPanel onDrag, ', event);
                this.recalcSidesSeparatorPosition(event);
              }}
              onDragStart={(event) => {
                console.log('SelectedVideoPanel onDragStart, ', event);
                this.activateHorizontallyResizing(event);
              }}
              onDragEnd={(event) => {
                console.log('SelectedVideoPanel onDragEnd, ', event);
                this.recalcSidesSeparatorPosition(event);
                this.stopHorizontallyResizing(event);
              }}
            >
              <svg className="fig-resize-icon">
                <use href={sprites + '#resize-icon-y'} />
              </svg>
            </div>
            {/* <div
              id="resizeHandle"
              style={{
                position: 'absolute',
                left: `${this.state.horizontalPos}%`,
                top: '50%',
                minWidth: 20,
                backgroundColor: this.state.horizontallyResizing ? 'green' : 'pink',
                zIndex: 200,
              }}
              draggable
              // onMouseDown={this.activateHorizontallyResizing}
              // onMouseUp={this.stopHorizontallyResizing}
              onClick={this.toggleResizeState}
              
              //are nevoie si de onMouseMove/recalcHorizontalPosition pe mouseMove in parent
            >
              o
            </div> */}
            <div
              // className="col-8 no-padding"
              className="no-padding"
              style={{
                flex: `0 0 ${this.props.graphicsSideWidth}%`,
                maxWidth: `${this.props.graphicsSideWidth}%`,
                //minWidth: `20%`,
              }}
            >
              <div className="fig-video-details-left-side">
                <div className="fig-video-details-left-side__header">
                  <div className="fig-direction-column">
                    <span className="fig-page-title">{this.props.selectedSubmission ? this.props.selectedSubmission.name : ''}</span>
                    <span className="fig-date">
                      {this.props.selectedSubmission ? formatFigaroDate(this.props.selectedSubmission.updated_at) : ''}
                    </span>
                  </div>
                  <div className={`fig-icons  ${sharingAllowed ? '' : 'hide'}`} /*onClick={this.closeUsersList}*/>
                    <div
                      ref={(e) => {
                        this.anchorElement = e;
                      }}
                    >
                      <Avatars hasToolTip={this.state.usersListAnchor === null} onClick={this.toggleUsersList} />
                    </div>
                    <SharedWith
                      anchorRef={this.state.usersListAnchor} /* if null, popper is not rendered*/
                      sharesMap={this.props.selectedVideoSubmissionShareMap ? this.props.selectedVideoSubmissionShareMap : {}}
                      submission={this.props.selectedSubmission}
                      token={this.props.currentToken}
                      removeShare={(id) => this.props.deleteSubmissionShare(id, this.props.currentToken)}
                      addShare={this.addShare}
                      setGlobalAlert={this.props.setGlobalAlert}
                    ></SharedWith>
                  </div>
                </div>
                <div>
                  <TabsComponent
                    theVideoPlayer={this}
                    initialSelected={this.props.selectedGraphicsSideTab}
                    graphicsSide
                    handleChangeTab={this.handleChangeGraphicsSideTab}
                    reporting={submission.createdby.id === this.props.authentication.user.id}
                  />
                </div>
              </div>
            </div>
            <div
              //className="col-4 no-padding"
              className="no-padding"
              style={{
                flex: `0 0 ${100 - this.props.graphicsSideWidth}%`,
                maxWidth: `${100 - this.props.graphicsSideWidth}%`,
                visibility: showGraphOptions || showReport ? 'hidden' : 'visible',
                //minWidth: `20%`,
              }}
            >
              <VideoRenderer ref={this.videoComponent} hidden={showGraphOptions || showReport} />
              <div className="fig-video-details-right-side__transcript">
                <TabsComponent
                  theVideoPlayer={this}
                  initialSelected={this.props.selectedTextSideTab}
                  graphicsSide={false}
                  handleChangeTab={this.handleChangeTextSideTab}
                  reporting={submission.createdby.id === this.props.authentication.user.id}
                />
              </div>
            </div>
            {this.renderDrawer()}
          </div>
        );
      }
    } else if (this.props.submissions.submissionsUploadInfosMap[id]) {
      const submissionUploadInfo = this.props.submissions.submissionsUploadInfosMap[id];
      if (submissionUploadInfo.state === SubmissionState.requested) {
        return this.renderUploadForm(submissionUploadInfo);
      }
    }
  }
  handleChangeGraphicsSideTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.props.setSelectedGraphicsSideTab(newValue);
  };
  handleChangeTextSideTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.props.setSelectedTextSideTab(newValue);
  };

  renderDrawer = () => {
    if (this.props.selectedGraphicsSideTab === TabsComponent.TAB_IDX_ANALYSIS /*todo refactor to be internal to Ugraph/legend etc*/) {
      if (this.props.selectedLegendTab < this.props.speakersCount && this.props.faceRightMenuOpen) {
        return (
          <div
            className="col no-padding"
            style={{
              left: `${this.props.graphicsSideWidth}%`,
              top: 0,
              maxWidth: `${100 - this.props.graphicsSideWidth}%`,
              position: 'absolute',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <AnalysisOptions
              isFaceAnalysis={true}
              toggleDrawer={() => this.props.setFaceAnalysisDrawerVisibility(!this.props.faceRightMenuOpen)}
            />
          </div>
        );
      } else if (
        this.props.selectedSubmission &&
        this.props.selectedLegendTab === this.props.speakersCount &&
        this.props.voiceRightMenuOpen //todo refactor to be internal to Ugraph/legend etc
      ) {
        return (
          <div
            className="col no-padding"
            style={{
              left: `${this.props.graphicsSideWidth}%`,
              top: 0,
              maxWidth: `${100 - this.props.graphicsSideWidth}%`,
              position: 'absolute',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <AnalysisOptions
              isFaceAnalysis={false}
              toggleDrawer={() => this.props.setVoiceAnalysisDrawerVisibility(!this.props.voiceRightMenuOpen)}
            />
          </div>
        );
      }
    } else if (this.props.selectedGraphicsSideTab === TabsComponent.TAB_IDX_REPORT) {
      if (this.props.selectedSubmission && this.props.reportRightMenuOpen) {
        return (
          <div
            className="col no-padding"
            style={{
              left: `${this.props.graphicsSideWidth}%`,
              top: 0,
              maxWidth: `${100 - this.props.graphicsSideWidth}%`,
              position: 'absolute',
              height: '100%',
              overflowY: 'auto',
            }}
          >
            <div className="fig-legend-options-pannel">
              <div className="pannel-title-mainbox">
                <div className="pannel-title">{`${config.dev.simulateReportView ? 'HTML' : ''} Report Preview`}</div>
                <div>
                  <Button
                    onClick={() => {
                      this.props.setReportDrawerVisibility(false);
                    }}
                  >
                    <svg className="fig-close-icon">
                      <use href={sprites + '#close-icon'} />
                    </svg>
                  </Button>
                </div>
              </div>

              {config.dev.simulateReportView ? (
                this.props.pdfReport && this.props.pdfReport.simulator ? (
                  this.props.pdfReport.simulator
                ) : null
              ) : (
                <PDFViewer style={{ width: '100%', height: 'calc(100vh - 174px)' }}>
                  {this.props.pdfReport && this.props.pdfReport.report ? this.props.pdfReport.report : null}
                </PDFViewer>
              )}
            </div>
          </div>
        );
      }
    }
    return null;
  };

  recalcSidesSeparatorPosition = (e) => {
    //console.log('recalclegendSeparatorPos event/clientX/clientY ', e, e.clientX, e.clientY);
    const containerRelCoords = this.containerElement ? this.containerElement.getBoundingClientRect() : null;
    // console.log('recalcSidesSeparatorPosition event.clientX ', e.clientX, 'isResizing: ', this.state.horizontallyResizing);
    console.log(
      'recalcSidesSeparatorPosition containerCoords/x/width: ',
      containerRelCoords,
      containerRelCoords.x,
      containerRelCoords.width
    );
    const y = e.clientY ? e.clientY : this.state.mouseY; //on Firefox no coordinates clientX, clientY... are available
    const x = e.clientX ? e.clientX : this.state.mouseX;

    if (containerRelCoords.width) {
      let pos = (x - containerRelCoords.x) / containerRelCoords.width;
      pos = 100 * pos;
      if (pos < config.graph.minWidth) {
        //console.log('forcing min sidesSeparatorPos ', SelectedVideoPanel.MIN_WIDTH);
        pos = config.graph.minWidth;
      } else if (pos > 100 - config.graph.minWidth) {
        console.log('forcing max sidesSeparatorPos ', 100 - config.graph.minWidth);
        pos = 100 - config.graph.minWidth;
      }

      if (this.state.horizontallyResizing && !(x === 0 && y === 0) && pos >= 0 && pos !== this.props.graphicsSideWidth) {
        //console.log('recalcedSidesSeparatorPosition %: ', pos);
        this.props.graphicsSideWidthUpdated(pos);
      }
    }
  };
  activateHorizontallyResizing = (event) => {
    console.log('activateResize ....', event);
    this.setState({ horizontallyResizing: true });
  };
  stopHorizontallyResizing = (event) => {
    console.log('stopResize ....', event);
    this.setState({ horizontallyResizing: false });
  };
  toggleResizeState = (event) => {
    console.log('toggleResize ....', event);
    let old = this.state.horizontallyResizing;
    this.setState({ horizontallyResizing: !old });
  };
}

const selectorVideo = (state: IRootState) => {
  return state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
    ? state.video.videosMap[state.video.currentVideoId]
    : null;
};

const selectedVideoSubmissionShareMapSelector = (state: IRootState) => {
  return state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
    ? state.video.videosMap[state.video.currentVideoId].submissionSharesMap
    : null;
};

const speakersCount = (state: IRootState) => {
  const video = selectorVideo(state);
  return video && video.tag2FaceAnalysisIdx ? Object.keys(video.tag2FaceAnalysisIdx).length : 0;
};

// const SPEAKERS_NONE = [] as string[];
// const retrieveSpeakers = createSelector([selectorVideo], (video) =>
//   video && video.tag2FaceAnalysisIdx ? Object.keys(video.tag2FaceAnalysisIdx) : SPEAKERS_NONE
// );

const mapStateToProps = (state: IRootState) => {
  console.log('SelectedVideoPanel mapStateToProps ', state);
  return {
    currentAnnotatingStatus: state.video.currentAnnotatingStatus && true,
    currentTaggingStatus: state.video.currentTaggingStatus && true,
    currentTagCreationStatus: state.video.currentTagCreationStatus && true,
    authentication: state.authentication,
    submissions: state.submissions,
    disfluenciesLoaded: state.settings.disfluencies && true,
    globalRubricsLoaded: state.settings.globalRubricsMap && true,
    dataGroupsLoaded:
      state.settings.allFaceCategories &&
      state.settings.allVoiceCategories &&
      Object.keys(state.settings.allFaceCategories).length > 0 &&
      Object.keys(state.settings.allVoiceCategories).length > 0,

    selectedVideoSubmissionShareMap: selectedVideoSubmissionShareMapSelector(state),
    //speakers: retrieveSpeakers(state),
    speakersCount: speakersCount(state),
    selectedSubmission: state.video.currentVideoId ? state.submissions.submissionsMap[state.video.currentVideoId] : null,
    currentToken: state.authentication.token,
    playerHeight: state.utils.playerHeight,
    graphicsSideWidth: state.utils.graphicsSideWidth,

    selectedGraphicsSideTab: state.selections.selectedGraphicsSideTab,
    selectedTextSideTab: state.selections.selectedTextSideTab,
    faceRightMenuOpen: state.selections.faceAnalysisOptionsDrawerOpened,
    voiceRightMenuOpen: state.selections.voiceAnalysisOptionsDrawerOpened,
    reportRightMenuOpen: state.selections.reportDrawerOpened,
    selectedLegendTab: state.selections.selectedLegendTab,

    height: state.utils.windowHeight,
    pdfReport: state.selections.pdfDoc,
  };
};

const mapDispatchToProps = {
  noAnnotationSelected,
  noTagSelected,
  videoSelected,
  noTagCreation,
  getSubmissionEnhancedInfo,
  getSubmissionANDEnhancedOrUploadInfo,
  getSubmissionUploadInfo,
  getDisfluencies,
  getDatagroups,
  getGlobalRubrics,
  deleteSubmissionShare,
  shareSubmission,
  uploadFile,
  playerHeightUpdated,
  windowHeightUpdated,
  setSelectedGraphicsSideTab,
  setSelectedTextSideTab,
  graphicsSideWidthUpdated,
  setVoiceAnalysisDrawerVisibility,
  setFaceAnalysisDrawerVisibility,
  setReportDrawerVisibility,
  setGlobalAlert,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(SelectedVideoPanel);
