import { FormControl, Select } from '@material-ui/core';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import sprites from '../../../../icons/fig-icons.svg';
import { IRootState } from '../../../shared/reducers';
import { Evaluation } from '../../actions/figaroApiActions/types';
import { Category, EvaluationResponse, SubmissionRubric, SUser } from '../../model/ApiTypes';
import { computeEvaluationsByUsers, EvaluationsMap, getCategoryAverage, SubmissionRubricsMap } from '../../model/Video';
import { ISelectableItem } from '../shared/BorderedSelector';
import BorderedSelectorSquares from '../shared/BorderedSelectorSquares';

interface IProps extends PropsFromRedux {}
interface IState {
  selectedRubricIdx: number;
  selectedRubricId: number;
  usersListAnchor: any /* if null, popper with Users is not rendered*/;
  selectedUsersIds: string[];
  fakeEvaluationAvg: EvaluationResponse; // the values for the Average column
  usersPassedToSelectorForm: SUser[];
}

class Matrix extends React.PureComponent<IProps, IState> {
  anchorElement = null; //used as anchor for Users Popper
  constructor(props: IProps) {
    super(props);
    console.log('Matrix c-tor');
    const hasEvaluations = props.evaluations && props.evaluations.length > 0;
    this.state = {
      selectedRubricIdx: hasEvaluations ? 0 : undefined,
      selectedRubricId: hasEvaluations ? props.evaluations[0].rubricInfo.id : null,
      usersPassedToSelectorForm: hasEvaluations ? this.getAllEvaluators(this.props.evaluations[0].evaluations) : [],
      usersListAnchor: null, //if not null, show Users popper
      selectedUsersIds: [],
      //FFS POC evaluations
      fakeEvaluationAvg: hasEvaluations ? this.getFakeEvaluationAvg(0) : null,
    };
  }

  getAllEvaluators = (evaluations: EvaluationsMap) => {
    return evaluations ? this.addFakeUsers(this.getUsersList(evaluations)) : [];
  };

  toggleUsersList = () => {
    const opened = this.state.usersListAnchor !== null;
    this.setState({ usersListAnchor: opened ? null : this.anchorElement });
  };
  closeUsersList = () => {
    if (this.state.usersListAnchor) this.setState({ usersListAnchor: null });
  };
  componentDidMount() {
    console.log('Matrix did mount');
    // window.addEventListener('resize', this.handleGlobalWindowResize);
    if (!isNaN(this.state.selectedRubricIdx)) {
      //FFS POC evaluation - show just averages by default
      // this.selectedUsersChanged(this.state.usersPassedToSelectorForm.map((user) => `${user.id}`)); //to compute needed averages and show all users scoes and averages
      this.selectedUsersChanged([`${this.FAKE_USER_AVERAGE_ID}`]);
    } else this.forceUpdate(); // rerender with known anchor element
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    console.log('Matrix did update: prev props, state: ', prevProps, prevState, 'crt props, state: ', this.props, this.state);
    if (
      //same selected rubric
      this.hasConsistentSelection() &&
      this.hadConsistentSelection(prevState, prevProps) &&
      this.state.selectedRubricId === prevState.selectedRubricId
    ) {
      if (
        // evaluations of the crtly selected rubric changed
        this.props.evaluations[this.state.selectedRubricIdx].evaluations !== prevProps.evaluations[prevState.selectedRubricIdx].evaluations
      ) {
        let oldSelectdUsers = this.state.selectedUsersIds;
        let allCrtUsers = this.getAllEvaluators(this.props.evaluations[this.state.selectedRubricIdx].evaluations);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let allCrtUsersIds = allCrtUsers.map((entry) => `${entry.id}`);
        this.usersChanged(
          allCrtUsers,
          // todo ffs uncomment this if preference is to keep just common users of old and new state
          this.getCommonUsers(
            oldSelectdUsers,
            allCrtUsers.map((entry) => `${entry.id}`)
          )
          //FFS POC evaluations - uncomment this to show all users
          //allCrtUsersIds
        );
      } else if (
        this.props.evaluations[this.state.selectedRubricIdx].rubricInfo !== prevProps.evaluations[prevState.selectedRubricIdx].rubricInfo
      ) {
        //FFS POC evaluations - currently rubricInfo is not updated at evaluation save/delete, just at video refresh
        console.log('updating rubric averages');
        let fakeAvg = this.getFakeEvaluationAvg(this.state.selectedRubricIdx);
        this.setState({ fakeEvaluationAvg: fakeAvg });
      }
      return;
    } else if (!this.hasConsistentSelection() && this.props.evaluations.length) {
      //no rubric is selected, select first
      this.selectRubricByIdx(0);
    }
  }

  handleGlobalWindowResize = () => {
    this.forceUpdate();
  };

  componentWillUnmount = () => {
    // window.removeEventListener('resize', this.handleGlobalWindowResize);
  };

  getCommonUsers = (oldIds: string[], newIds: string[]) => {
    console.log('getComonUsrs called: ', oldIds, newIds);
    oldIds = [...oldIds].sort();
    newIds = [...newIds].sort();
    let rez = [] as string[];
    oldIds.forEach((oldId) => {
      if (newIds.includes(oldId)) {
        rez.push(oldId);
      }
    });
    console.log('getComonUsrs return: ', rez);
    return rez;
  };

  selectRubricNone = () => {
    this.setState({
      selectedRubricIdx: undefined,
      selectedRubricId: null,
      selectedUsersIds: [],
      usersPassedToSelectorForm: [],
      usersListAnchor: null,
    });
  };
  selectRubricByIdx = (idx: number) => {
    const hasEvaluations = this.props.evaluations && this.props.evaluations.length > idx;
    if (hasEvaluations && !isNaN(idx) && idx >= 0) {
      let allUsers = this.getAllEvaluators(this.props.evaluations[idx].evaluations);
      this.setState(
        {
          selectedRubricIdx: idx,
          selectedRubricId: this.props.evaluations[idx].rubricInfo.id,
          selectedUsersIds: [],
          usersPassedToSelectorForm: allUsers,
        },
        () => {
          // this.selectedUsersChanged(allUsers.map((user) => `${user.id}`));
          //FFS POC evaluations - show just averages by default
          this.selectedUsersChanged([`${this.FAKE_USER_AVERAGE_ID}`]);
        }
      );
    } else {
      this.selectRubricNone();
    }
  };
  selectRubricById = (id: number) => {
    const hasEvaluations = this.props.evaluations && this.props.evaluations.length > 0;
    if (hasEvaluations && !isNaN(id)) {
      let idx = this.props.evaluations.findIndex((evaluation) => evaluation.rubricInfo.id === id);
      if (idx >= 0) {
        this.selectRubricByIdx(idx);
        return;
      }
    }
    this.selectRubricNone();
  };

  hasConsistentSelection = () => {
    let rez =
      !isNaN(this.state.selectedRubricIdx) &&
      this.props.evaluations.length > this.state.selectedRubricIdx &&
      this.state.selectedRubricIdx >= 0 &&
      this.state.selectedRubricId === this.props.evaluations[this.state.selectedRubricIdx].rubricInfo.id;
    return rez;
  };
  hadConsistentSelection = (state, props) => {
    let rez =
      !isNaN(state.selectedRubricIdx) &&
      props.evaluations.length > state.selectedRubricIdx &&
      state.selectedRubricIdx >= 0 &&
      state.selectedRubricId === props.evaluations[state.selectedRubricIdx].rubricInfo.id;
    return rez;
  };

  render() {
    console.log(' render Matrix ', this.props, this.state);
    //TODO FFS COMPUTE ADI CSS
    let height = window.innerHeight - 90 - 90 - 45 - 20;
    if (height < 0) height = 30;
    return this.props.currentVideoId ? (
      <div className="row" style={{ width: '100%' }}>
        <div className="col-12 no-padding">
          <div className="fig-video-details-matrix-selects-row">
            <FormControl>
              <Select
                native
                labelId="select-rubric-label"
                id="select-rubric"
                value={this.hasConsistentSelection() ? this.state.selectedRubricIdx : -1}
                onChange={(event) => this.selectRubricByIdx(event.target.value as number)}
                style={{ fontWeight: 'bold', background: 'white', fontSize: 'smaller' }}
              >
                {/* Select will display the first option anyway when the value is not one of the options
                so we make a fake first option whose text will be displayed in this case, but we hide it from the options */}
                {!this.hasConsistentSelection() && (
                  <option value="-1" disabled style={{ display: 'none' }} key={`_${-1}`}>
                    (No rubric selected)
                  </option>
                )}
                {this.props.evaluations.map((evaluation, idx) => (
                  <option value={idx} key={`${evaluation.rubricInfo.id}_${idx}`}>
                    {evaluation.rubricInfo.name}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl
              style={{ paddingLeft: 30 }}
              ref={(e) => {
                this.anchorElement = e;
              }}
            >
              <Select
                native={false}
                labelId="select-user-label"
                id="select-user"
                value={-1}
                open={false}
                onOpen={this.toggleUsersList}
                style={{ fontWeight: 'bold', background: 'white', fontSize: 'smaller' }}
              >
                {
                  <option value="-1" disabled style={{ display: 'none' }} key={`_${-1}`}>
                    {this.state.usersListAnchor ? 'Show Evaluator' : 'Show Evaluator'}
                  </option>
                }
              </Select>
            </FormControl>
          </div>
          {this.renderUsersSelectorBordered()}
          <div className="row fig-video-details-matrix-main-container" onClick={this.closeUsersList} style={{ height, overflowY: 'auto' }}>
            {this.hasConsistentSelection() ? (
              <>{this.renderEvaluationForRubric(this.props.evaluations[this.state.selectedRubricIdx])}</>
            ) : null}
          </div>
        </div>
      </div>
    ) : null;
  }

  getUsersForSelector = () => {
    return this.hasConsistentSelection()
      ? this.state.usersPassedToSelectorForm.map((user) => {
          return {
            key: `${user.id}`,
            displayedValue: user.username,
            object: { user },
            selected: this.state.selectedUsersIds.includes(`${user.id}`) ? true : false,
          } as ISelectableItem;
        })
      : [];
  };

  toggleUserSelection = (key, object) => {
    //we need to preserve the order
    let rez = [];
    this.state.usersPassedToSelectorForm.forEach((user) => {
      if (key === `${user.id}`) {
        if (!this.state.selectedUsersIds.includes(key)) {
          rez.push(`${user.id}`);
        }
      } else {
        if (this.state.selectedUsersIds.includes(`${user.id}`)) {
          rez.push(`${user.id}`);
        }
      }
    });
    //FFS POC evaluations - averages are no longer computed based on scores from selected users, they are those computed by the server
    let fakeAvg = this.getFakeEvaluationAvg(this.state.selectedRubricIdx);
    this.setState({ selectedUsersIds: rez, fakeEvaluationAvg: fakeAvg });
  };

  renderUsersSelectorBordered() {
    let users = this.getUsersForSelector();
    let height = 30 * (users.length + 1); //TODO ADI
    let width = this.state.usersListAnchor ? this.state.usersListAnchor.getBoundingClientRect().width : 100;
    return (
      <BorderedSelectorSquares
        anchorRef={this.hasConsistentSelection() ? this.state.usersListAnchor : null} /* if null, popper is not rendered*/
        items={users}
        toggleSelection={this.toggleUserSelection}
        width={width}
        height={height}
        onClose={this.toggleUsersList}
        position="left"
      ></BorderedSelectorSquares>
    );
  }
  selectedUsersChanged = (newSelectedUsersIds: string[]) => {
    // we need to preserve the order
    let rez = [];
    let ids = [];
    this.state.usersPassedToSelectorForm.forEach((user) => {
      if (newSelectedUsersIds.indexOf(`${user.id}`) >= 0) {
        rez.push(`${user.id}`);
        ids.push(user.id);
      }
    });
    //FFS POC evaluations - averages are no longer computed based on scores from selected users, they are those computed by the server
    let fakeAvg = this.getFakeEvaluationAvg(this.state.selectedRubricIdx);
    this.setState({ selectedUsersIds: rez, fakeEvaluationAvg: fakeAvg });
  };
  usersChanged = (allUsers: SUser[], newSelectedUsersIds: string[]) => {
    this.setState({ usersPassedToSelectorForm: allUsers }, () => {
      this.selectedUsersChanged(newSelectedUsersIds);
    });
  };

  computeFakeEvaluationAvgOld = (userIds: number[]) => {
    let { rubricInfo, evaluations } = this.props.evaluations[this.state.selectedRubricIdx];
    let rez = { categories: [] } as EvaluationResponse;
    rubricInfo.rubric.categories.forEach((category: Category, idxCategory: number) => {
      let responses = Array.from(Array(category.questions.length).keys()).map((idxQuestion) =>
        this.getAverageOfQuestion(userIds, evaluations, idxCategory, idxQuestion)
      );
      rez.categories.push({ response: responses });
    });
    return rez;
  };
  getFakeEvaluationAvg = (rubricIdx: number) => {
    let { rubricInfo } = this.props.evaluations[rubricIdx];
    let rez = { categories: [] } as EvaluationResponse;
    rubricInfo.rubric.categories.forEach((category: Category, idxCategory: number) => {
      let responses = [...category.averages];
      rez.categories.push({ response: responses });
    });
    return rez;
  };

  getAverageOfQuestion = (userIds: number[], evaluations: EvaluationsMap, idxCategory: number, idxQuestion: number) => {
    if (!evaluations) return 0;
    let count = 0,
      sum = 0;

    userIds.forEach((userId) => {
      if (
        evaluations[userId] &&
        evaluations[userId].evaluation &&
        evaluations[userId].evaluation.categories &&
        evaluations[userId].evaluation.categories.length > idxCategory &&
        evaluations[userId].evaluation.categories[idxCategory].response &&
        evaluations[userId].evaluation.categories[idxCategory].response.length > idxQuestion
      ) {
        sum += evaluations[userId].evaluation.categories[idxCategory].response[idxQuestion];
        count++;
      }
    });
    return count ? Math.trunc((sum / count) * 100) / 100 : 0;
  };

  getUsersList = (evaluations: EvaluationsMap) => Object.values(evaluations).map((evaluation: Evaluation) => evaluation.createdBy);

  FAKE_USER_AVERAGE_ID = -Number.MAX_SAFE_INTEGER;

  addFakeUsers = (users: SUser[]) => {
    let rezult = [];
    rezult.push(...users);
    // if (rezult.length > 0) {
    //FFS POC evaluations - add average all the time, to show server-computed averages, instead of averages for the selected users
    rezult.push({
      id: this.FAKE_USER_AVERAGE_ID,
      username: 'Average',
      email: '',
      provider: '',
      confirmed: true,
      blocked: false,
      role: 1,
      created_at: null,
      updated_at: null,
    });
    // }
    return rezult;
  };

  renderEvaluationForRubric = (info: {
    rubricInfo: SubmissionRubric;
    evaluations: EvaluationsMap;
    categoryAveragesMap: {
      [userId: number]: EvaluationResponse;
    };
  }) => {
    let { rubricInfo, evaluations, categoryAveragesMap } = info;
    let rez = (
      <div className="col-12 no-padding">
        {rubricInfo.rubric.categories.map((category, idxCategory) => {
          return (
            <div key={`${idxCategory}_${category.name}`}>
              {this.renderCategory(category, rubricInfo.rubric.score, evaluations, idxCategory, categoryAveragesMap)}
            </div>
          );
        })}
      </div>
    );
    return rez;
  };

  renderCategory = (category: Category, rubricScore: number, evaluationsMap: EvaluationsMap, idxCategory: number, categoryAveragesMap) => {
    let rez = (
      <div>
        {this.renderCategoryHeader(evaluationsMap, idxCategory, category)}

        {category.questions.map((question, idxQuestion) =>
          this.renderQuestionWScores(question, rubricScore, evaluationsMap, idxQuestion, idxCategory)
        )}

        {this.renderCategoryFooter(evaluationsMap, idxCategory, categoryAveragesMap)}
      </div>
    );
    return rez;
  };
  renderQuestionWScores = (
    question: string,
    rubricScore: number,
    evaluationsMap: EvaluationsMap,
    idxQuestion: number,
    idxCategory: number
  ) => {
    console.log('renderQuestionWScore: question/idxCategory/idxQuestion ', question, idxCategory, idxQuestion);

    let itemToRender = (
      <div className="row fig-question-row" key={`${idxCategory}_${idxQuestion}`}>
        <div className="col-6 no-padding fig-question-text">
          <div>
            <b>{1 + idxQuestion}.</b> {question}
          </div>
        </div>
        <div className="col-6 no-padding fig-matrix-scores">{this.renderScores(idxCategory, idxQuestion, evaluationsMap)}</div>
      </div>
    );
    return itemToRender;
  };

  renderColumnHeaders(evaluationsMap: EvaluationsMap) {
    console.log('renderHeader:  ', evaluationsMap);
    return Object.values(this.state.selectedUsersIds).map((userId) => {
      let clone = false;
      let id = parseInt(userId, 10),
        fixedId = id;
      if (id < 0 && id !== this.FAKE_USER_AVERAGE_ID) {
        fixedId = -id;
        clone = true;
      }
      let hasEval = evaluationsMap && evaluationsMap[fixedId];
      return (
        <div className="fig-matrix-userbox" key={`Field_${id}`}>
          <div>
            {id === this.FAKE_USER_AVERAGE_ID ? (
              <svg>
                <use href={sprites + '#averege-icon'} />
              </svg>
            ) : (
              <svg>
                <use href={sprites + '#avatar-icon'} />
              </svg>
            )}
          </div>
          <div>
            {id === this.FAKE_USER_AVERAGE_ID
              ? 'Average'
              : hasEval
              ? `${evaluationsMap[fixedId].createdBy.username} ${clone ? 'Clone' : ''}`
              : 'None'}
          </div>
        </div>
      );
    });
  }

  renderCategoryHeader(evaluationsMap: EvaluationsMap, idxCategory: number, category: Category) {
    return (
      <div className="row">
        <div className="col-6 no-padding fig-category-header">
          <svg>
            <use href={sprites + '#rubric-icon'} />
          </svg>
          <span>{`  ${category.name}`}</span>
        </div>
        <div className="col-6 no-padding fig-category-header-users">
          {idxCategory === 0 ? this.renderColumnHeaders(evaluationsMap) : null}
        </div>
      </div>
    );
  }
  renderCategoryFooter(evaluationsMap: EvaluationsMap, idxCategory: number, categoryAveragesMap) {
    return (
      <div className="row">
        <div className="col-6 no-padding fig-category-footer-left">
          <div>Average</div>
        </div>
        <div className="col-6 no-padding fig-category-footer-right">
          {this.renderCategoryAverages(evaluationsMap, idxCategory, categoryAveragesMap)}
        </div>
      </div>
    );
  }
  renderCategoryAverages(evaluationsMap: EvaluationsMap, idxCategory, categoryAveragesMap) {
    console.log('renderCategoryAverages:  ', evaluationsMap, idxCategory, categoryAveragesMap);
    let averages = categoryAveragesMap as {
      [userId: number]: EvaluationResponse;
    };
    return Object.values(this.state.selectedUsersIds).map((userId) => {
      let id = parseInt(userId, 10),
        fixedId = id;
      if (id < 0 && id !== this.FAKE_USER_AVERAGE_ID) {
        fixedId = -id;
      }
      let hasEval = evaluationsMap && evaluationsMap[fixedId];
      let categAverage =
        id === this.FAKE_USER_AVERAGE_ID ? getCategoryAverage(this.state.fakeEvaluationAvg.categories[idxCategory].response) : 0;

      return (
        <div className="fig-matrix-average-scoresbox" key={`Average_${id}`}>
          {id === this.FAKE_USER_AVERAGE_ID
            ? this.state.fakeEvaluationAvg &&
              this.state.fakeEvaluationAvg.categories &&
              this.state.fakeEvaluationAvg.categories.length > idxCategory &&
              this.state.fakeEvaluationAvg.categories[idxCategory].response
              ? categAverage
                ? categAverage.toFixed(2)
                : '-'
              : '-'
            : hasEval && averages[fixedId].categories[idxCategory].response[0]
            ? `${averages[fixedId].categories[idxCategory].response[0].toFixed(2)}`
            : '-'}
        </div>
      );
    });
  }

  renderScores(idxCategory: number, idxQuestion: number, evaluationsMap: EvaluationsMap) {
    console.log('renderQuestionWScore: idxCategory/idxQuestion/map ', idxCategory, idxQuestion, evaluationsMap);
    return Object.values(this.state.selectedUsersIds).map((userId) => {
      let score = -100;
      let id = parseInt(userId, 10);
      if (id === this.FAKE_USER_AVERAGE_ID) {
        score =
          this.state.fakeEvaluationAvg &&
          this.state.fakeEvaluationAvg.categories &&
          this.state.fakeEvaluationAvg.categories.length > idxCategory &&
          this.state.fakeEvaluationAvg.categories[idxCategory].response &&
          !isNaN(this.state.fakeEvaluationAvg.categories[idxCategory].response[idxQuestion])
            ? this.state.fakeEvaluationAvg.categories[idxCategory].response[idxQuestion]
            : 0;
      } else {
        if (id < 0) {
          // FAKE USER, use the evaluation of -id
          id = -id;
        }
        console.log('id: ', id);
        let evaluation = evaluationsMap && evaluationsMap[id] ? evaluationsMap[id].evaluation : null;
        score =
          evaluation &&
          evaluation.categories &&
          evaluation.categories[idxCategory] &&
          evaluation.categories[idxCategory].response &&
          evaluation.categories[idxCategory].response[idxQuestion]
            ? evaluation.categories[idxCategory].response[idxQuestion]
            : 0;
      }
      return (
        <div className="fig-matrix-scoresbox " key={userId}>
          {score ? score.toFixed(2) : '-'}
        </div>
      );
    });
  }
}

const emptySubmissionRubricsMap = {} as SubmissionRubricsMap;
const selectorRubricsMap: (IRootState) => SubmissionRubricsMap = (state: IRootState) => {
  console.log('Matrix: SELECTOR selectorRubricsMap called with params ', state);
  let rez =
    state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
      ? state.video.videosMap[state.video.currentVideoId].submissionRubricsMap
      : emptySubmissionRubricsMap;
  console.log('Matrix: SELECTOR selectorRubricsMap returns  ', rez);
  return rez;
};

const retrieveEvaluations = createSelector([selectorRubricsMap], (submissionRubricsMap) => computeEvaluationsByUsers(submissionRubricsMap));

const mapStateToProps = (state: IRootState) => {
  return {
    authentication: state.authentication,
    currentVideoId: state.video.currentVideoId,
    evaluations: retrieveEvaluations(state) as {
      rubricInfo: SubmissionRubric;
      evaluations: EvaluationsMap;
      categoryAveragesMap: {
        [userId: number]: EvaluationResponse;
      };
    }[],
    windowHeight: state.utils.windowHeight,
    playerHeight: state.utils.playerHeight,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Matrix);
