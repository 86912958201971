import { Button, SvgIcon, Tooltip } from '@material-ui/core';
import React from 'react';

export function Avatars(props) {
  let { hasToolTip, ...rest } = props;
  const elementBtn = (
    <Button
      {...rest}
      className="fig-button "
      variant="outlined"
      style={{
        width: 115,
        height: 25,
      }}
      //TODO ADI FFS
    >
      Share
    </Button>
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const elementCircles = (
    <SvgIcon
      {...rest}
      style={{
        width: 83,
        height: 32,
        fill: 'none',
        margin: 'auto',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      viewBox="0 0 83 32"
    >
      <circle cx="16.6267" cy="16" r="15.5" fill="#9BA7BC" stroke="white" />
      <path
        d="M20.9312 21.1625L20.6697 20.5058C20.5375 20.1726 20.332 19.8737 20.069 19.6302L20.0212 19.5861C19.6073 19.2021 19.0634 18.9884 18.4986 18.9884H14.7525C14.1877 18.9884 13.6437 19.2021 13.2291 19.5854L13.1813 19.6302C12.9183 19.8737 12.7136 20.1726 12.5806 20.5058L12.3191 21.1625"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.4739 12.5983C19.4951 13.6195 19.4951 15.2752 18.4739 16.2964C17.4527 17.3176 15.797 17.3176 14.7758 16.2964C13.7546 15.2752 13.7546 13.6195 14.7758 12.5983C15.797 11.5771 17.4527 11.5771 18.4739 12.5983"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.3806 11.2452C24.0066 13.8712 24.0066 18.1286 21.3806 20.7546C18.7547 23.3805 14.4972 23.3805 11.8713 20.7546C9.24532 18.1286 9.24532 13.8711 11.8713 11.2452C14.4972 8.61927 18.7547 8.61927 21.3806 11.2452"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="41.7334" cy="16" r="15.5" fill="#9BA7BC" stroke="white" />
      <path
        d="M46.0379 21.1625L45.7764 20.5058C45.6442 20.1726 45.4387 19.8737 45.1757 19.6302L45.1279 19.5861C44.714 19.2021 44.1701 18.9884 43.6053 18.9884H39.8592C39.2943 18.9884 38.7504 19.2021 38.3358 19.5854L38.288 19.6302C38.025 19.8737 37.8203 20.1726 37.6873 20.5058L37.4258 21.1625"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M43.5806 12.5983C44.6018 13.6195 44.6018 15.2752 43.5806 16.2964C42.5594 17.3176 40.9037 17.3176 39.8825 16.2964C38.8613 15.2752 38.8613 13.6195 39.8825 12.5983C40.9037 11.5771 42.5594 11.5771 43.5806 12.5983"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M46.4873 11.2452C49.1133 13.8712 49.1133 18.1286 46.4873 20.7546C43.8614 23.3805 39.6039 23.3805 36.978 20.7546C34.352 18.1286 34.352 13.8711 36.978 11.2452C39.6039 8.61927 43.8614 8.61927 46.4873 11.2452"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="66.8398" cy="16" r="15.5" fill="#9BA7BC" stroke="white" />
      <path
        d="M71.1444 21.1625L70.8829 20.5058C70.7506 20.1726 70.5452 19.8737 70.2822 19.6302L70.2344 19.5861C69.8205 19.2021 69.2765 18.9884 68.7117 18.9884H64.9656C64.4008 18.9884 63.8569 19.2021 63.4422 19.5854L63.3944 19.6302C63.1314 19.8737 62.9267 20.1726 62.7937 20.5058L62.5322 21.1625"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M68.687 12.5983C69.7083 13.6195 69.7083 15.2752 68.687 16.2964C67.6658 17.3176 66.0102 17.3176 64.989 16.2964C63.9678 15.2752 63.9678 13.6195 64.989 12.5983C66.0102 11.5771 67.6658 11.5771 68.687 12.5983"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M71.5937 11.2452C74.2197 13.8712 74.2197 18.1286 71.5937 20.7546C68.9678 23.3805 64.7103 23.3805 62.0844 20.7546C59.4584 18.1286 59.4584 13.8711 62.0844 11.2452C64.7103 8.61927 68.9678 8.61927 71.5937 11.2452"
        stroke="#F5F6F8"
        strokeOpacity="0.6"
        strokeWidth="0.891429"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );

  if (props.hasToolTip) {
    // return <Tooltip title="Manage Video Sharing">{elementCircles}</Tooltip>;
    return <Tooltip title="Manage Video Sharing">{elementBtn}</Tooltip>;
  } else {
    // return elementCircles;
    return elementBtn;
  }
}
// Avatars.propTypes = {
//   hasToolTip: PropTypes.bool.isRequired,
//   onClick: PropTypes.func.isRequired,
//   style: PropTypes.object,
//   viewBox: PropTypes.string,
// };

//<svg width="83" height="32" viewBox="0 0 83 32" fill="none" xmlns="http://www.w3.org/2000/svg">
