import axios, { AxiosError, AxiosResponse } from 'axios';
import { errorNotification, successNotification, warningNotification } from '../../../utils/utils';
import figaroApi, {
  confirmEMailURL,
  foldersURL,
  folderTagsURL,
  forgotPasswordURL,
  registerURL,
  resendConfirmationEMailURL,
  resetPasswordURL,
  rubricsURL,
  s3URL,
  settingsURL,
  submissionCommentsURL,
  submissionEvaluationsURL,
  submissionHighlightsURL,
  submissionRubricsURL,
  submissionSharesURL,
  submissionsURL,
  usersURL
} from '../../apis/figaroApi';
import { IEditSubmissionState } from '../../components/submission/EditSubmission';
import config from '../../config/constants';
import {
  AnalysisItem,
  AnalysisItemStatus,
  AWSUploadFields,
  CommentRequest,
  CommentResponse,
  FaceAnalysisItem,
  FaceSelections,
  FaceState,
  FaceTagRequest,
  Folder,
  FolderBase,
  FolderSubmission,
  HighlightRequest,
  HighlightResponse,
  IUser,
  LoginRequest,
  LoginResponse,
  Rubric,
  RubricBase,
  SettingsDatagroups,
  SettingsDisfluencies,
  ShareBase,
  Submission,
  SubmissionRubric,
  SubmissionRubricEvaluationResponse,
  SubmissionRubricResponse,
  SubmissionShareResponse,
  SubmissionState,
  SubmissionUploadInfo,
  VoiceState
} from '../../model/ApiTypes';
import { processTranscript } from '../../model/samples/sampleVideoData';
import {
  addEmotionsRates,
  addFakeEmotionsInfo,
  Annotation,
  buildTag2FaceAnalysisIdx,
  buildTag2FriendlyName,
  Comment,
  computeFullText,
  FaceAnalysis,
  Highlight,
  SentimentAnalysis,
  StreamRef,
  SubmissionRubricsMap,
  SubmissionSharesMap,
  toStringId,
  Transcript,
  Urlid2FaceAnalysisMetaMap,
  Video,
  VoiceAnalysis
} from '../../model/Video';
import {
  VideoAnnotationActionTypes,
  VideoCommentActionTypes,
  VideoHighlightActionTypes,
  VideoTagActionTypes,
  VIDEO_ANNOTATION_ADDED,
  VIDEO_ANNOTATION_DELETED,
  VIDEO_COMMENT_ADDED,
  VIDEO_COMMENT_DELETED,
  VIDEO_COMMENT_PRIVACY_CHANGED,
  VIDEO_COMMENT_UPDATED,
  VIDEO_HIGHLIGHT_ADDED,
  VIDEO_HIGHLIGHT_DELETED,
  VIDEO_HIGHLIGHT_PRIVACY_CHANGED,
  VIDEO_HIGHLIGHT_SELECTED,
  VIDEO_HIGHLIGHT_UPDATED,
  VIDEO_TAG_UPDATED
} from '../videoActions/types';
import {
  ALLFOLDERS_SUBMISSIONS,
  AuthActionTypes,
  AUTH_CLEAR,
  AUTH_ERROR,
  AUTH_USER,
  Evaluation,
  FoldersActionTypes,
  FolderSubmissionActionTypes,
  FOLDER_ADDED,
  FOLDER_DELETED,
  FOLDER_SUBMISSION_ADDED,
  FOLDER_SUBMISSION_DELETED,
  FOLDER_UPDATED,
  GlobalRubricsAction,
  OWN_FOLDERS,
  OWN_RUBRICS,
  RubricsActionTypes,
  RUBRIC_ADDED,
  RUBRIC_DELETED,
  RUBRIC_UPDATED,
  SettingsActionTypes,
  SETTINGS_DATAGROUPS,
  SETTINGS_DISFLUENCIES,
  SETTINGS_GLOBAL_RUBRICS,
  SubmissionRubricEvaluationsActionTypes,
  SubmissionRubricsActionTypes,
  SUBMISSIONS,
  SubmissionsActionTypes,
  SubmissionShareActionTypes,
  SUBMISSIONS_ASKED_TO_OTHERS,
  SUBMISSIONS_TO_UPLOAD,
  SUBMISSION_DELETED,
  SUBMISSION_ENHANCED_INFO,
  SUBMISSION_META,
  SUBMISSION_RUBRICS,
  SUBMISSION_RUBRIC_ADDED,
  SUBMISSION_RUBRIC_DELETED,
  SUBMISSION_RUBRIC_EVALUATIONS,
  SUBMISSION_RUBRIC_EVALUATION_ADDED,
  SUBMISSION_RUBRIC_EVALUATION_DELETED,
  SUBMISSION_RUBRIC_EVALUATION_UPDATED,
  SUBMISSION_RUBRIC_UPDATED,
  SUBMISSION_SHARE_ADDED,
  SUBMISSION_SHARE_DELETED,
  SUBMISSION_UPDATED_DETAILS,
  SUBMISSION_UPDATED_DISFLUENCIES,
  SUBMISSION_UPLOAD_INFO
} from './types';

const authOptions = {
  headers: { 'Content-Type': ' application/json', Accept: 'application/json' },
};

export const logErrorResponse = (error, ownMessage) => {
  if (error && error.response) {
    /*
     * The request was made and the server responded with a
     * status code that falls out of the range of 2xx
     */
    console.error(ownMessage);
    console.error(error.response);
    console.error(error.response.data);
    // console.error(error.response.status);
    // console.error(error.response.headers);
    let text = null;
    if (error.response.data && error.response.data.message) {
      text = error.response.data.message;
    } else if (error.response.data) {
      text = error.response.data;
    }
    errorNotification(`${ownMessage}: ${error} ${text ? text : ''}`);
  } else {
    errorNotification(`${ownMessage}: ${error ? error : ''}`);
  }
};

export const login = (loginInfo: LoginRequest) => async (dispatch: (action: AuthActionTypes | any) => void) => {
  try {
    const response = await figaroApi.post('auth/local', { ...loginInfo });
    const loginResponse: LoginResponse = response.data;
    dispatch({ type: AUTH_USER, payload: loginResponse });
    dispatch(getDisfluencies(loginResponse.jwt));
    dispatch(getDatagroups(loginResponse.jwt));
    dispatch(getGlobalRubrics(loginResponse.jwt));
    return Promise.resolve();
  } catch (err) {
    dispatch({
      type: AUTH_ERROR,
      payload: { message: 'Invalid username or password', error: err },
    });
    logErrorResponse(err, 'Invalid username or password. ');
    return Promise.reject(err);
  }
};

export const logout = () => async (dispatch: (action: AuthActionTypes) => void) => {
  try {
    dispatch({ type: AUTH_CLEAR });
  } catch (err) {
    console.error('Unexpected logout err ', err);
  }
  return Promise.resolve();
};

function withAuthorizationToken(authoptions, token) {
  const res = {
    headers: { ...authoptions.headers, Authorization: `Bearer ${token}` },
  };
  console.log(res);
  return res;
}

const fromApiCoord = (coord: number) => coord * 100;
const toApiCoord = (coord: number) => coord / 100;

const makeCreateFaceTagRequest = (tag: FaceAnalysisItem) => {
  return {
    name: tag.name,
    x1: tag.tag.x1,
    y1: tag.tag.y1,
    x2: tag.tag.x2,
    y2: tag.tag.y2,
  } as FaceTagRequest;
};

const makeUpdateFaceTagNameRequest = (tag: FaceAnalysisItem) => {
  return { analysis: { faces: [{ url: tag.url, name: tag.name }] } };
};
const makeUpdateFaceTagStatusRequest = (tag: FaceAnalysisItem) => {
  return { analysis: { faces: [{ url: tag.url, status: tag.status }] } };
};

const extractTagResultByUrl = (submission: Submission, url: string) => {
  let rez: FaceAnalysisItem = null;
  if (submission.analysis && submission.analysis.faces) {
    rez = submission.analysis.faces.find((face) => face.url === url);
  }
  return rez;
};
const extractTagResultByName = (submission: Submission, name: string) => {
  let rez: FaceAnalysisItem = null;
  if (submission.analysis && submission.analysis.faces) {
    rez = submission.analysis.faces.find((face) => face.name === name);
  }
  return rez;
};

const makeEmptyAnnotationRequest = (commentId: string) => {
  const res: CommentRequest = {
    options: {
      annotation: null,
    },
  };
  return res;
};
const makeAnnotationRequest = (commentId: string, annotation: Annotation) => {
  const res: CommentRequest = {};
  if (annotation) {
    res.options = {
      annotation: {
        x: toApiCoord(annotation.x),
        y: toApiCoord(annotation.y),
      },
    };
  }
  return res;
};

const makeAnnotationResponse = (commentResponse: CommentResponse) => {
  const res: Annotation = {
    id: commentResponse.id + '',
    x: fromApiCoord(commentResponse.options.annotation.x),
    y: fromApiCoord(commentResponse.options.annotation.y),
  };
  return res;
};

const makeCommentRequest = (videoId: string, comment: Comment): CommentRequest => {
  const res: CommentRequest = {
    submission: Number.parseInt(videoId, 10),
    state: comment.privacy, // Possible States: public, shared, private, removed TODO ASK REMOVED?
    comment: comment.text,
    videotime: Math.floor(comment.time * 1000), // millis in DB
  };
  if (comment.annotation) {
    res.options = {
      annotation: {
        x: toApiCoord(comment.annotation.x),
        y: toApiCoord(comment.annotation.y),
      },
    };
  }
  return res;
};

const makeComment = (commentResponse: CommentResponse): Comment => {
  const comment: Comment = {
    id: toStringId(commentResponse.id),
    time: commentResponse.videotime / 1000, // millis in DB
    text: commentResponse.comment,
    privacy: commentResponse.state, // TODO ASk  deleted?
    userID: commentResponse.createdby.username,
    created_at: new Date(commentResponse.created_at),
    updated_at: new Date(commentResponse.updated_at),
    annotation:
      commentResponse.options && commentResponse.options.annotation
        ? {
            x: fromApiCoord(commentResponse.options.annotation.x),
            y: fromApiCoord(commentResponse.options.annotation.y),
            id: toStringId(commentResponse.id),
          }
        : null,
  };
  return comment;
};

const makeHighlightRequest = (videoId: string, highlight: Highlight): HighlightRequest => {
  const highlightReq = {
    submission: Number.parseInt(videoId, 10),
    state: highlight.privacy,
    name: highlight.name,
    description: highlight.text,
    starttime: Math.floor(highlight.startTime * 1000), // millis in DB
    endtime: Math.floor(highlight.endTime * 1000),
    options: {
      face_state: {
        threshold: highlight.faceThreshold,
        highlight: null,
        playing: null,
        selections: faceRefs2apiFaceRef(highlight.selectedStreamsFace),
      },

      voice_state: {
        threshold: highlight.voiceThreshold,
        highlight: null,
        playing: null,
        selections: voiceRefs2apiVoiceRef(highlight.selectedStreamsVoice),
      },
    },
  };
  return highlightReq;
};

export const voiceRefs2apiVoiceRef = (voiceRefs: StreamRef[]) => {
  let apiVoiceSelections: string[] = [];
  voiceRefs.forEach((voiceRef, index) => {
    apiVoiceSelections.push(voiceRef.stream);
  });
  return apiVoiceSelections;
};

const faceRefs2apiFaceRef = (faceRefs: StreamRef[]) => {
  let apiFaceSelections: FaceSelections = {};
  faceRefs.forEach((faceRef, index) => {
    if (!apiFaceSelections[faceRef.analysisTag]) {
      apiFaceSelections[faceRef.analysisTag] = [];
    }
    apiFaceSelections[faceRef.analysisTag].push(faceRef.stream);
  });

  if (apiFaceSelections[UNIQUE_SPEAKER_TAG]) {
    //rename back " " to "Speaker"
    apiFaceSelections[API_UNIQUE_SPEAKER_TAG] = apiFaceSelections[UNIQUE_SPEAKER_TAG];
    delete apiFaceSelections[UNIQUE_SPEAKER_TAG];
  }
  return apiFaceSelections;
};

const makeHighlight = (hlResponse: HighlightResponse): Highlight => {
  const highlightResult: Highlight = {
    id: toStringId(hlResponse.id),
    startTime: hlResponse.starttime / 1000,
    endTime: hlResponse.endtime / 1000,
    text: hlResponse.description,
    privacy: hlResponse.state,
    userID: hlResponse.createdby.username,
    name: hlResponse.name,
    created_at: new Date(hlResponse.created_at),
    updated_at: new Date(hlResponse.updated_at),

    voiceThreshold: hlResponse.options ? hlResponse.options.voice_state.threshold : 100,
    faceThreshold: hlResponse.options ? hlResponse.options.face_state.threshold : 100,
    selectedStreamsVoice: hlResponse.options ? apiVoiceRef2VoiceRefs(hlResponse.options.voice_state) : [],
    selectedStreamsFace: hlResponse.options ? apiFaceRef2FaceRefs(hlResponse.options.face_state) : [],
  };
  return highlightResult;
};

export const apiVoiceRef2VoiceRefs = (apiVoiceState: VoiceState) => {
  let rez: StreamRef[] = [];
  apiVoiceState.selections.forEach((selection, index) => {
    let ref: StreamRef = {
      analysisIdx: 0,
      stream: selection,
      analysisTag: '',
      tagFriendlyName: '',
    };
    rez.push(ref);
  });
  return rez;
};

const apiFaceRef2FaceRefs = (apiFaceState: FaceState) => {
  let rez: StreamRef[] = [];
  Object.keys(apiFaceState.selections).forEach((faceTag, index) => {
    const streams = apiFaceState.selections[faceTag];
    streams.forEach((stream, idx) => {
      let ref: StreamRef = {
        analysisIdx: null, // TODO FFS - this will be updated later when video streams are known
        tagFriendlyName: null, // // TODO FFS - this will be updated later when video streams are known
        stream: stream,
        analysisTag: faceTag === API_UNIQUE_SPEAKER_TAG ? UNIQUE_SPEAKER_TAG : faceTag,
      };
      rez.push(ref);
    });
  });
  return rez;
};

const UNIQUE_SPEAKER_TAG = ' ';
const API_UNIQUE_SPEAKER_TAG = 'Speaker';
const simplifyUniqueTag = (faceAnalyses: FaceAnalysis[], highlights: Highlight[]) => {
  if (faceAnalyses && faceAnalyses.length === 1 && faceAnalyses[0].tag === API_UNIQUE_SPEAKER_TAG) {
    faceAnalyses[0].tag = UNIQUE_SPEAKER_TAG;
    if (highlights && highlights.length) {
      highlights.forEach((hl) => {
        if (hl.selectedStreamsFace) {
          hl.selectedStreamsFace.forEach((streamRef) => (streamRef.analysisTag = UNIQUE_SPEAKER_TAG));
        }
      });
    }
  }
};

/*
CR: instead of using submission.analytics.source
    Use `${REACT_APP_BASE_S3}${submission.uuid}/`*/
//TODO FFS update    getSubmissionInfoInSequence and other old stuff?
const makeVideo = (
  submission: Submission,
  voiceAnalyses: VoiceAnalysis[],
  faceAnalyses: FaceAnalysis[],
  transcript: Transcript,
  comments: Comment[],
  highlights: Highlight[],
  submissionRubricResponses: SubmissionRubricResponse[],
  submissionShareResponses: SubmissionShareResponse[],
  sentiment: SentimentAnalysis,
  metaFaceAnalysesMap: Urlid2FaceAnalysisMetaMap
) => {
  simplifyUniqueTag(faceAnalyses, highlights);
  addFakeEmotionsInfo(faceAnalyses);
  addEmotionsRates(faceAnalyses);
  let tag2FaceAnalysisIdx = buildTag2FaceAnalysisIdx(faceAnalyses);
  let tag2FriendlyName = buildTag2FriendlyName(faceAnalyses, metaFaceAnalysesMap);
  const video: Video = {
    id: toStringId(submission.id),
    faceAnalyses,
    tag2FaceAnalysisIdx,
    tag2FriendlyName,
    voiceAnalyses,
    transcript,
    sentiment,
    creatorId: toStringId(submission.createdby.id),
    dateCreated: submission.created_at,
    videoRequestId: submission.uuid, // todo ask etc
    url:
      submission.analysis.video && submission.analysis.video.status === AnalysisItemStatus.available
        ? //? submission.analysis.source + submission.analysis.video.url //CRFFS
          `${s3URL}${submission.uuid}/${submission.analysis.video.url}`
        : null,
    comments: { comments },
    highlights: { highlights },
    threshold: submission.threshold ? submission.threshold : config.trigger.triggerLevel, // TODO ffs oare un default si in submission?
    submissionRubricsMap: buildSubmissionRubricsMap(submissionRubricResponses, submission.id),
    submissionSharesMap: buildSubmissionSharesMap(submissionShareResponses, submission.id),
    metaFaceAnalysesMap,
  };
  computeFullText(video);
  return video;
};

const buildSubmissionSharesMap = (submissionShareResponses: SubmissionShareResponse[], submissionId: number) => {
  let rez = {} as SubmissionSharesMap;
  submissionShareResponses.forEach(
    (submissionShareResponse) => (rez[submissionShareResponse.id] = makeSubmissionShare(submissionShareResponse, submissionId))
  );
  return rez;
};
const makeSubmissionShare = (response: SubmissionShareResponse, submissionId: number) => {
  return {
    id: response.id,
    createdby: response.createdby,
    created_at: response.created_at,
    updated_at: response.updated_at,
    user: response.user,
    state: response.state,
    email: response.email,
  } as ShareBase;
};

const buildSubmissionRubricsMap = (submissionRubricResponses: SubmissionRubricResponse[], submissionId: number) => {
  let rez = {} as SubmissionRubricsMap;
  submissionRubricResponses.forEach(
    (submissionRubricResponse) =>
      (rez[submissionRubricResponse.id] = {
        ...rez[submissionRubricResponse.id],
        rubricInfo: makeSubmissionRubric(submissionRubricResponse, submissionId),
      })
  );
  return rez;
};
const makeSubmissionRubric = (response: SubmissionRubricResponse, submissionId: number) => {
  return {
    submission: submissionId, // TODO FFS REMOVE IF NOT USED
    name: response.name,
    state: response.state,
    rubric: response.rubric,
    id: response.id,
    createdby: response.createdby,
  } as SubmissionRubric;
};
const makeVideoShallow = (submission: Submission) => makeVideo(submission, [], [], null, [], [], [], [], null, null);

const makeVideosShallow = (submissionsResponse: Submission[]): Video[] => {
  return submissionsResponse.map((submission, index) => makeVideoShallow(submission));
};

export const updateAnnotation =
  (videoId: string, commentId: string, annotation: Annotation, token: string) =>
  async (dispatch: (action: VideoAnnotationActionTypes) => void) => {
    try {
      const commentReq = makeAnnotationRequest(commentId, annotation);
      const res = await figaroApi.put(`${submissionCommentsURL}/${commentId}`, commentReq, withAuthorizationToken(authOptions, token));
      console.log('updateAnnotation request: ', commentReq, ', response: ', res.data);
      const commentResponse: CommentResponse = res.data;
      const annotationResult = makeAnnotationResponse(commentResponse);
      dispatch({
        type: 'VIDEO_ANNOTATION_UPDATED',
        payload: {
          videoId /* videoId*/,
          commentId: commentId + '',
          annotation: annotationResult,
        },
      });
      successNotification('Annotation updated!');
    } catch (err) {
      logErrorResponse(err, 'updateAnnotation failed ');
    }
  };
export const deleteAnnotation =
  (videoId: string, commentId: string, token: string) => async (dispatch: (action: VideoAnnotationActionTypes) => void) => {
    const commentReq = makeEmptyAnnotationRequest(commentId);
    const res = await figaroApi.put(`${submissionCommentsURL}/${commentId}`, commentReq, withAuthorizationToken(authOptions, token));
    console.log('deleteAnnotation request: ', commentReq, ', response: ', res.data);
    dispatch({
      type: VIDEO_ANNOTATION_DELETED,
      payload: { videoId, commentId },
    });
    warningNotification('Annotation deleted!');
  };
export const addAnnotation =
  (videoId: string, commentId: string, annotation: Annotation, token: string) =>
  async (dispatch: (action: VideoAnnotationActionTypes) => void) => {
    try {
      const commentReq = makeAnnotationRequest(commentId, annotation);
      const res = await figaroApi.put(`${submissionCommentsURL}/${commentId}`, commentReq, withAuthorizationToken(authOptions, token));
      console.log('addAnnotation request: ', commentReq, ', response: ', res.data);
      const commentResponse: CommentResponse = res.data;
      const annotationResult = makeAnnotationResponse(commentResponse);
      dispatch({
        type: VIDEO_ANNOTATION_ADDED,
        payload: {
          videoId /* videoId*/,
          commentId: commentId + '',
          annotation: annotationResult,
        },
      });
      successNotification('Annotation added!');
    } catch (err) {
      logErrorResponse(err, 'addAnnotation failed ');
    }
  };

//the response contains the entire submission;extract just the new tag - by url
//   {
//     "tag": {
//         "x1": 33.874690849134375,
//         "x2": 61.662544655125025,
//         "y1": 14.38272509648737,
//         "y2": 68.16161023987493
//     },
//     "name": "Lady in Red",
// }
export const addVideoTag =
  (videoId: string, tag: FaceAnalysisItem, token: string) => async (dispatch: (action: VideoTagActionTypes | any) => void) => {
    try {
      const tagReq = makeCreateFaceTagRequest(tag);
      const res = await figaroApi.post(`${submissionsURL}/tag/${videoId}`, tagReq, withAuthorizationToken(authOptions, token));
      console.log('addFaceTag request: ', tagReq, ', response: ', res.data);

      const submissionResponse: Submission = res.data;
      const createdTag = extractTagResultByName(submissionResponse, tag.name);
      if (createdTag) {
        //FFS - we better re-get all submission info, to have the right indexes maps etc
        // dispatch({
        //   type: VIDEO_TAG_ADDED,
        //   payload: { videoId, tagId: createdTag.url, tag: createdTag },
        // });
        successNotification('Face tag added!');
      } else {
        console.warn(`missing tag ${tag.name} in addFaceTag response  `, res.data);
        // warningNotification(`Response does not contain tag ${tag.name}`);
      }
      //FFS
      const video: Video = makeVideoShallow(submissionResponse);
      dispatch({
        type: SUBMISSION_META,
        payload: { submission: submissionResponse, video },
      });
      console.log(' getSubmissionAndEnhancedInfo after tag creation: get enhanced  info about  subm : ', videoId);
      dispatch(getSubmissionEnhancedInfo(submissionResponse, token));
    } catch (err) {
      logErrorResponse(err, 'addFaceTag failed ');
    }
  };

export const updateTagName =
  (videoId: string, tag: FaceAnalysisItem, token: string) => async (dispatch: (action: VideoTagActionTypes | any) => void) => {
    try {
      const tagReq = makeUpdateFaceTagNameRequest(tag);
      const res = await figaroApi.put(`${submissionsURL}/${videoId}`, tagReq, withAuthorizationToken(authOptions, token));
      console.log('updateTagName request: ', tagReq, ', response: ', res.data);

      const submissionResponse: Submission = res.data;
      const updatedTag = extractTagResultByUrl(submissionResponse, tag.url);
      if (updatedTag) {
        successNotification('Face tag updated!');
      } else {
        console.warn(`missing tag ${tag.url} in updateTagName response  `, res.data);
        // warningNotification(`Response does not contain tag ${tag.url}`);
      }
      const video: Video = makeVideoShallow(submissionResponse);
      dispatch({
        type: SUBMISSION_META,
        payload: { submission: submissionResponse, video },
      });
      console.log(' getSubmissionAndEnhancedInfo after tag creation: get enhanced  info about  subm : ', videoId);
      dispatch(getSubmissionEnhancedInfo(submissionResponse, token));
    } catch (err) {
      logErrorResponse(err, 'updateTagName failed ');
    }
  };
export const updateTagStatus =
  (videoId: string, tag: FaceAnalysisItem, token: string) => async (dispatch: (action: VideoTagActionTypes | any) => void) => {
    try {
      const tagReq = makeUpdateFaceTagStatusRequest(tag);
      const res = await figaroApi.put(`${submissionsURL}/${videoId}`, tagReq, withAuthorizationToken(authOptions, token));
      console.log('updateTagStatus request: ', tagReq, ', response: ', res.data);

      const submissionResponse: Submission = res.data;
      const updatedTag = extractTagResultByUrl(submissionResponse, tag.url);
      if (updatedTag) {
        successNotification('Face tag status updated!');
      } else {
        console.warn(`missing tag ${tag.url} in updateTagStatus response  `, res.data);
        // warningNotification(`Response does not contain tag ${tag.url}`);
      }
      const video: Video = makeVideoShallow(submissionResponse);
      dispatch({
        type: SUBMISSION_META,
        payload: { submission: submissionResponse, video },
      });
      console.log(' getSubmissionAndEnhancedInfo after tag creation: get enhanced  info about  subm : ', videoId);
      dispatch(getSubmissionEnhancedInfo(submissionResponse, token));
    } catch (err) {
      logErrorResponse(err, 'updateTagStatus failed ');
    }
  };
// export const videoTagDeleted = (videoId: string, tagId: string) => (dispatch: (action: VideoTagActionTypes) => void) => {
//   dispatch({
//     type: VIDEO_TAG_DELETED,
//     payload: { videoId, tagId },
//   });
//   warningNotification('FAKE Video tag deleted! (NOT SENT TO THE BACKEND)');
// };

export const updateVideoTag =
  (videoId: string, tagId: string, tag: FaceAnalysisItem) => (dispatch: (action: VideoTagActionTypes) => void) => {
    dispatch({
      type: VIDEO_TAG_UPDATED,
      payload: { videoId, tagId, tag },
    });
    successNotification('Video tag updated! -full (NOT SENT TO THE BACKEND)');
  };

export const addComment =
  (videoId: string, comment: Comment, token: string) => async (dispatch: (action: VideoCommentActionTypes) => void) => {
    try {
      const commentReq = makeCommentRequest(videoId, comment);
      const res = await figaroApi.post(`${submissionCommentsURL}`, commentReq, withAuthorizationToken(authOptions, token));
      console.log('addComment request: ', commentReq, ', response: ', res.data);

      const commentResponse: CommentResponse = res.data;
      const commentResult = makeComment(commentResponse);
      dispatch({
        type: VIDEO_COMMENT_ADDED,
        payload: {
          videoId: commentResponse.submission.id + '' /* videoId*/,
          comment: commentResult,
        },
      });
      successNotification('Video comment added!');
    } catch (err) {
      logErrorResponse(err, 'addComment failed ');
    }
  };

export const deleteComment =
  (videoId: string, commentId: string, token: string) => async (dispatch: (action: VideoCommentActionTypes) => void) => {
    try {
      const res = await figaroApi.delete(`${submissionCommentsURL}/${commentId}`, withAuthorizationToken(authOptions, token));
      console.log('deleteComment request: ', commentId, ', response: ', res.data);
      // const commentResponse: CommentResponse = res.data;
      dispatch({
        type: VIDEO_COMMENT_DELETED,
        payload: { videoId, commentId },
      });
      warningNotification('Video comment deleted!');
    } catch (err) {
      logErrorResponse(err, 'deleteComment failed ');
    }
  };

// changeCommentPrivacy
export const changeCommentPrivacy =
  (videoId: string, commentId: string, privacy: string, token: string) => async (dispatch: (action: VideoCommentActionTypes) => void) => {
    try {
      const commentReq = {
        state: privacy,
      };
      const res = await figaroApi.put(`${submissionCommentsURL}/${commentId}`, commentReq, withAuthorizationToken(authOptions, token)); // TODO ASK how to update

      console.log('changeCommentPrivacy response: ', res.data);
      // const commentResponse: CommentResponse = res.data;
      dispatch({
        type: VIDEO_COMMENT_PRIVACY_CHANGED,
        payload: { videoId, commentId, privacy },
      });
      successNotification('Video comment privacy changed!');
    } catch (err) {
      logErrorResponse(err, 'changeCommentPrivacy failed ');
    }
  };

export const updateComment =
  (videoId: string, commentId: string, comment: Comment, token: string) => async (dispatch: (action: VideoCommentActionTypes) => void) => {
    try {
      const commentReq = makeCommentRequest(videoId, comment);
      const res = await figaroApi.put(`${submissionCommentsURL}/${commentId}`, commentReq, withAuthorizationToken(authOptions, token));

      console.log('updateComment response: ', res.data);
      const commentResponse: CommentResponse = res.data;
      const commentResult = makeComment(commentResponse);
      dispatch({
        type: VIDEO_COMMENT_UPDATED,
        payload: { videoId, comment: commentResult },
        // videoId: commentResponse.submission.id + '' /* videoId*/,
      });
      successNotification('Video comment updated!');
    } catch (err) {
      logErrorResponse(err, 'updateComment failed ');
    }
  };

export const addHighlight =
  (videoId: string, highlight: Highlight, token: string) => async (dispatch: (action: VideoHighlightActionTypes) => void) => {
    try {
      const highlightReq = makeHighlightRequest(videoId, highlight);
      const res = await figaroApi.post(`${submissionHighlightsURL}`, highlightReq, withAuthorizationToken(authOptions, token));
      console.log('addHighlight request: ', highlightReq, ', response: ', res.data);
      const hlResponse: HighlightResponse = res.data;
      const highlightResult = makeHighlight(hlResponse);
      dispatch({
        type: VIDEO_HIGHLIGHT_ADDED,
        payload: {
          videoId: hlResponse.submission.id + '' /* videoId*/,
          highlight: highlightResult,
        },
      });
      successNotification('Video highlight added!');
      dispatch({
        // TODO FFS we cannot dispatch this from the caller, since it doesn't know the newly allocated id;
        // at most, we can have an additional boolean arg dispatchSelected
        type: VIDEO_HIGHLIGHT_SELECTED,
        payload: {
          videoId: hlResponse.submission.id + '' /* videoId*/,
          highlight: highlightResult,
        },
      });
    } catch (err) {
      logErrorResponse(err, 'addHighlight failed ');
    }
  };
export const deleteHighlight =
  (videoId: string, highlightId: string, token: string) => async (dispatch: (action: VideoHighlightActionTypes) => void) => {
    try {
      const res = await figaroApi.delete(`${submissionHighlightsURL}/${highlightId}`, withAuthorizationToken(authOptions, token));
      console.log('deleteHighlight request: ', highlightId, ', response: ', res.data);
      dispatch({
        type: VIDEO_HIGHLIGHT_DELETED,
        payload: { videoId, highlightId },
      });
      warningNotification('Video highlight deleted!');
    } catch (err) {
      logErrorResponse(err, 'deleteHighlight failed ');
    }
  };

/*
export const updateThreshold = (videoId: string, threshold: number, token: string) => async (
  dispatch: (action: CurrentVideoActionTypes) => void
) => {
  try {
    dispatch({
      type: VIDEO_THRESHOLD_UPDATED,
      payload: {
        videoId,
        threshold,
      },
    });
    // successNotification('Threshold updated! (NOT sent to the backend!!!)'); TODO FFS
  } catch (err) {
    logErrorResponse(err, 'Threshold update failed ');
  }
};
*/
export const updateHighlight =
  (videoId: string, highlightId: string, highlight: Highlight, token: string) =>
  async (dispatch: (action: VideoHighlightActionTypes) => void) => {
    try {
      const highlightReq = makeHighlightRequest(videoId, highlight);
      const res = await figaroApi.put(
        `${submissionHighlightsURL}/${highlightId}`,
        highlightReq,
        withAuthorizationToken(authOptions, token)
      );
      console.log('updateHighlight request: ', highlightReq, ', response: ', res.data);
      const hlResponse: HighlightResponse = res.data;
      const highlightResult = makeHighlight(hlResponse);
      dispatch({
        type: VIDEO_HIGHLIGHT_UPDATED,
        payload: {
          videoId: hlResponse.submission.id + '',
          highlightId: hlResponse.id + '',
          highlight: highlightResult,
        },
      });
      successNotification('Highlight updated!');
    } catch (err) {
      logErrorResponse(err, 'updateHighlight failed ');
    }
  };

export const changeHighlightPrivacy =
  (videoId: string, highlightId: string, privacy: string, token: string) =>
  async (dispatch: (action: VideoHighlightActionTypes) => void) => {
    try {
      const highlightReq = {
        state: privacy,
      };
      const res = await figaroApi.put(
        `${submissionHighlightsURL}/${highlightId}`,
        highlightReq,
        withAuthorizationToken(authOptions, token)
      );
      console.log('changeHighlightPrivacy response: ', res.data);
      const hlResponse: HighlightResponse = res.data;
      const highlightResult = makeHighlight(hlResponse);

      dispatch({
        type: VIDEO_HIGHLIGHT_PRIVACY_CHANGED,
        payload: { videoId, highlightId, /* privacy */ highlight: highlightResult },
      });
      successNotification('Highlight privacy changed!');
    } catch (err) {
      logErrorResponse(err, 'changeHighlightPrivacy failed');
    }
  };

// Submissions
// todo pagination
export const getSubmissions = (token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
  try {
    console.log(`calling ${submissionsURL}?_limit=1000&_sort=id:asc`);
    const response = await figaroApi.get(`${submissionsURL}?_limit=1000&_sort=id:asc`, withAuthorizationToken(authOptions, token));
    const submissionsResponse: Submission[] = response.data;
    const videos: Video[] = makeVideosShallow(submissionsResponse);
    dispatch({
      type: SUBMISSIONS,
      payload: { submissions: submissionsResponse, videos },
    }); // todo pagination
  } catch (err) {
    logErrorResponse(err, 'getSubmissions failed');
  }
};

// todo pagination
export const getSubmissionsAskedToOthers =
  (userId: number, token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
    try {
      console.log(`calling ${submissionsURL}?_limit=1000&_start=1&_sort=id:asc&createdby=${userId}`);
      const response = await figaroApi.get(
        `${submissionsURL}?_limit=1000&_start=1&_sort=id:asc&createdby=${userId}`,
        withAuthorizationToken(authOptions, token)
      );
      const submissionsResponse: Submission[] = response.data;
      const videos: Video[] = makeVideosShallow(submissionsResponse);
      dispatch({
        type: SUBMISSIONS_ASKED_TO_OTHERS,
        payload: { submissions: submissionsResponse, videos },
      }); // todo pagination
    } catch (err) {
      logErrorResponse(err, 'getSubmissionsAskedToOthers failed');
    }
  };

// todo pagination
export const getSubmissionsToUpload = (userId: number, token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
  try {
    const response = await figaroApi.get(
      `${submissionsURL}?_limit=1000&_start=1&_sort=id:asc&user=${userId}`,
      withAuthorizationToken(authOptions, token)
    );
    const submissionsResponse: Submission[] = response.data;
    const videos: Video[] = makeVideosShallow(submissionsResponse);
    dispatch({
      type: SUBMISSIONS_TO_UPLOAD,
      payload: { submissions: submissionsResponse, videos },
    }); // todo pagination
  } catch (err) {
    logErrorResponse(err, 'getSubmissionsToUpload failed');
  }
};

export const getSubmissionUploadInfo =
  (submission: Submission, token: string) => async (dispatch: (action: SubmissionsActionTypes /*|any*/) => void) => {
    try {
      if (submission.state === SubmissionState.requested) {
        console.log(' getSubmissionUploadInfo retrieving Upload infor about available subm : ', submission);

        const response = await figaroApi.get(`${submissionsURL}/${submission.id}`, withAuthorizationToken(authOptions, token));
        const submissionResponse: SubmissionUploadInfo = response.data;
        dispatch({
          type: SUBMISSION_UPLOAD_INFO,
          payload: { submissionId: submission.id, submissionUploadInfo: submissionResponse },
        });
      } else {
        console.warn('cannot get upload info for state != requested, ', submission);
      }
    } catch (err) {
      logErrorResponse(err, 'Retrieve selected submission Upload info error');
    }
  };
//TODO ASK WING
const WORKAROUND_URL_FOR_MISSING_SENTIMENT_URL = 'video.sentiment.json';

const fixMissingSentimentURL = (sentiment: AnalysisItem) => {
  if (!sentiment.url || !sentiment.url.length) {
    sentiment.url = WORKAROUND_URL_FOR_MISSING_SENTIMENT_URL;
  }
};

export const getSubmissionInfoInParallel =
  (submission: Submission, token: string) => async (dispatch: (action: SubmissionsActionTypes | SettingsActionTypes | any) => void) => {
    try {
      let voiceAnalysis: VoiceAnalysis = null;
      let submissionRubricsResponse = [] as SubmissionRubricResponse[];
      let submissionSharesResponse = [] as SubmissionShareResponse[];
      const faceAnalysis: FaceAnalysis[] = [];
      let transcript: Transcript = null;
      let comments: Comment[] = [];
      let hls: Highlight[] = [];
      const metaFaceAnalysesMap: Urlid2FaceAnalysisMetaMap = {};
      let sentiment: SentimentAnalysis = null;

      if (submission.state === SubmissionState.available /*|| submission.state === SUBMISSION_STATE_PROCESSING*/) {
        let voicePromise: Promise<AxiosResponse<any>>,
          transcriptPromise: Promise<AxiosResponse<any>>,
          highlightsPromise: Promise<AxiosResponse<any>>,
          commentsPromise: Promise<AxiosResponse<any>>,
          rubricsPromise: Promise<AxiosResponse<any>>,
          sharesPromise: Promise<AxiosResponse<any>>,
          sentimentPromise: Promise<AxiosResponse<any>>;
        const facePromises: Promise<AxiosResponse<any>>[] = [];
        const facePromisesUrls: string[] = [];
        // todo ask about state
        console.log(' submissionSelected : retrieving more infor about available subm : ', submission);
        // todo ask about status
        if (submission.analysis && submission.analysis.voice && submission.analysis.voice.status === AnalysisItemStatus.available) {
          // retreive what's at submission.analysis.voice.url, as VoiceAnalysis
          console.log(' submissionSelected : launch voice : ');
          voicePromise = axios.get(
            // TODO ASK NOAUTH?
            //CRFFS //`${submission.analysis.source}${submission.analysis.voice.url}` // ,withAuthorizationToken(authOptions, token)
            `${s3URL}${submission.uuid}/${submission.analysis.voice.url}` // ,withAuthorizationToken(authOptions, token)ENV
          );
        }
        if (
          submission.analysis &&
          submission.analysis.transcript &&
          submission.analysis.transcript.status === AnalysisItemStatus.available
        ) {
          // retreive what's at submission.analysis.transcript.url, as Transcript
          console.log(' submissionSelected : launch transcript : ');

          //CRFFS
          // transcriptPromise = axios.get(
          //   `${submission.analysis.source}${submission.analysis.transcript.url}` // ,withAuthorizationToken(authOptions, token)           // TODO ASK NOAUTH?
          // );
          transcriptPromise = axios.get(
            `${s3URL}${submission.uuid}/${submission.analysis.transcript.url}` // ,withAuthorizationToken(authOptions, token)           // TODO ASK NOAUTH?
          );
        }
        if (submission.analysis && submission.analysis.faces) {
          for (const face of submission.analysis.faces) {
            if (AnalysisItemStatus.hasData(face.status)) {
              console.log(' submissionSelected : launch a face : ');
              // todo ask
              // retreive what's at face.url, as FaceAnalysis

              //CRFFS
              // const response = axios.get(`${submission.analysis.source}${face.url}`); // , withAuthorizationToken(authOptions, token));           // TODO ASK NOAUTH?
              const response = axios.get(`${s3URL}${submission.uuid}/${face.url}`); // , withAuthorizationToken(authOptions, token));           // TODO ASK NOAUTH?
              facePromises.push(response);
              facePromisesUrls.push(face.url);
            }
            metaFaceAnalysesMap[face.url] = { ...face };
          }
        }
        // TODO FFS pagination!?
        console.log(' submissionSelected : launch comments : ');
        commentsPromise = figaroApi.get(
          `${submissionCommentsURL}?submission=${submission.id}&_limit=1000000&_sort=videotime`,
          withAuthorizationToken(authOptions, token)
        );
        console.log(' submissionSelected : launch hls : ');
        highlightsPromise = figaroApi.get(
          `${submissionHighlightsURL}?submission=${submission.id}&_limit=1000000&_sort=starttime`,
          withAuthorizationToken(authOptions, token)
        );
        console.log(' submissionSelected : launch submissionrubrics : ');
        rubricsPromise = figaroApi.get(`${submissionRubricsURL}?submission=${submission.id}`, withAuthorizationToken(authOptions, token));
        console.log(' submissionSelected : launch submissionshares : ');
        sharesPromise = figaroApi.get(`${submissionSharesURL}?submission=${submission.id}`, withAuthorizationToken(authOptions, token));

        if (submission.analysis && submission.analysis.sentiment && submission.analysis.sentiment.status === AnalysisItemStatus.available) {
          fixMissingSentimentURL(submission.analysis.sentiment);
          // retreive what's at submission.analysis.sentiment.url, as SentimentAnalysis
          console.log(' submissionSelected : launch sentiment : ');
          //CRFFS
          // sentimentPromise = axios.get(
          //   `${submission.analysis.source}${submission.analysis.sentiment.url}` // ,withAuthorizationToken(authOptions, token)           // TODO ASK NOAUTH?
          // );
          sentimentPromise = axios.get(
            `${s3URL}${submission.uuid}/${submission.analysis.sentiment.url}` // ,withAuthorizationToken(authOptions, token)           // TODO ASK NOAUTH?
          );
        }

        console.log(' submissionSelected : wait all : ');

        const allPromises = [
          transcriptPromise,
          commentsPromise,
          highlightsPromise,
          voicePromise,
          rubricsPromise,
          sharesPromise,
          sentimentPromise,
        ].concat(facePromises);
        //TODO FFS TES/FIX WHAT IF JUST PART OF THEM WERE AVAILABLE< SO PROMISES ARE NULL
        const allResponses = await Promise.allSettled(allPromises);
        console.log(' submissionSelected : all promises settled ');
        const FULFILLED = 'fulfilled';
        if (allResponses[0].status === FULFILLED) {
          transcript = processTranscript(allResponses[0].value.data as Transcript);
          console.log(' submissionSelected :  transcript (processed): ', transcript);
        } else {
          logErrorResponse(null, 'Get transcript error ' + allResponses[0].reason);
        }
        if (allResponses[1].status === FULFILLED) {
          const commentsResponse = allResponses[1].value.data as CommentResponse[];
          console.log(' submissionSelected :  comments : ', commentsResponse);
          comments = commentsResponse.map((commentResponse) => makeComment(commentResponse));
        } else {
          logErrorResponse(null, 'Get comments error ' + allResponses[1].reason);
        }
        if (allResponses[2].status === FULFILLED) {
          const hlsResponse = allResponses[2].value.data as HighlightResponse[];
          console.log(' submissionSelected :  highlights : ', hlsResponse);
          hls = hlsResponse.map((hl) => makeHighlight(hl));
        } else {
          logErrorResponse(null, 'Get highlights error ' + allResponses[2].reason);
        }
        if (allResponses[3].status === FULFILLED) {
          voiceAnalysis = allResponses[3].value.data as VoiceAnalysis;
          console.log(' submissionSelected : voice analysis : ', voiceAnalysis);
        } else {
          logErrorResponse(null, 'Get voice analysis error  ' + allResponses[3].reason);
        }
        if (allResponses[4].status === FULFILLED) {
          submissionRubricsResponse = allResponses[4].value.data as SubmissionRubricResponse[];
          console.log(' submissionSelected : submission rubrics : ', submissionRubricsResponse);
        } else {
          logErrorResponse(null, 'Get submission rubrics error  ' + allResponses[4].reason);
        }
        if (allResponses[5].status === FULFILLED) {
          submissionSharesResponse = allResponses[5].value.data as SubmissionShareResponse[];
          console.log(' submissionSelected : submission shares : ', submissionSharesResponse);
        } else {
          logErrorResponse(null, 'Get submission shares error  ' + allResponses[5].reason);
        }
        if (allResponses[6].status === FULFILLED) {
          if (sentimentPromise) {
            sentiment = allResponses[6].value.data as SentimentAnalysis;
            console.log(' submissionSelected :  sentiment : ', sentiment);
          }
        } else {
          logErrorResponse(null, 'Get sentiment error ' + allResponses[6].reason);
        }

        for (let i = 0; i < facePromises.length; i++) {
          if (allResponses[7 + i].status === FULFILLED) {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            let dataF = (allResponses[7 + i] as PromiseFulfilledResult<AxiosResponse>).value.data as FaceAnalysis;

            faceAnalysis.push({ ...dataF, urlid: facePromisesUrls[i] });
            console.log(' submissionSelected :  a face : ', faceAnalysis[i]);
          } else {
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            logErrorResponse(null, 'Get a face analysis error  ' + (allResponses[7 + i] as PromiseRejectedResult).reason);
          }
        }
      }

      //TODO TOD REMOVE THIS AFTER TESTING
      //THIS IS JUST FOR CHECKING NEW SENTIMET CHARTS LAYOUT AND FUNCT WHEN 2 speakers - currently sentiment is generated for new videos, all with  just 1 speaker
      if (!sentiment && faceAnalysis && faceAnalysis.length === 2) {
        sentiment = {
          type: 'sentiment',
          version: '1.0.0',
          created: '2021-07-27T07:34:41.873086',
          name: 'Sentiment',
          data: { Joy: 0.05, Anger: 0.24, Surprise: 0.08, Sadness: 0.14, Fear: 0.49, Other: 0 },
        };
      }
      const video: Video = makeVideo(
        submission,
        voiceAnalysis ? [voiceAnalysis] : [],
        faceAnalysis,
        transcript,
        comments,
        hls,
        submissionRubricsResponse,
        submissionSharesResponse,
        sentiment,
        metaFaceAnalysesMap
      );
      // dispatch info about both Video and Submission
      console.log(' submissionSelected : dispatching  SUBMISSION_SELECTED ');
      dispatch({
        type: SUBMISSION_ENHANCED_INFO,
        payload: { submission, video },
      });
      if (submissionRubricsResponse) {
        for (const submissionRubricResponse of submissionRubricsResponse) {
          if (submissionRubricResponse.state === 'available') {
            console.log(' submissionSelected : launch get evaluations for submissionRubricResponse: ', submissionRubricResponse);
            dispatch(getSubmissionEvaluations(submission.id, submissionRubricResponse.id, token));
          }
        }
      }
    } catch (err) {
      logErrorResponse(err, 'Retrieve selected submission error: ');
    }
  };

// download voice, transcript, faces, etc  jsons for the submission
// then convert to my data format ( Video)
export const getSubmissionInfoInSequence =
  (submission: Submission, token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
    try {
      let voiceAnalysis: VoiceAnalysis = null;
      const faceAnalysis: FaceAnalysis[] = [];
      let transcript: Transcript = null;
      let comments: Comment[] = [];
      let hls: Highlight[] = [];
      let sentiment: SentimentAnalysis = null;

      if (submission.state === SubmissionState.available) {
        // todo ask about state
        console.log(' submissionSelected : retreiving more info about available submission: ', submission);
        // todo ask about status
        if (submission.analysis && submission.analysis.voice && submission.analysis.voice.status === AnalysisItemStatus.available) {
          // retreive what's at submission.analysis.voice.url, as VoiceAnalysis
          try {
            console.log(' submissionSelected : wait voice : ');
            const response = await axios.get(
              // TODO ASK NOAUTH?
              `${submission.analysis.source}${submission.analysis.voice.url}` // ,withAuthorizationToken(authOptions, token)
            );
            voiceAnalysis = response.data;
            console.log(' submissionSelected : got voice analysis : ', voiceAnalysis);
          } catch (err) {
            logErrorResponse(err, 'Get voice analysis error ');
          }
        }
        if (
          submission.analysis &&
          submission.analysis.transcript &&
          submission.analysis.transcript.status === AnalysisItemStatus.available
        ) {
          // retreive what's at submission.analysis.transcript.url, as Transcript
          try {
            console.log(' submissionSelected : wait transcript : ');
            const response = await axios.get(
              `${submission.analysis.source}${submission.analysis.transcript.url}` // ,withAuthorizationToken(authOptions, token)           // TODO ASK NOAUTH?
            );
            transcript = processTranscript(response.data as Transcript);
            console.log(' submissionSelected : got transcript (processed): ', transcript);
          } catch (err) {
            logErrorResponse(err, 'Get transcript error');
          }
        }
        if (submission.analysis && submission.analysis.sentiment && submission.analysis.sentiment.status === AnalysisItemStatus.available) {
          // retreive what's at submission.analysis.sentiment.url,
          try {
            console.log(' submissionSelected : wait sentiment : ');
            const response = await axios.get(
              `${submission.analysis.source}${submission.analysis.sentiment.url}` // ,withAuthorizationToken(authOptions, token)           // TODO ASK NOAUTH?
            );
            sentiment = response.data as SentimentAnalysis;
            console.log(' submissionSelected : got sentiment: ', sentiment);
          } catch (err) {
            logErrorResponse(err, 'Get sentiment error');
          }
        }

        if (submission.analysis && submission.analysis.faces) {
          for (const face of submission.analysis.faces) {
            if (face.status === AnalysisItemStatus.available) {
              try {
                console.log(' submissionSelected : wait a face : ');
                // todo ask
                // retreive what's at face.url, as FaceAnalysis
                const response = await axios.get(`${submission.analysis.source}${face.url}`); // , withAuthorizationToken(authOptions, token));           // TODO ASK NOAUTH?
                faceAnalysis.push(response.data as FaceAnalysis);
                console.log(' submissionSelected : got a face : ', response.data);
              } catch (err) {
                logErrorResponse(err, 'Get a face analysis error');
              }
            }
          }
        }
        // TODO FFS pagination!?
        try {
          console.log(' submissionSelected : wait comments : ');
          const response = await figaroApi.get(
            `${submissionCommentsURL}?submission=${submission.id}&_limit=1000000&_sort=videotime`,
            withAuthorizationToken(authOptions, token)
          );
          console.log(' submissionSelected : got comments : ', response.data);
          const commentsResponse = response.data as CommentResponse[];
          comments = commentsResponse.map((commentResponse) => makeComment(commentResponse));
        } catch (err) {
          logErrorResponse(err, 'Get comments error');
        }
        try {
          console.log(' submissionSelected : wait hls : ');
          const response = await figaroApi.get(
            `${submissionHighlightsURL}?submission=${submission.id}&_limit=1000000&_sort=starttime`,
            withAuthorizationToken(authOptions, token)
          );
          console.log(' submissionSelected : got hls : ', response.data);
          const hlsResponse = response.data as HighlightResponse[];
          hls = hlsResponse.map((hl) => makeHighlight(hl));
        } catch (err) {
          logErrorResponse(err, 'Get highlights error');
        }
      }
      const video: Video = makeVideo(
        submission,
        voiceAnalysis ? [voiceAnalysis] : [],
        faceAnalysis,
        transcript,
        comments,
        hls,
        /*TODO TODO TODO get submission rubrics in sequence too*/ [],
        /*TODO TODO TODO get submission shares in sequence too*/ [],
        sentiment,
        null //TODO TODO metaFaceAnalysesMap: Urlid2FaceAnalysisMetaMap like in ParallelProcessing
      );
      // dispatch info about both Video and Submission
      console.log(' submissionSelected : dispatching  SUBMISSION_ENHANCED_INFO ');
      dispatch({
        type: SUBMISSION_ENHANCED_INFO,
        payload: { submission, video },
      });
    } catch (err) {
      logErrorResponse(err, 'Retrieve selected submission error');
    }
  };

export const getSubmissionEnhancedInfo = config.submissions.parallel ? getSubmissionInfoInParallel : getSubmissionInfoInSequence;

export const getSubmissionANDEnhancedOrUploadInfo =
  (submissionId: number, token: string) => async (dispatch: (action: SubmissionsActionTypes | any) => void) => {
    try {
      console.log(' getSubmissionAndEnhancedInfo : retrieving meta  info for  submission : ', submissionId);
      // retrieve meta then retrieve enhanced info
      const response = await figaroApi.get(`${submissionsURL}/${submissionId}`, withAuthorizationToken(authOptions, token));
      if (
        response.data &&
        response.data.state &&
        (response.data.state === SubmissionState.available || response.data.state === SubmissionState.processing)
      ) {
        const submissionResponse: Submission = response.data;
        const video: Video = makeVideoShallow(submissionResponse);
        dispatch({
          type: SUBMISSION_META,
          payload: { submission: submissionResponse, video },
        });
        console.log(' getSubmissionAndEnhancedInfo : get enhanced  info about  subm : ', submissionId);
        dispatch(getSubmissionEnhancedInfo(submissionResponse, token));
      } else {
        console.log(' getSubmissionAndEnhancedInfo : got info about  subm w UPLOAD info: ', submissionId);
        const submissionResponse: SubmissionUploadInfo = response.data;
        dispatch({
          type: SUBMISSION_UPLOAD_INFO,
          payload: { submissionId: submissionId, submissionUploadInfo: submissionResponse },
        });
      }
    } catch (err) {
      logErrorResponse(err, `getSubmissionAndEnhancedInfo error for submission ${submissionId} : `);
    }
  };

export const requestSubmissionFromUser =
  (submissionName: string, details: string, deadline: string, token: string, userId?: number, email?: string) =>
  async (dispatch: (action: SubmissionsActionTypes) => void) => {
    try {
      const submission = {
        // user: results.auth.user.id, // Target user (to upload video), set to myself
        user: userId,
        email,
        name: submissionName,
        details,
        deadline,
      };

      console.log(`requestSubmission calling ${submissionsURL} with `, submission);
      const response = await figaroApi.post(`${submissionsURL}`, submission, withAuthorizationToken(authOptions, token));
      const submissionResponse: Submission = response.data;
      const video: Video = makeVideoShallow(submissionResponse);
      dispatch({
        type: SUBMISSION_META,
        payload: { submission: submissionResponse, video },
      });
      successNotification(`Submission requested from ${email} !`);
    } catch (err) {
      logErrorResponse(err, `RequestSubmissionFromUser ${email} failed, error: `);
    }
  };

export const uploadFile =
  (file: File, awsFields: AWSUploadFields, submissionID: number, token: string) =>
  async (dispatch: (action: SubmissionsActionTypes) => void) => {
    const { url, fields } = awsFields;
    const data = { ...fields, 'Content-Type': file.type, file: file };
    const formData = new FormData();
    for (const name in data) {
      formData.append(name, data[name]);
    }
    try {
      const response = await axios.post(url, formData);
      // const response = await fetch(url, { method: 'POST', body: formData });

      console.log('ok Response from s3 ', response);

      //successNotification('Video UPLOADED!');
      try {
        const res = await figaroApi.put(`${submissionsURL}/process/${submissionID}`, {}, withAuthorizationToken(authOptions, token));
        const submissionResponse: Submission = res.data;
        console.log('process() rezult ', submissionResponse);
        const video: Video = makeVideoShallow(submissionResponse);
        dispatch({
          type: SUBMISSION_META,
          payload: { submission: submissionResponse, video },
        });
        console.log('after process(): getSubmissionAndEnhancedInfo : get enhanced  info about  subm : ', submissionID);
        successNotification('Video UPLOADED!');
        return Promise.resolve(submissionID);
      } catch (err) {
        console.log('after upload: process() err: ', err);
        logErrorResponse(err, 'Processing upload file failed ');
        let res: FigApiError = { error: err, step: STEP_PROCESSING };
        return Promise.reject(res);
      }
    } catch (error) {
      console.warn('AWS post ', error);
      console.warn(error.request);
      console.warn(error.response.headers);
      console.warn(error.response.data);
      logErrorResponse(error, 'Uploading file failed ');
      let res: FigApiError = { error, step: STEP_UPLOADING };
      return Promise.reject(res);
    }
  };

export const STEP_PROCESSING = 'processing';
export const STEP_UPLOADING = 'uploading';
export interface FigApiError {
  error: any;
  step: string;
}
// End Submissions

// get global settings for datagroups
export const getDatagroups = (token: string) => async (dispatch: (action: SettingsActionTypes) => void) => {
  try {
    console.log(' getDatagroups : retrieving info   ');
    const response = await figaroApi.get(`${settingsURL}?key=datagroups`, withAuthorizationToken(authOptions, token));
    const settings: SettingsDatagroups[] = response.data;
    dispatch({
      type: SETTINGS_DATAGROUPS,
      payload:
        settings && settings.length > 0 && settings[0].options && settings[0].options.groups
          ? {
              allFaceCategories: settings[0].options.groups.Face,
              allVoiceCategories: settings[0].options.groups.Voice,
            }
          : { allFaceCategories: {}, allVoiceCategories: {} },
    });
  } catch (err) {
    logErrorResponse(err, 'getDatagroups error: ');
  }
};
// END Datagroups

// DISFLUENCIES
// get global settings for disfluencies
export const getDisfluencies = (token: string) => async (dispatch: (action: SettingsActionTypes) => void) => {
  try {
    console.log(' getDisfluencies : retrieving info   ');
    const response = await figaroApi.get(`${settingsURL}?key=disfluencies`, withAuthorizationToken(authOptions, token));
    const settings: SettingsDisfluencies[] = response.data;
    dispatch({
      type: SETTINGS_DISFLUENCIES,
      payload: { disfluencies: settings && settings.length > 0 ? settings[0] : null },
    });
  } catch (err) {
    logErrorResponse(err, 'getDisfluencies error: ');
  }
};

//TODO ASK should the add/remove disfluecy buttons be enabled/shown just for the CREATOR of the submission
export const addSubmissionDisfluency =
  (submission: Submission, word: string, token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
    try {
      const updateSubmissionReq = makeAddDisfluencyRequest(submission, word);
      const res = await figaroApi.put(
        `${submissionsURL}/${submission.id}`,
        updateSubmissionReq,
        withAuthorizationToken(authOptions, token)
      );
      const submissionResponse: Submission = res.data;
      console.log('addSubmissionDisfluency request: ', updateSubmissionReq, ', response: ', res.data);
      dispatch({
        type: SUBMISSION_UPDATED_DISFLUENCIES,
        payload: {
          disfluencies: res.data && res.data.options && res.data.options.text_search ? res.data.options.text_search : [],
          submission: submissionResponse,
        },
      });
      successNotification('Disfluency term added!');
    } catch (err) {
      logErrorResponse(err, 'addSubmissionDisfluency failed ');
    }
  };
export const removeSubmissionDisfluency =
  (submission: Submission, word: string, token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
    try {
      const updateSubmissionReq = makeRemoveDisfluencyRequest(submission, word);
      const res = await figaroApi.put(
        `${submissionsURL}/${submission.id}`,
        updateSubmissionReq,
        withAuthorizationToken(authOptions, token)
      );
      const submissionResponse: Submission = res.data;
      console.log('removeSubmissionDisfluency request: ', updateSubmissionReq, ', response: ', res.data);
      dispatch({
        type: SUBMISSION_UPDATED_DISFLUENCIES,
        payload: {
          disfluencies: res.data && res.data.options && res.data.options.text_search ? res.data.options.text_search : [],
          submission: submissionResponse,
        },
      });
      warningNotification('Disfluency term removed!');
    } catch (err) {
      logErrorResponse(err, 'removeSubmissionDisfluency failed ');
    }
  };

export const makeRemoveDisfluencyRequest = (submission: Submission, word: string) => {
  let options = { ...submission.options };
  let textSearch = [...options.text_search];

  const index = textSearch.indexOf(word);
  if (index > -1) {
    textSearch.splice(index, 1);
  }
  options.text_search = textSearch;
  const submissionU = {
    options,
  };
  return submissionU;
};

export const makeAddDisfluencyRequest = (submission: Submission, word: string) => {
  const submissionU = {
    options: {
      /* ...submission.options,*/ text_search:
        submission.options && submission.options.text_search ? [...submission.options.text_search, word] : [word],
    },
  };
  return submissionU;
};

// End Disfluencies

// Rubrics
export const getRubric = (rubricId: number, token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
  try {
    console.log(' getRubric starting');
    const response = await figaroApi.get(`${rubricsURL}/${rubricId}`, withAuthorizationToken(authOptions, token));
    const rubrics: Rubric[] = response.data;
    dispatch({
      type: RUBRIC_UPDATED,
      payload: { rubricId, rubric: rubrics[0] },
    });
  } catch (err) {
    logErrorResponse(err, 'getRubric error: ');
  }
};

export const getRubrics = (token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
  try {
    console.log(' getRubrics starting');
    const response = await figaroApi.get(`${rubricsURL}?_limit=1000000&_sort=id`, withAuthorizationToken(authOptions, token));
    const rubrics: Rubric[] = response.data;
    dispatch({
      type: OWN_RUBRICS,
      payload: { rubrics: rubrics },
    });
  } catch (err) {
    logErrorResponse(err, 'getRubrics error: ');
  }
};

export const getGlobalRubrics = (token: string) => async (dispatch: (action: RubricsActionTypes | GlobalRubricsAction) => void) => {
  try {
    console.log(' getRubricSettings starting');
    const response = await figaroApi.get(`${settingsURL}?key=rubrics`, withAuthorizationToken(authOptions, token));
    let rubrics: RubricBase[] = [];
    const settings: any[] = response.data;
    if (settings.length > 0 && settings[0].options && settings[0].options.rubrics) {
      rubrics = settings[0].options.rubrics as RubricBase[];
    }
    dispatch({
      type: SETTINGS_GLOBAL_RUBRICS,
      payload: { rubrics: rubrics },
    });
  } catch (err) {
    logErrorResponse(err, 'getRubricSettings error: ');
  }
};

export const deleteRubric = (rubricId: number, token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
  try {
    const res = await figaroApi.delete(`${rubricsURL}/${rubricId}`, withAuthorizationToken(authOptions, token));
    console.log('deleteRubric request: ', rubricId, ', response: ', res.data);
    dispatch({
      type: RUBRIC_DELETED,
      payload: { rubricId },
    });
    warningNotification('Rubric  deleted!');
  } catch (err) {
    logErrorResponse(err, 'deleteRubric failed ');
  }
};

export const createRubric = (rubric: RubricBase, token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
  try {
    const res = await figaroApi.post(`${rubricsURL}`, rubric, withAuthorizationToken(authOptions, token));
    console.log('createRubric request: ', rubric, ', response: ', res.data);
    const response = res.data as Rubric;
    dispatch({
      type: RUBRIC_ADDED,
      payload: { rubricId: response.id, rubric: response },
    });
    successNotification('Rubric  created!');
    return Promise.resolve(response.id);
  } catch (err) {
    logErrorResponse(err, 'createRubric failed ');
    return Promise.reject();
  }
};
export const updateRubric =
  (id: number, rubricUpdate: RubricBase, token: string) => async (dispatch: (action: RubricsActionTypes) => void) => {
    try {
      const updated = { ...rubricUpdate };
      const res = await figaroApi.put(`${rubricsURL}/${id}`, updated, withAuthorizationToken(authOptions, token));
      console.log('updateRubric request: ', rubricUpdate, ', response: ', res.data);
      dispatch({
        type: RUBRIC_UPDATED,
        payload: { rubricId: id, rubric: res.data as Rubric },
      });
      successNotification('Rubric  updated!');
    } catch (err) {
      logErrorResponse(err, 'updateRubric failed ');
    }
  };

// SubmissionRubrics
export const getSubmissionRubrics =
  (submissionId: number, token: string) => async (dispatch: (action: SubmissionRubricsActionTypes) => void) => {
    try {
      console.log(' getRubrics starting');
      const response = await figaroApi.get(
        `${submissionRubricsURL}?submission=${submissionId}`,
        withAuthorizationToken(authOptions, token)
      );
      const rubrics: SubmissionRubricResponse[] = response.data;
      dispatch({
        type: SUBMISSION_RUBRICS,
        payload: {
          videoId: submissionId,
          submissionRubrics: rubrics.map((submissionrubric) => {
            return makeSubmissionRubric(submissionrubric, submissionId);
          }),
        },
      });
    } catch (err) {
      logErrorResponse(err, 'getRubrics error: ');
    }
  };

export const createSubmissionRubric =
  (rubric: SubmissionRubric, token: string) => async (dispatch: (action: SubmissionRubricsActionTypes) => void) => {
    try {
      const res = await figaroApi.post(`${submissionRubricsURL}`, rubric, withAuthorizationToken(authOptions, token));
      console.log('createSubmissionRubric request: ', rubric, ', response: ', res.data);
      const response = res.data as SubmissionRubricResponse;
      dispatch({
        type: SUBMISSION_RUBRIC_ADDED,
        payload: {
          videoId: rubric.submission,
          submissionRubricId: response.id,
          submissionRubric: { ...rubric, id: response.id, createdby: response.createdby },
        },
      });
      successNotification('SubmissionRubric  created!');
      return Promise.resolve(response.id);
    } catch (err) {
      logErrorResponse(err, 'SubmissionRubric failed ');
      return Promise.reject();
    }
  };
export const deleteSubmissionRubric =
  (rubricId: number, token: string) => async (dispatch: (action: SubmissionRubricsActionTypes) => void) => {
    try {
      const res = await figaroApi.delete(`${submissionRubricsURL}/${rubricId}`, withAuthorizationToken(authOptions, token));
      console.log('deleteSubmissionRubric request: ', rubricId, ', response: ', res.data);
      const response = res.data as SubmissionRubricResponse;
      dispatch({
        type: SUBMISSION_RUBRIC_DELETED,
        payload: { videoId: response.submission.id, submissionRubricId: rubricId },
      });
      warningNotification('SubmissionRubric  deleted!');
      // return Promise.resolve(response.id);
    } catch (err) {
      logErrorResponse(err, 'deleteSubmissionRubric failed ');
      // return Promise.reject();
    }
  };
export const updateSubmissionRubricName =
  (rubricId: number, name: string, token: string) => async (dispatch: (action: SubmissionRubricsActionTypes) => void) => {
    try {
      const updated = { name };
      const res = await figaroApi.put(`${submissionRubricsURL}/${rubricId}`, updated, withAuthorizationToken(authOptions, token));
      console.log('updateSubmissionRubricName request: ', rubricId, ', response: ', res.data);
      const response = res.data as SubmissionRubricResponse;
      dispatch({
        type: SUBMISSION_RUBRIC_UPDATED,
        payload: { videoId: response.submission.id, submissionRubricId: rubricId, name },
      });
      successNotification('SubmissionRubric  updated!');
    } catch (err) {
      logErrorResponse(err, 'updateSubmissionRubricName failed ');
    }
  };
//End Submission Rubrics

// SubmissionEvaluations
export const getSubmissionEvaluations =
  (videoId: number, submissionRubricId: number, token: string) =>
  async (dispatch: (action: SubmissionRubricEvaluationsActionTypes) => void) => {
    try {
      console.log(' getSubmissionEvaluations starting, for submissionRubricId ', submissionRubricId);
      const response = await figaroApi.get(
        `${submissionEvaluationsURL}?submissionrubrics=${submissionRubricId}`,
        withAuthorizationToken(authOptions, token)
      );
      const evaluations: SubmissionRubricEvaluationResponse[] = response.data;
      console.log(' getSubmissionEvaluations dispatching result, w submissionRubricId/evaluations ', submissionRubricId, evaluations);
      dispatch({
        type: SUBMISSION_RUBRIC_EVALUATIONS,
        payload: {
          videoId,
          submissionRubricId: submissionRubricId,
          submissionRubricEvaluations: evaluations.map((evaluation, idx) => {
            return {
              id: evaluation.id,
              evaluation: evaluation.evaluation,
              state: evaluation.state,
              createdBy: evaluation.createdby,
            } as Evaluation;
          }),
        },
      });
      console.log(' getSubmissionEvaluations AFTER dispatching result, w submissionRubricId/evaluations ');
    } catch (err) {
      logErrorResponse(err, 'getSubmissionEvaluations error: ');
    }
  };
export const createSubmissionEvaluation =
  (evaluation: Evaluation, submissionRubricId: number, token: string) =>
  async (dispatch: (action: SubmissionRubricEvaluationsActionTypes) => void) => {
    try {
      const res = await figaroApi.post(
        `${submissionEvaluationsURL}`,
        { ...evaluation, submissionrubrics: submissionRubricId },
        withAuthorizationToken(authOptions, token)
      );
      console.log('createSubmissionEvaluation request: ', evaluation, ' submissionRubricId', submissionRubricId, ', response: ', res.data);
      const response = res.data as SubmissionRubricEvaluationResponse;
      dispatch({
        type: SUBMISSION_RUBRIC_EVALUATION_ADDED,
        payload: {
          videoId: response.submission.id,
          submissionRubricId: submissionRubricId,
          evaluation: {
            id: response.id,
            evaluation: response.evaluation,
            state: response.state,
            createdBy: response.createdby,
          },
        },
      });
      successNotification('SubmissionEvaluation  created!');
    } catch (err) {
      logErrorResponse(err, 'createSubmissionEvaluation failed ');
    }
  };
export const updateSubmissionEvaluation =
  (evaluation: Evaluation, submissionRubricId: number, token: string) =>
  async (dispatch: (action: SubmissionRubricEvaluationsActionTypes) => void) => {
    try {
      const updated = { state: evaluation.state, evaluation: evaluation.evaluation };
      const res = await figaroApi.put(
        `${submissionEvaluationsURL}/${submissionRubricId}`,
        updated,
        withAuthorizationToken(authOptions, token)
      );
      console.log('updateSubmissionEvaluation request: ', evaluation, submissionRubricId, ', response: ', res.data);
      const response = res.data as SubmissionRubricEvaluationResponse;
      dispatch({
        type: SUBMISSION_RUBRIC_EVALUATION_UPDATED,
        payload: {
          videoId: response.submission.id,
          submissionRubricId,
          evaluation: {
            id: response.id,
            evaluation: response.evaluation,
            state: response.state,
            createdBy: response.createdby,
          },
        },
      });
      successNotification('SubmissionEvaluation  updated!');
    } catch (err) {
      logErrorResponse(err, 'updateSubmissionEvaluation failed ');
    }
  };
export const deleteSubmissionEvaluation =
  (evaluationId: number, submissionRubricId: number, token: string) =>
  async (dispatch: (action: SubmissionRubricEvaluationsActionTypes) => void) => {
    try {
      const res = await figaroApi.delete(`${submissionEvaluationsURL}/${evaluationId}`, withAuthorizationToken(authOptions, token));
      console.log('deleteSubmissionEvaluation request: ', evaluationId, submissionRubricId, ', response: ', res.data);
      const response = res.data as SubmissionRubricEvaluationResponse;
      let videoId = response.submission.id;
      dispatch({
        type: SUBMISSION_RUBRIC_EVALUATION_DELETED,
        payload: { evaluationId, submissionRubricId, videoId, evaluationResponse: response.evaluation },
      });
      warningNotification('Submission Evaluation  deleted!');
    } catch (err) {
      logErrorResponse(err, 'deleteSubmissionEvaluation failed ');
    }
  };

// End SubmissionEvaluations

//Shares
export const shareSubmission =
  (submissionId: number, email: string, token: string) => async (dispatch: (action: SubmissionShareActionTypes) => void) => {
    try {
      const shareReq = {
        submission: submissionId,
        email,
      };
      const res = await figaroApi.post(`${submissionSharesURL}`, shareReq, withAuthorizationToken(authOptions, token));
      console.log('shareSubmission request: ', shareReq, ', response: ', res.data);
      const response = res.data as SubmissionShareResponse;
      dispatch({
        type: SUBMISSION_SHARE_ADDED,
        payload: {
          videoId: submissionId,
          shareId: response.id,
          share: makeSubmissionShare(response, submissionId),
          updatedSubmissionInfo: response.submission,//FFS study refresh submission info
        },
      });
      successNotification(`Succesfully shared to ${email}!`);
      return Promise.resolve(response.id);
    } catch (err) {
      logErrorResponse(err, `Sharing to ${email} failed!`);
      return Promise.reject();
    }
  };
export const deleteSubmissionShare = (shareId: number, token: string) => async (dispatch: (action: SubmissionShareActionTypes) => void) => {
  try {
    const res = await figaroApi.delete(`${submissionSharesURL}/${shareId}`, withAuthorizationToken(authOptions, token));
    console.log('deleteSubmissionShare request: ', shareId, ', response: ', res.data);
    const response = res.data as SubmissionShareResponse;
    dispatch({
      type: SUBMISSION_SHARE_DELETED,
      payload: { videoId: response.submission.id, shareId: shareId, updatedSubmissionInfo: response.submission,//FFS study refresh submission info
      },
    });
    warningNotification('Share  deleted!');
    // return Promise.resolve(response.id);
  } catch (err) {
    logErrorResponse(err, 'DeleteSubmissionShare failed ');
    // return Promise.reject();
  }
};
//End shares
//Folders
export const getFolder = (folderId: number, token: string) => async (dispatch: (action: FoldersActionTypes) => void) => {
  try {
    console.log(' getFolder starting');
    const response = await figaroApi.get(`${foldersURL}/${folderId}`, withAuthorizationToken(authOptions, token));
    const folders: Rubric[] = response.data;
    dispatch({
      type: FOLDER_UPDATED,
      payload: { folderId: folderId, folder: folders[0] },
    });
  } catch (err) {
    logErrorResponse(err, 'getFolder error: ');
  }
};

export const getFolders = (token: string) => async (dispatch: (action: FoldersActionTypes) => void) => {
  try {
    console.log(' getFolders starting');
    const response = await figaroApi.get(`${foldersURL}?_limit=1000000&_sort=name`, withAuthorizationToken(authOptions, token));
    const folders: Folder[] = response.data;
    console.log('getFolders  response: ', folders);
    dispatch({
      type: OWN_FOLDERS,
      payload: { folders: folders },
    });
    // return Promise.resolve(folders);
  } catch (err) {
    logErrorResponse(err, 'getFolders error: ');
  }
};

export const deleteFolder = (folderId: number, token: string) => async (dispatch: (action: FoldersActionTypes) => void) => {
  try {
    const res = await figaroApi.delete(`${foldersURL}/${folderId}`, withAuthorizationToken(authOptions, token));
    console.log('deleteFolder request: ', folderId, ', response: ', res.data);
    dispatch({
      type: FOLDER_DELETED,
      payload: { folderId: folderId, folder: res.data as Folder },
    });
    warningNotification('Folder  deleted!');
  } catch (err) {
    logErrorResponse(err, 'deleteFolder failed ');
  }
};

export const createFolder = (folder: FolderBase, token: string) => async (dispatch: (action: FoldersActionTypes) => void) => {
  try {
    const res = await figaroApi.post(`${foldersURL}`, folder, withAuthorizationToken(authOptions, token));
    console.log('createFolder request: ', folder, ', response: ', res.data);
    const response = res.data as Folder;
    dispatch({
      type: FOLDER_ADDED,
      payload: { folderId: response.id, folder: response },
    });
    successNotification('Folder  created!');
    return Promise.resolve(response.id);
  } catch (err) {
    logErrorResponse(err, 'createFolder failed ');
  }
};

// End folders

// Folder/SubmissionTags
export const addSubmissionToFolder =
  (folderTag: FolderBase, submissionId: number, token: string) => async (dispatch: (action: FolderSubmissionActionTypes) => void) => {
    try {
      const res = await figaroApi.post(
        `${folderTagsURL}`,
        { name: folderTag.name, submission: submissionId },
        withAuthorizationToken(authOptions, token)
      );
      console.log('addSubmissionToFolder request: ', folderTag, submissionId, ', response: ', res.data);
      const response = res.data as FolderSubmission;
      dispatch({
        type: FOLDER_SUBMISSION_ADDED,
        payload: { folderSubmission: response },
      });
      successNotification(`Successfully added submission ${submissionId} to folder ${folderTag.name}!`);
    } catch (err) {
      logErrorResponse(err, `Failed adding submission ${submissionId} to folder ${folderTag.name}!`);
      return Promise.reject();
    }
  };
export const getAllFoldersSubmissions = (token: string) => async (dispatch: (action: FolderSubmissionActionTypes) => void) => {
  try {
    console.log(' getAllFoldersSubmissions starting');
    const response = await figaroApi.get(`${folderTagsURL}?`, withAuthorizationToken(authOptions, token));
    const foldersSubmissions: FolderSubmission[] = response.data;
    console.log('getAllFoldersSubmissions  response: ', foldersSubmissions);
    dispatch({
      type: ALLFOLDERS_SUBMISSIONS,
      payload: { foldersSubmissions: foldersSubmissions },
    });
  } catch (err) {
    logErrorResponse(err, 'getAllFoldersSubmissions error: ');
  }
};
// export const getSubmissionsOfFolder =  (
//   folderTag: FolderBase,
//   token: string
// ) => async (dispatch: (action: FolderSubmissionActionTypes) => void)  => {
//   try {
//     console.log(' getSubmissionsOfFolder starting');
//     const response = await figaroApi.get(`${folderTagsURL}?name=${folderTag}&_sort=id`, withAuthorizationToken(authOptions, token));
//     const folders: FolderSubmission[] = response.data;
//     console.log('getSubmissionsOfFolder  response: ', folders);
//     dispatch({
//       type: FOLDER_SUBMISSIONS,
//       payload: { folders: folders },
//     });
//   } catch (err) {
//     logErrorResponse(err, 'getSubmissionsOfFolder error: ');
//   }
// };
export const deleteFolderSubmission = (tagId: number, token: string) => async (dispatch: (action: FolderSubmissionActionTypes) => void) => {
  try {
    const res = await figaroApi.delete(`${folderTagsURL}/${tagId}`, withAuthorizationToken(authOptions, token));
    console.log('deleteFolderSubmission request: ', tagId, ', response: ', res.data);
    const folderSubmission: FolderSubmission = res.data;
    dispatch({
      type: FOLDER_SUBMISSION_DELETED,
      payload: { folderSubmission },
    });
    warningNotification(`Folder Submission ${tagId} deleted!`);
  } catch (err) {
    logErrorResponse(err, `deleteFolderSubmission ${tagId} failed `);
  }
};

// End Folder/SubmissionTags
// Users
export const registerUser = async (username: string, email: string, password: string) => {
  try {
    const req = { username, password, email };
    const res = await figaroApi.post(`${registerURL}`, req, { headers: { ...authOptions.headers } });
    console.log('registerUser request: ', req, ', response: ', res.data);
    successNotification('User registered!');
    return Promise.resolve(username);
  } catch (err) {
    logErrorResponse(err, 'registerUser failed ');
    return Promise.reject();
  }
};

export const confirmRegistration = async (confirmation: string) => {
  try {
    console.log(' confirmRegistration starting, ', confirmation);
    const response = await figaroApi.get(`${confirmEMailURL}?confirmation=${confirmation}`, { headers: { ...authOptions.headers } });
    let users = response.data;

    console.log('confirmRegistration  response: ', users);
    return Promise.resolve(users);
  } catch (err) {
    const axiosErr = err as AxiosError;
    if (axiosErr.response) {
      return Promise.resolve(axiosErr.message);
      /*TODO REMOVE WHEN IT"S FIXED ON THE SERVER 
      A: As long as you hit that endpoint, it will work. I am aware that it returns an error
      */
    } else {
      logErrorResponse(err, 'confirmRegistration error ');
      return Promise.reject(err);
    }
  }
};

export const forgotPassword = async (email: string) => {
  try {
    console.log(' forgotPassword starting, ', email);
    const req = { email };
    const response = await figaroApi.post(`${forgotPasswordURL}`, req, { headers: { ...authOptions.headers } });
    let forgotten = response.data;

    console.log('forgotPassword  response: ', forgotten);
    return Promise.resolve(forgotten);
  } catch (err) {
    logErrorResponse(err, 'forgotPassword error ');
    return Promise.reject(err);
  }
};
export const reRequestConfirmation = async (email: string) => {
  try {
    console.log(' requestConfirmation starting, ', email);
    const req = { email };
    const response = await figaroApi.post(`${resendConfirmationEMailURL}`, req, { headers: { ...authOptions.headers } });
    let confirmation = response.data;

    console.log('requestConfirmation  response: ', confirmation);
    return Promise.resolve(confirmation);
  } catch (err) {
    logErrorResponse(err, 'requestConfirmation error ');
    return Promise.reject(err);
  }
};
export const resetPassword = async (code: string, password: string) => {
  try {
    console.log(' resetPassword starting, ', code, password);
    const req = { code, password, passwordConfirmation: password };
    const response = await figaroApi.post(`${resetPasswordURL}`, req, { headers: { ...authOptions.headers } });
    let reset = response.data;

    console.log('resetPassword  response: ', reset);
    return Promise.resolve(reset);
  } catch (err) {
    logErrorResponse(err, 'resetPassword error ');
    return Promise.reject(err);
  }
};

export const getUsers = async (addressPart: string, token: string) => {
  try {
    let url = `${usersURL}?`;
    if (addressPart && addressPart.length > 0) {
      url += `email_contains=${addressPart}`;
    }
    console.log(' getUsers starting, ', addressPart);
    const response = await figaroApi.get(`${url}&_sort=username:asc`, withAuthorizationToken(authOptions, token));
    let users: IUser[] = response.data;

    console.log('getUsers  response: ', users);
    return Promise.resolve(users);
  } catch (err) {
    logErrorResponse(err, 'getUsers error ');
  }
};

export const updateSubmissionDetails =
  (submissionId: number, values: IEditSubmissionState, token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
    try {
      const updateSubmissionReq = makeUpdateSubmissionDetailsRequest(values);
      const res = await figaroApi.put(`${submissionsURL}/${submissionId}`, updateSubmissionReq, withAuthorizationToken(authOptions, token));
      // TODO FFS: this supposes bakend will return a Submission, not a SubmissionUploadInfo, even for requested submissions
      const submissionResponse: Submission = res.data;
      console.log('updateSubmissionDetails request: ', updateSubmissionReq, ', response: ', res.data);
      dispatch({
        type: SUBMISSION_UPDATED_DETAILS,
        payload: {
          submission: submissionResponse,
        },
      });
      successNotification('Submission details updated!');
    } catch (err) {
      logErrorResponse(err, 'Updating SubmissionDetails failed ');
    }
  };

const makeUpdateSubmissionDetailsRequest = (values: IEditSubmissionState) => {
  const submissionU = {
    ...values,
  };
  return submissionU;
};

export const deleteSubmission = (submissionId, token: string) => async (dispatch: (action: SubmissionsActionTypes) => void) => {
  try {
    const res = await figaroApi.delete(`${submissionsURL}/${submissionId}`, withAuthorizationToken(authOptions, token));
    const submissionResponse: Submission = res.data;
    console.log('deleteSubmission request for: ', submissionId, ', response: ', res.data);
    dispatch({
      type: SUBMISSION_DELETED,
      payload: {
        submission: submissionResponse,
      },
    });
    warningNotification(`Submission ${submissionResponse.state === SubmissionState.deleted ? ' marked as deleted!' : ' deleted'} !`);
  } catch (err) {
    logErrorResponse(err, 'DeleteSubmission failed ');
  }
};

// export const createUser =
//   (username: string, email: string, password: string, token: string) => async (dispatch: (action: any /*UsersActionTypes*/) => void) => {
//     try {
//       const req = { username, password, email };
//       const res = await figaroApi.post(`${registerURL}`, req, withAuthorizationToken(authOptions, token));
//       console.log('registerUser request: ', req, ', response: ', res.data);
//       // const response = res.data;
//       // dispatch({
//       //   type: USER_ADDED,
//       //   payload: { rubricId: response.id, rubric: response },
//       // });
//       successNotification('User registered!');
//       // return Promise.resolve(response.id);
//     } catch (err) {
//       logErrorResponse(err, 'registerUser failed ');
//       // return Promise.reject();
//     }
//   };

// End Users

/*
createStream = ()=> async (dispatch, getState) => {
 ***const { userId } = getState().auth;
    const response = await ....
    dispatch(....);
*/
