import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import '../tabs/Tab.css';
import { TabPanel } from '../tabs/TabPanel';
import Legend from './Legend';

interface IProps {
  initialSelected: number;

  seriesColors: any;
  toggleFaceDrawer: any;
  toggleVoiceDrawer: any;
  containerHeight: any;
  handleChangeLegendTab: (newValue: number) => void;
  isComplexityOnly: boolean;
  speakers: string[];
  speakersFriendlyNames: string[];
  speakersHiddenStatus: boolean[];
}

class LegendTabs extends React.PureComponent<IProps, any> {
  // UNCOMMENT THESE FOR CHECKING RENDERING PERF issues
  //class LegendTabs extends React.Component<IProps, any> {
  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   console.log('LegendTabs shouldComponentUpdate', ' props/state/ctxt: ', this.props, this.state, this.context);
  //   console.log('LegebdTabs shouldComponentUpdate, ', 'NEXT props/state/ctxt: ', nextProps, nextState, nextContext);
  //   if (nextProps !== this.props) {
  //     console.log('props differs?');
  //     if (nextProps.containerHeight !== this.props.containerHeight) {
  //       console.log('Height differss');
  //       return true;
  //     } else if (nextProps.initialSelected !== this.props.initialSelected) {
  //       console.log('initialSel differss');
  //       return true;
  //     } else if (nextProps.isComplexityOnly !== this.props.isComplexityOnly) {
  //       console.log('isComplexityOnly differss');
  //       return true;
  //     } else if (nextProps.seriesColors !== this.props.seriesColors) {
  //       console.log('seriesColors differss');
  //       return true;
  //     } else if (nextProps.speakers !== this.props.speakers) {
  //       console.log('speakers differss');
  //       return true;
  //     }
  //   }
  //   if (nextState !== this.state) {
  //     console.log('state differs');
  //     return true;
  //   }
  //   return false;
  // }
  private handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
    this.props.handleChangeLegendTab(newValue);
  }

  render() {
    console.log(' render LegendTabs ', this.props);
    return (
      <div className="fig-direction-column w-100">
        <div
          className="row"
          style={{
            height: 35, //TODO ADI CHECKU
          }}
        >
          <div className="col no-padding ">
            <Tabs
              className="fig-video-details-left-side__chartTabs" //TODO ADI CHECKU
              style={{
                height: 35, //TODO ADI CHECKU
              }}
              value={this.props.initialSelected}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => this.handleChangeTab(event, newValue)}
              variant={'scrollable'}
              scrollButtons={'on'}
            >
              {!this.props.isComplexityOnly
                ? this.props.speakers.map((speaker, ispeaker) => (
                    <Tab
                      key={speaker}
                      label={`${this.props.speakersHiddenStatus[ispeaker] ? '(' : ''}Face Analysis ${
                        this.props.speakersFriendlyNames[ispeaker]
                      }${this.props.speakersHiddenStatus[ispeaker] ? ')' : ''}`}
                      style={this.props.speakersHiddenStatus[ispeaker] ? { color: 'gray', textDecoration: 'line-through' } : {}}
                    />
                  ))
                : null}
              {!this.props.isComplexityOnly ? <Tab key={'Voice Analysis'} label={'Voice  Analysis'} /> : null}
              <Tab label={'Legend'} key={'Legend'} />
            </Tabs>
          </div>
        </div>
        {/* //TODO ADI CHECKU */}
        <div className="row h-100">
          <div className="col no-padding ">
            {!this.props.isComplexityOnly
              ? this.props.speakers.map((speaker, index) => (
                  <TabPanel className="h-100" key={speaker} index={index} value={this.props.initialSelected}>
                    <Legend
                      isFaceAnalysis={true}
                      seriesColors={this.props.seriesColors}
                      toggleDrawer={this.props.toggleFaceDrawer}
                      containerHeight={this.props.containerHeight}
                      isPseudo={false}
                      isComplexityOnly={false}
                      speaker={speaker}
                      speakerFriendlyName={this.props.speakersFriendlyNames[index]}
                      speakerHiddenStatus={this.props.speakersHiddenStatus[index]}
                    />
                  </TabPanel>
                ))
              : null}
            {!this.props.isComplexityOnly ? (
              <TabPanel className="h-100" index={this.props.speakers.length} value={this.props.initialSelected} key={'Voice Analysis'}>
                <Legend
                  isFaceAnalysis={false}
                  seriesColors={this.props.seriesColors}
                  toggleDrawer={this.props.toggleVoiceDrawer}
                  containerHeight={this.props.containerHeight}
                  isPseudo={false}
                  isComplexityOnly={false}
                />
              </TabPanel>
            ) : null}
            <TabPanel
              index={this.props.isComplexityOnly ? 0 : this.props.speakers.length + 1}
              value={this.props.initialSelected}
              key={'Legend'}
            >
              <Legend
                isFaceAnalysis={false}
                seriesColors={this.props.seriesColors}
                toggleDrawer={null}
                containerHeight={this.props.containerHeight}
                isPseudo={true}
                isComplexityOnly={this.props.isComplexityOnly}
              />
            </TabPanel>
          </div>
        </div>
      </div>
    );
  }
}
export default LegendTabs;
