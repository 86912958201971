/* eslint-disable no-console */
import { TextField } from '@material-ui/core';
import React, { FormEvent } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import config from '../../config/constants';
import { Datum3, getUtteranceTextAtMoment } from '../../model/Video';
import { ISelectableItem } from '../shared/BorderedSelector';
import { SelectionList } from '../shared/SelectionList';

interface IProps extends StateProps, DispatchProps {
  handleFormSubmit: (form: IAddHighlightState) => void;
}

export interface IAddHighlightState {
  privacyOptions: ISelectableItem[];
  highlight: string;
  highlightRequired: boolean;
  highlightStartTime: number;
  highlightEndTime: number;
  name: string;
}

class AddHighlight extends React.PureComponent<IProps, IAddHighlightState> {
  constructor(props: IProps) {
    super(props);
    this.state = this.initState();
  }

  handlePrivacyChange = (privacyOptions: ISelectableItem[]) => {
    this.setState({ privacyOptions });
  };

  handleHighlightNameChange = (event) => {
    let res: any = { name: event.target.value, highlightRequired: true };
    if (!this.state.highlightRequired) {
      res = {
        ...res,
        highlightEndTime: Math.min(this.trunc2(this.props.currentTime + 20), this.props.videoDuration),
        highlightStartTime: this.trunc2(this.props.currentTime),
      };
    }
    this.setState(res);
  };
  handleHighlightChange = (event) => {
    let res: any = { highlight: event.target.value, highlightRequired: true };
    if (!this.state.highlightRequired) {
      res = {
        ...res,
        highlightEndTime: Math.min(this.trunc2(this.props.currentTime + 20), this.props.videoDuration),
        highlightStartTime: this.trunc2(this.props.currentTime),
      };
    }
    this.setState(res);
  };
  handleStartTimeChange = (event) => {
    let res: any = {
      highlightStartTime: event.target.value !== '' ? event.target.value : 0,
      highlightRequired: true,
    };
    if (!this.state.highlightRequired) {
      res = {
        ...res,
        highlightEndTime: Math.min(this.trunc2(this.props.currentTime + 20), this.props.videoDuration),
      };
    }
    this.setState(res);
  };
  handleEndTimeChange = (event) => {
    let res: any = {
      highlightEndTime: event.target.value !== '' ? Math.min(event.target.value, this.props.videoDuration) : 0,
      highlightRequired: true,
    };
    if (!this.state.highlightRequired) {
      res = { ...res, highlightStartTime: this.trunc2(this.props.currentTime) };
    }
    this.setState(res);
  };

  trunc2 = (a: number) => {
    return Math.trunc(a * 100) / 100;
  };

  onFormSubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // console.log('onFormSubmit', this.state);
    if (
      this.state.highlightStartTime === null ||
      this.state.highlightEndTime === null ||
      isNaN(this.state.highlightStartTime) ||
      isNaN(this.state.highlightEndTime) ||
      // this.state.highlight.length === 0 || //TODO FFS
      this.state.name.length === 0
    ) {
      this.setState({ highlightRequired: true });
      return;
    }
    const res = { ...this.state };
    if (Number.parseInt(res.highlightStartTime as unknown as string, 10) > Number.parseInt(res.highlightEndTime as unknown as string, 10)) {
      res.highlightStartTime = this.state.highlightEndTime;
      res.highlightEndTime = this.state.highlightStartTime;
    }
    res.highlight =
      makeHlText(res.highlightStartTime, this.props.transcript) + '...' + makeHlText(res.highlightEndTime, this.props.transcript);
    this.props.handleFormSubmit(res);
    this.setState(this.initState());
  };

  private initState() {
    const rez = {
      //allow adding further HLs with the same privacy
      privacyOptions: this.state && this.state.privacyOptions ? this.state.privacyOptions : [...config.highlights.defaultPrivacyItems],
      highlight: '',
      highlightRequired: false,
      highlightStartTime: this.props.currentTime,
      highlightEndTime: Math.min(this.props.currentTime + 20, this.props.videoDuration),
      name: '',
    };
    return rez;
  }
  render() {
    return (
      <form className="row fig-vertical-middle" onSubmit={this.onFormSubmit} onKeyPress={(e) => this.onEnterKey(e)}>
        <TextField
          className="col-12 fig-add-highlight-input"
          id="name"
          label={`COMMENT @ ${this.props.currentTime}`}
          InputLabelProps={{
            shrink: true,
            required: false,
          }}
          placeholder={'Enter Comment'}
          value={this.state.name}
          onChange={this.handleHighlightNameChange}
          error={(!this.state.name || this.state.name.length === 0) && this.state.highlightRequired}
        />
        <div className="fig-select-status">
          {/* <PrivacyButtons privacy={this.state.privacy} handlePrivacyChange={this.handlePrivacyChange} /> */}
          <SelectionList listItems={this.state.privacyOptions} selectionChanged={this.handlePrivacyChange} />
        </div>
      </form>
    );
  }
  onEnterKey(e: any) {
    if (e.which === 13) {
      this.onFormSubmit(e);
    }
  }
}
const makeHlText = (time: number, transcript: Datum3[]) => {
  if (transcript && transcript.length > 0) {
    return getUtteranceTextAtMoment(transcript, time);
  } else return '';
};

const emptyTranscript: Datum3[] = [];
const selectorTranscript: (IRootState) => Datum3[] = (state: IRootState) => {
  //console.log('addHL: SELECTOR transcript called with params ', state);
  return state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].transcript &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance.data
    ? state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance.data
    : emptyTranscript;
};

const mapStateToProps = (state: IRootState) => {
  return {
    currentTime: state.video.currentTime,
    transcript: selectorTranscript(state), // needed for creating default HL text
    // fullText: retrieveTranscriptFullText(state), // needed for creating default HL text
    // fullTextIndices: retrieveTranscriptTextIndices(state), // needed for creating default HL text
    videoDuration: state.video.videoDuration,
  };
};

const mapDispatchToProps = {};
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddHighlight);
