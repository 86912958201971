import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import Comments from '../../components/comment/Comments';
import Highlights from '../../components/highlight/Highlights';
import { TabPanel } from '../../components/tabs/TabPanel';
import Transcript from '../../components/transcript/Transcript';
import { IVideoPlayer } from '../../components/video/VideoRenderer';
import { ENV_VALUE_ENABLED } from '../../config/constants';
import Charts from '../echarts/Charts';
import UnifiedGraph from '../echarts/UGraph';
import Matrix from '../matrix/Matrix';
import Rubric4Video from '../rubric4video/Rubric4Video';
import Report from './Report';
import './Tab.css';
import VideoInfo from './VideoInfo';

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
  initialSelected: number;
  graphicsSide: boolean;

  handleChangeTab: any;
  reporting: boolean; //show reporting tabs
}

class TabsComponent extends React.PureComponent<IProps, any> {
  private handleChangeTab(event: React.ChangeEvent<{}>, newValue: number) {
    // this.setState({ selectedUpperTab: newValue });
    // if (
    //   this.props.graphicsSide &&
    //   (newValue === TabsComponent.TAB_IDX_TAGS - 1 || newValue === TabsComponent.TAB_IDX_TAGS - 2) &&
    //   !this.props.reporting
    // ) {
    //   return;
    // } else this.props.handleChangeTab(event, newValue);
    this.props.handleChangeTab(event, newValue);
  }

  chartsHandle = null; // FFS POC hidden charts capture

  public static TAB_IDX_ANALYSIS = 0;
  public static TAB_IDX_TAGS = 5;
  public static TAB_IDX_REPORT = 4;

  public static TAB_IDX_HLS = 2;
  render() {
    console.log(' render TabsComponent ', this.props, this.props.graphicsSide ? 'graphics Side' : 'text side');
    return (
      <React.Fragment>
        <div className="row">
          <div className="col no-padding">
            <Tabs
              className="fig-video-details-left-side__chartTabs"
              value={this.props.initialSelected}
              textColor="primary"
              indicatorColor="primary"
              onChange={(event, newValue) => this.handleChangeTab(event, newValue)}
              variant={'scrollable'}
              scrollButtons={'on'}
              //scrollButtons="auto"

              //variant={"fullWidth"}
              //centered
            >
              {this.props.graphicsSide ? <Tab label={'Analysis'} /> : <Tab label={'Transcript'} />}
              <Tab label={this.props.graphicsSide ? 'Complexity' : 'Comments'} />
              <Tab label={this.props.graphicsSide ? 'Charts' : 'Highlights'} />
              <Tab label={this.props.graphicsSide ? 'Evaluation Results' : 'Rubric'} />
              {this.props.graphicsSide ? <Tab label={'Report'} className={this.props.reporting ? '' : 'hide'} /> : null}
              {this.props.graphicsSide && process.env.REACT_APP_FACE_TAGGING && process.env.REACT_APP_FACE_TAGGING === ENV_VALUE_ENABLED ? (
                <Tab label={'Face Tagging'} />
              ) : null}
            </Tabs>
          </div>
        </div>

        <div className="row">
          {this.props.graphicsSide ? (
            <div className="col no-padding">
              <TabPanel index={0} value={this.props.initialSelected}>
                {<UnifiedGraph theVideoPlayer={this.props.theVideoPlayer} isComplexityOnly={false} />}
              </TabPanel>
              <TabPanel index={1} value={this.props.initialSelected}>
                {<UnifiedGraph theVideoPlayer={this.props.theVideoPlayer} isComplexityOnly={true} />}
              </TabPanel>
              <TabPanel index={2} value={this.props.initialSelected}>
                <Charts />
              </TabPanel>
              <TabPanel index={3} value={this.props.initialSelected}>
                <Matrix />
              </TabPanel>
              <TabPanel
                index={TabsComponent.TAB_IDX_REPORT}
                value={this.props.initialSelected}
                className={this.props.reporting ? '' : 'hide'}
              >
                <Report theVideoPlayer={this.props.theVideoPlayer} />
              </TabPanel>
              {process.env.REACT_APP_FACE_TAGGING && process.env.REACT_APP_FACE_TAGGING === ENV_VALUE_ENABLED ? (
                <TabPanel index={TabsComponent.TAB_IDX_TAGS} value={this.props.initialSelected}>
                  <VideoInfo theVideoPlayer={this.props.theVideoPlayer} />
                </TabPanel>
              ) : null}

              {/* <TabPanel index={4} value={this.props.initialSelected} /*className="graph-transcript-tab"*>
                {<Graph theVideoPlayer={this.props.theVideoPlayer} faceGraph={true} />}
              </TabPanel>
              <TabPanel index={5} value={this.props.initialSelected} /*className="graph-transcript-tab"*>
                {<Graph theVideoPlayer={this.props.theVideoPlayer} faceGraph={false} />}
              </TabPanel> */}
              {/* <TabPanel index={3} value={selectedUpperTab}>
                <VideoInfo theVideoPlayer={this.props.theVideoPlayer} />
              </TabPanel> */}
            </div>
          ) : (
            <div className="col no-padding">
              <TabPanel index={0} value={this.props.initialSelected}>
                <Transcript theVideoPlayer={this.props.theVideoPlayer} />
              </TabPanel>
              <TabPanel index={1} value={this.props.initialSelected}>
                <Comments theVideoPlayer={this.props.theVideoPlayer} />
              </TabPanel>
              <TabPanel index={2} value={this.props.initialSelected}>
                <Highlights theVideoPlayer={this.props.theVideoPlayer} />
              </TabPanel>
              <TabPanel index={3} value={this.props.initialSelected}>
                <Rubric4Video />
              </TabPanel>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    appSelectedHighlight: state.video.currentHighlight,
  };
};

const mapDispatchToProps = {};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(TabsComponent);
