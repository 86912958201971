import { AnalysisItemStatus } from '../../model/ApiTypes';
import {
  Datum,
  Datum2,
  FaceAnalysis,
  FAKE_EMOTION_COMPLEXITY,
  FAKE_EMOTION_FLUIDITY,
  StreamRef,
  Urlid2FaceAnalysisMetaMap,
  VoiceAnalysis,
} from '../../model/Video';

//TODO ffs relation to Graph lengendId2StreamRef
export function streamRef2LegendId(streamRef: StreamRef) {
  return streamRef.analysisTag !== '' ? `${streamRef.stream}.${streamRef.analysisTag}` : `${streamRef.stream}`;
}
export function streamRef2FriendlyName(streamRef: StreamRef) {
  return streamRef ? (streamRef.analysisTag !== '' ? `${streamRef.stream}.${streamRef.tagFriendlyName}` : `${streamRef.stream}`) : '';
}

export function isFluidityStream(streamRef: StreamRef) {
  return streamRef.stream === FAKE_EMOTION_FLUIDITY;
}
export function isComplexityStream(streamRef: StreamRef) {
  return streamRef.stream === FAKE_EMOTION_COMPLEXITY;
}

export type FaceVoiceData = {
  valuesArray: number[];
  id: string;
  streamRef: StreamRef;
  hidden: boolean;
};

// get the array of values plus the ID of each face stream; the position in the array corresponds to the second of the video
function getFaceData(analysis: FaceAnalysis, analysisIdx: number, metaFaceAnalysesMap: Urlid2FaceAnalysisMetaMap): FaceVoiceData[] {
  return Object.keys(analysis.streams).map((streamName, index) => {
    const datum: Datum[] = analysis.streams[streamName].data;
    const valuesArray: number[] = datum.map((datumItem: Datum) => datumItem.conf);
    let streamRef: StreamRef = {
      analysisIdx: analysisIdx,
      stream: streamName,
      analysisTag: analysis.tag ? analysis.tag : '',
      tagFriendlyName:
        analysis.tag && metaFaceAnalysesMap && metaFaceAnalysesMap[analysis.urlid] ? metaFaceAnalysesMap[analysis.urlid].name : '',
    };
    // return { valuesArray, id: `${streamName}.${analysis.tag}`, streamRef };
    return {
      valuesArray,
      id: streamRef2LegendId(streamRef),
      streamRef,
      hidden: metaFaceAnalysesMap[analysis.urlid].status === AnalysisItemStatus.hidden,
    };
  });
}

const getAllFaceAnalysesData = (faceAnalyses: FaceAnalysis[], metaFaceAnalysesMap) => {
  console.log('getAllFaceAnalysesData called with SELECTED params ', faceAnalyses, metaFaceAnalysesMap);
  const allFacesAllStreams: FaceVoiceData[] = [];
  if (faceAnalyses) {
    faceAnalyses.forEach((analysis, index) => {
      const newStreams = getFaceData(analysis, index, metaFaceAnalysesMap);
      allFacesAllStreams.push(...newStreams);
    });
  }
  // console.log(' getAllFaceAnalysesData ');
  return allFacesAllStreams;
};

function getVoiceData(analysis: VoiceAnalysis, analysisIdx: number): FaceVoiceData[] {
  return Object.keys(analysis.streams).map((streamName, index) => {
    const datum: Datum2[] = analysis.streams[streamName].data;
    const valuesArray = datum.map((datumItem) => datumItem.value);

    let streamRef: StreamRef = {
      analysisIdx: analysisIdx,
      stream: streamName,
      analysisTag: analysis.tag ? analysis.tag : '',
      tagFriendlyName: '',
    };

    // return { valuesArray, id: `${streamName}` };
    return { valuesArray, id: streamRef2LegendId(streamRef), streamRef, hidden: false };
  });
}

const getAllVoiceAnalysesData = (voiceAnalyses: VoiceAnalysis[]) => {
  console.log('getAllVoiceAnalysesData called with SELECTED params ', voiceAnalyses);

  const allVoicesAllStreams: FaceVoiceData[] = [];
  if (voiceAnalyses) {
    voiceAnalyses.forEach((analysis, index) => {
      const newStreams = getVoiceData(analysis, index);
      allVoicesAllStreams.push(...newStreams);
    });
  }
  return allVoicesAllStreams;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAllVoiceAnalysesData,
  getAllFaceAnalysesData,
};
