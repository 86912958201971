import { Evaluation } from '../actions/figaroApiActions/types';
import { CONFIG } from '../config/constants';
import { EvaluationResponse, FaceAnalysisItem, Score, ShareBase, SubmissionRubric } from './ApiTypes';

export const VOICE_STREAMS_COUNT = 19;
export const FACE_STREAMS_COUNT = 11;

export interface Urlid2FaceAnalysisMetaMap {
  [urlid: string]: FaceAnalysisItem;
}

export const toStringId = (id: number) => '' + id;
export interface Video {
  id?: string;
  faceAnalyses?: FaceAnalysis[];
  voiceAnalyses?: VoiceAnalysis[];
  transcript?: Transcript;
  sentiment?: SentimentAnalysis;
  creatorId: string;
  dateCreated: string;
  videoRequestId: string;
  url: string;
  comments?: Comments;
  metaFaceAnalysesMap: Urlid2FaceAnalysisMetaMap;
  highlights?: Highlights;
  threshold?: number;
  tag2FaceAnalysisIdx: Tag2FaceAnalysisIdx;
  tag2FriendlyName: Tag2FriendlyName;
  submissionRubricsMap: SubmissionRubricsMap;
  submissionSharesMap: SubmissionSharesMap;
  /*  
  hiddenPortions
  statisticAnalysis
 */
}
export interface Tag2FaceAnalysisIdx {
  [tag: string]: number;
}
export interface Tag2FriendlyName {
  [tag: string]: string;
}

export const buildTag2FaceAnalysisIdx = (faceAnalyses: FaceAnalysis[]) => {
  let rez = {} as Tag2FaceAnalysisIdx;
  if (faceAnalyses) {
    faceAnalyses.forEach((faceAnaysis, idx) => {
      rez[faceAnaysis.tag] = idx;
    });
  }
  return rez;
};
export const buildTag2FriendlyName = (faceAnalyses: FaceAnalysis[], urlid2FaceAnalysisMetaMap: Urlid2FaceAnalysisMetaMap) => {
  let rez = {} as Tag2FriendlyName;
  if (faceAnalyses) {
    faceAnalyses.forEach((faceAnaysis, idx) => {
      rez[faceAnaysis.tag] = urlid2FaceAnalysisMetaMap[faceAnaysis.urlid].name;
    });
  }
  return rez;
};

export interface Transcript {
  type: string;
  version: string;
  created: string;
  name: string;
  tag?: any;
  streams: TranscriptStreams;
  language?: string;
  fullText?: string;
  fullTextIndices?: { start: number; end: number }[];
  gapsCount?: number; //how many fake gap utterances were added
}

export const getUtteranceTextAtMoment = (utterances: Datum3[], time: number) => {
  console.log('looking for utterance at time ', time);
  if (utterances && utterances.length > 0 && utterances[utterances.length - 1].end >= time) {
    const found = utterances.find((utterance) => utterance.start <= time && utterance.end >= time);
    console.log('found utterance ', found);
    return found ? found.text : '';
  } else return '';
};
export const getTextOfUtterances = (utterances: Datum3[], timeStart: number, timeEnd: number) => {
  console.log('looking for utterances at timeframe ', timeStart, timeEnd);
  let startIdx, endIdx;
  let res = '';
  if (utterances && utterances.length > 0 && utterances[utterances.length - 1].end >= timeStart) {
    startIdx = utterances.findIndex((utterance) => utterance.start <= timeStart && utterance.end >= timeStart);
    // console.log('found start utterance idx', startIdx);
  } else return '';
  if (utterances && utterances.length > 0 && utterances[utterances.length - 1].end >= timeEnd) {
    endIdx = utterances.findIndex((utterance) => utterance.start <= timeEnd && utterance.end >= timeEnd);
    // console.log('found end utterance idx ', endIdx);
  } else return '';
  if (startIdx !== -1 && endIdx !== -1) {
    for (let i = startIdx; i <= endIdx; i++) {
      res += utterances[i].text + ' ';
    }
    return res;
  } else return '';
};

export interface TranscriptStreams {
  utterance?: TranscriptStream;
}

export interface TranscriptStream {
  name: string;
  index: string;
  data: Datum3[];
}

export interface Datum3 {
  text: string;
  start: number;
  end: number;
}

export interface Meta3 {
  text: string;
  req: boolean;
}

export interface VoiceAnalysis {
  type: string;
  version: string;
  created: string;
  name: string;
  tag?: any;
  streams: VoiceStreams;
}

export interface VoiceStreams {
  [streamName: string]: VoiceStream;
}

export interface VoiceStream {
  name: string;
  index: string;
  meta: Meta2;
  data: Datum2[];
}

export interface Datum2 {
  value: number;
  time?: number;
}

export interface Meta2 {
  value: string;
  req: boolean;
}

export interface FaceAnalysis {
  urlid?: string;
  type: string;
  version: string;
  created: string;
  name: string;
  tag: string;
  streams: FaceStreams;
  dominantEmotion?: string[][]; //the list of the dominant emotions, at each second
  dominantEmotionsRate: EmotionsRate; //percentage of dominance of each emotion, normalized
  reducedDominantEmotionsRate: EmotionsRate; //percentage of dominance of reduced set of emotions  ( Disgust and Contempt are excluded)

  summedEmotionsRate: EmotionsRate; //percentage of each emotion from the total emotions of during the video, normalized
  reducedSummedEmotionsRate: EmotionsRate; //percentage of reduced set of emotions  ( Disgust and Contempt are excluded)  from the total emotions of during the video
}

export interface FaceStreams {
  [streamName: string]: FaceStream;
}

export interface FaceStream {
  name: string;
  index: string;
  meta: Meta;
  data: Datum[];
}

export interface Datum {
  conf: number;
  time?: number;
}

export interface Meta {
  conf: string;
  req: boolean;
}

//percents
export interface Coords {
  x1: number;
  y1: number;
  x2: number;
  y2: number;
}
const NEAR_BORDER_LIMIT = 5;
export const insideCoords = (x: number, y: number, coords: Coords) => {
  return (
    x < coords.x2 - NEAR_BORDER_LIMIT &&
    x > coords.x1 + NEAR_BORDER_LIMIT &&
    y > coords.y1 + NEAR_BORDER_LIMIT &&
    y < coords.y2 - NEAR_BORDER_LIMIT
  );
};
export const onLeftBorder = (x: number, y: number, coords: Coords) => {
  return Math.abs(x - coords.x1) < NEAR_BORDER_LIMIT && y >= coords.y1 + NEAR_BORDER_LIMIT && y <= coords.y2 - NEAR_BORDER_LIMIT;
};
export const onRightBorder = (x: number, y: number, coords: Coords) => {
  return Math.abs(-x + coords.x2) < NEAR_BORDER_LIMIT && y >= coords.y1 && y <= coords.y2;
};
export const onTopBorder = (x: number, y: number, coords: Coords) => {
  return Math.abs(y - coords.y1) < NEAR_BORDER_LIMIT && x <= coords.x2 && x >= coords.x1;
};
export const onBottomBorder = (x: number, y: number, coords: Coords) => {
  return Math.abs(-y + coords.y2) < NEAR_BORDER_LIMIT && x <= coords.x2 && x >= coords.x1;
};

export const pan = (draftTagCoords: Coords, movementCoords: Coords, limitBottom) => {
  //console.log('limit bottom = ', limitBottom);
  let rez = { ...draftTagCoords };
  rez.x1 = rez.x1 + (movementCoords.x2 - movementCoords.x1);
  rez.x2 = rez.x2 + (movementCoords.x2 - movementCoords.x1);

  rez.y1 = rez.y1 + (movementCoords.y2 - movementCoords.y1);
  rez.y2 = rez.y2 + (movementCoords.y2 - movementCoords.y1);

  if (rez.x1 < 0) rez.x1 = 0;
  if (rez.x1 > 100) rez.x1 = 100;
  if (rez.x2 < 0) rez.x2 = 0;
  if (rez.x2 > 100) rez.x2 = 100;
  if (rez.y1 < 0) rez.y1 = 0;
  if (rez.y1 > 100) rez.y1 = 100;
  if (rez.y2 < 0) rez.y2 = 0;
  if (rez.y2 > 100 - limitBottom) rez.y2 = 100 - limitBottom;
  return rez;
};

export const resizeRight = (draftTagCoords: Coords, movementCoords: Coords) => {
  let rez = { ...draftTagCoords };
  rez.x2 = rez.x2 + (movementCoords.x2 - movementCoords.x1);

  if (rez.x2 < 0) rez.x2 = 0;
  if (rez.x2 > 100) rez.x2 = 100;
  return rez;
};
export const resizeLeft = (draftTagCoords: Coords, movementCoords: Coords) => {
  let rez = { ...draftTagCoords };
  rez.x1 = rez.x1 + (movementCoords.x2 - movementCoords.x1);
  if (rez.x1 < 0) rez.x1 = 0;
  if (rez.x1 > 100) rez.x1 = 100;
  return rez;
};

export const resizeTop = (draftTagCoords: Coords, movementCoords: Coords) => {
  let rez = { ...draftTagCoords };
  rez.y1 = rez.y1 + (movementCoords.y2 - movementCoords.y1);

  if (rez.y1 < 0) rez.y1 = 0;
  if (rez.y1 > 100) rez.y1 = 100;
  return rez;
};

export const resizeBottom = (draftTagCoords: Coords, movementCoords: Coords) => {
  let rez = { ...draftTagCoords };
  rez.y2 = rez.y2 + (movementCoords.y2 - movementCoords.y1);

  if (rez.y2 < 0) rez.y2 = 0;
  if (rez.y2 > 100) rez.y2 = 100;
  return rez;
};

export interface SentimentAnalysis {
  type: string;
  version: string;
  created: string;
  name: string;
  data: EmotionsRate;
}
export interface EmotionsRate {
  [emotion: string]: number;
}
export const hasEmotions = (emotionsRate: EmotionsRate) => {
  if (emotionsRate[VERBAL_EMOTION_NONE] && emotionsRate[VERBAL_EMOTION_NONE] === 1) {
    return false;
  } else {
    return true;
  }
};

export interface StreamRef {
  analysisIdx?: number;
  tagFriendlyName: string;
  stream: string;
  analysisTag: string;
}

export interface StreamsState {
  voiceThreshold: number;
  faceThreshold: number;
  selectedStreamsVoice: StreamRef[];
  selectedStreamsFace: StreamRef[];
}

export interface Highlights {
  highlights: Highlight[];
}
export interface Highlight extends TimeWindow, StreamsState {
  id: string;
  text: string;
  privacy: string;
  userID: string;
  name: string;
  created_at?: Date;
  updated_at?: Date;
}
export interface Comments {
  comments: Comment[];
}

export interface Comment {
  id: string;
  time: number;
  text: string;
  privacy: string;
  userID: string;
  annotation?: Annotation;
  created_at?: Date;
  updated_at?: Date;
}
/**
 *
 * @param video
 * @param second
 * @returns null if no comments for the current second, array with the comments otherwise
 */
export function getComments(video: Video, second: number) {
  let commentsArray: Comment[] = null;
  let rez: Comment[] = null;
  if (video.comments) {
    commentsArray = video.comments.comments;
  }
  if (commentsArray) {
    rez = commentsArray.filter((comment) => comment.time >= second && comment.time < second + 1);
    if (rez.length === 0) {
      rez = null;
    }
  }
  return rez;
}
/**
 * @param video
 * @param time
 * @returns null if the given video has no comments  in the given time interval, or array with the comments otherwise
 */
export function getCommentsForTimeWindow(comments: Comments, time: TimeWindow) {
  let commentsArray: Comment[] = null;
  let rez: Comment[] = null;
  if (comments) {
    commentsArray = comments.comments;
  }
  if (commentsArray) {
    rez = commentsArray.filter((comment) => comment.time >= time.startTime && comment.time <= time.endTime);
    if (rez.length === 0) {
      rez = null;
    }
  }
  return rez;
}

/**
 * gets the id-th stream from the idxAnalisys-th voice analisys, zero based, if it exists
 * @param video
 * @param id
 */
export function getVoiceAnalysisData(idxAnalisys: number, video: Video, id: number) {
  if (video.voiceAnalyses && video.voiceAnalyses.length > idxAnalisys) {
    const newLocal = Object.keys(video.voiceAnalyses[idxAnalisys].streams);
    if (newLocal.length >= id + 1) {
      return video.voiceAnalyses[idxAnalisys].streams[newLocal[id]].data as Datum2[];
    }
  }
  return null;
}

/**
 * gets the id-th stream from the idxAnalisys-th face analisys, zero based, if it exists
 * @param video
 * @param id
 */
export function getFaceAnalysisData(idxAnalisys: number, video: Video, id: number) {
  if (video.faceAnalyses && video.faceAnalyses.length > idxAnalisys) {
    const newLocal = Object.keys(video.faceAnalyses[idxAnalisys].streams);
    if (newLocal.length >= id + 1) {
      return video.faceAnalyses[idxAnalisys].streams[newLocal[id]].data.map((entry: Datum) => ({
        value: entry.conf,
        time: entry.time,
      })) as Datum2[];
    }
  }
  return null;
}

/**
 * Check if the given relative moment belongs to a highlight interval of the given video
 * @param seconds the moment
 * @param video the video
 */
export function isHighlighted(video: Video, seconds: number) {
  const containsThatTime = (element: Highlight) => element.startTime <= seconds && element.endTime >= seconds;
  return video.highlights && video.highlights.highlights && video.highlights.highlights.some(containsThatTime);
}

/**
 * Check if the given time span overlaps partially/totally with a highlight interval of the given video
 * @param slot the time span
 * @param video the video
 */
export function isTimeWindowHighlighted(highlights: Highlight[], slot: TimeWindow) {
  const overlapsThatSlot = (highlight: Highlight) =>
    (highlight.startTime <= slot.startTime && highlight.endTime > slot.startTime) ||
    (highlight.startTime < slot.endTime && highlight.endTime > slot.endTime) ||
    (highlight.startTime >= slot.startTime && highlight.endTime < slot.endTime);
  return highlights && highlights.some(overlapsThatSlot);
}

export interface Annotation {
  id: string;
  x: number;
  y: number;
}
export interface TimeWindow {
  startTime: number;
  endTime: number;
}

export interface UterranceProps {
  highlighted: boolean;
  exceedsThreshold?: boolean;
  isntGap: boolean;
  commentsArray: Comment[];
  data: Datum3;
  ofSelectedHighlight: boolean;
  found?: boolean;
  foundAndSeeked?: boolean;
}

const computeUteranceProps = (
  highlights: Highlights,
  comments: Comments,
  data: Datum3,
  config: CONFIG,
  appSelectedHighlight: Highlight
): UterranceProps => {
  const timeSlot: TimeWindow = { startTime: data.start, endTime: data.end };
  const hls: Highlight[] = highlights && highlights.highlights ? highlights.highlights : [];
  const highlighted = isTimeWindowHighlighted(hls, timeSlot);
  // const exceedsThreshold = config.trigger.slotExceedsConfiguredThreshold4BackgroundStyling(checkedData, timeSlot);
  const isntGap = data.text !== config.transcript.gapFakeText;

  const commentsArray: Comment[] = getCommentsForTimeWindow(comments, timeSlot);
  let ofSelectedHighlight = false;
  if (appSelectedHighlight) {
    if (isTimeWindowHighlighted([appSelectedHighlight], timeSlot)) {
      ofSelectedHighlight = true;
    }
  }
  return { highlighted, /* exceedsThreshold,*/ isntGap, commentsArray, data, ofSelectedHighlight };
};
export const computeUtterancesProps = (
  transcript: Transcript,
  highlights: Highlights,
  comments: Comments,
  config: CONFIG,
  appSelectedHighlight: Highlight
) => {
  console.log('SELECTED computeUtterancesProps called, with params', transcript, highlights, comments, config, appSelectedHighlight);
  let rez: UterranceProps[] = [];
  if (transcript && transcript.streams && transcript.streams.utterance && transcript.streams.utterance.data) {
    rez = transcript.streams.utterance.data.map((data) => computeUteranceProps(highlights, comments, data, config, appSelectedHighlight));
  }
  console.log('computeUtterancesProps retruning ', rez);
  return rez;
};

export const computeFullText = (video: Video): void => {
  if (
    video &&
    video.transcript &&
    video.transcript.streams &&
    video.transcript.streams.utterance &&
    video.transcript.streams.utterance.data
  ) {
    let res = [],
      fullText = '';

    video.transcript.streams.utterance.data.forEach((entry) => {
      let spaceAdded = false;
      fullText += entry.text;
      // if (entry.text[entry.text.length - 1] !== ' ') {// tod ASK attempt to correct old format transcript
      fullText += ' ';
      spaceAdded = true;
      // }
      res.push({
        start: spaceAdded ? fullText.length - entry.text.length - 1 : fullText.length - entry.text.length,
        end: fullText.length - 1,
      });
    });

    video.transcript.fullText = fullText;
    video.transcript.fullTextIndices = res;
    // console.log('computedFullText ', video.transcript.fullText, video.transcript.fullTextIndices);
  }
};

const isThresholdExceeded = (
  faceAnalyses: FaceAnalysis[],
  voiceAnalyses: VoiceAnalysis[],
  utteranceData: Datum3,
  selectedVoiceStreams: StreamRef[],
  voiceThreshold: number,
  selectedFaceStreams: StreamRef[],
  faceThreshold: number
): boolean => {
  const timeSlot: TimeWindow = { startTime: utteranceData.start, endTime: utteranceData.end };

  if (voiceAnalyses && selectedVoiceStreams && voiceThreshold) {
    for (let i = 0; i < selectedVoiceStreams.length; i++) {
      let analysisIdx = !isNaN(selectedVoiceStreams[i].analysisIdx) ? selectedVoiceStreams[i].analysisIdx : 0;
      let streamData =
        voiceAnalyses[analysisIdx].streams[selectedVoiceStreams[i].stream] &&
        voiceAnalyses[analysisIdx].streams[selectedVoiceStreams[i].stream].data
          ? voiceAnalyses[analysisIdx].streams[selectedVoiceStreams[i].stream].data
          : [];
      if (slotExceedsConfiguredVoiceThreshold4BackgroundStyling(streamData, timeSlot, voiceThreshold)) {
        return true;
      }
    }
  }
  if (faceAnalyses && selectedFaceStreams && faceThreshold) {
    for (let i = 0; i < selectedFaceStreams.length; i++) {
      //todo ffs  check is there still are use cases which needed this
      // let analysisIdx = !isNaN(selectedFaceStreams[i].analysisIdx)
      //   ? selectedFaceStreams[i].analysisIdx
      //   : video.tag2FaceAnalysisIdx[selectedFaceStreams[i].analysisTag];
      let analysisIdx = selectedFaceStreams[i].analysisIdx;
      let streamData =
        faceAnalyses[analysisIdx].streams[selectedFaceStreams[i].stream] &&
        faceAnalyses[analysisIdx].streams[selectedFaceStreams[i].stream].data
          ? faceAnalyses[analysisIdx].streams[selectedFaceStreams[i].stream].data
          : null;
      if (slotExceedsConfiguredFaceThreshold4BackgroundStyling(streamData, timeSlot, faceThreshold)) {
        return true;
      }
    }
  }
  return false;
};

const slotExceedsConfiguredVoiceThreshold4BackgroundStyling = (streamData: Datum2[], slot: TimeWindow, threshold: number) => {
  const secondStart = Math.floor(slot.startTime),
    secondEnd = Math.floor(slot.endTime);
  for (let i = secondStart; i <= secondEnd; i++) {
    if (streamData && streamData.length > i && streamData[i].value >= threshold) {
      // console.log('slotExceedsConfiguredThreshold4BackgroundStyling retruns true for slot ', slot, ', data ', streamData[i].value, 'i ', i);
      return true;
    }
  }
  return false;
};
const slotExceedsConfiguredFaceThreshold4BackgroundStyling = (streamData: Datum[], slot: TimeWindow, threshold: number) => {
  const secondStart = Math.floor(slot.startTime),
    secondEnd = Math.floor(slot.endTime);
  for (let i = secondStart; i <= secondEnd; i++) {
    if (streamData && streamData.length > i && streamData[i].conf >= threshold) {
      // console.log('slotExceedsConfiguredThreshold4BackgroundStyling retruns true for slot ', slot, ', data ', streamData[i].value, 'i ', i);
      return true;
    }
  }
  return false;
};

export const computeThresholdExceededProp = (
  selectedVoiceStreams: StreamRef[],
  voiceThreshold: number,
  selectedFaceStreams: StreamRef[],
  faceThreshold: number,
  transcript: Transcript,
  faceAnalyses: FaceAnalysis[],
  voiceAnalyses: VoiceAnalysis[]
) => {
  console.log(
    'SELECTED computeThresholdExceededProps called, with params',
    selectedVoiceStreams,
    voiceThreshold,
    selectedFaceStreams,
    faceThreshold,
    transcript,
    faceAnalyses,
    voiceAnalyses
  );
  let rez: boolean[] = [];
  if (
    // ( ( voiceThreshold || faceThreshold )&& TODO FFS
    transcript &&
    transcript.streams &&
    transcript.streams.utterance &&
    transcript.streams.utterance.data
  ) {
    rez = transcript.streams.utterance.data.map((data) =>
      isThresholdExceeded(faceAnalyses, voiceAnalyses, data, selectedVoiceStreams, voiceThreshold, selectedFaceStreams, faceThreshold)
    );
  }
  console.log('computeThresholdExceededProp returning ', rez);
  //if (rez.length === 0) console.log('computeThresholdExceededProp NULL empty rez ', rez);
  return rez;
};

export interface EvaluationsMap {
  [id: number]: Evaluation;
}
export interface SubmissionRubricsMap {
  [submissionRubricId: number]: { rubricInfo: SubmissionRubric; evaluationsMap: EvaluationsMap };
}
export interface SubmissionSharesMap {
  [shareId: number]: ShareBase;
}
export const emptyEvaluationsMap = {} as EvaluationsMap;
export const makeEvaluationsMap = (evaluations: Evaluation[]) => {
  // console.log(' makeEvaluationsMap called with param: ', evaluations);
  let rez = emptyEvaluationsMap;
  if (evaluations) {
    rez = {};
    evaluations.forEach((evaluation) => {
      rez[evaluation.id] = evaluation;
    });
  }
  return rez;
};

export const computeOwnEvaluations = (submissionRubricsMap: SubmissionRubricsMap, user: number) => {
  console.log('computeOwnEvaluations array: submissionRubricsMap/user ', submissionRubricsMap, user);
  let rez = Object.keys(submissionRubricsMap).map((submissionRubricId) => {
    return {
      rubricInfo: submissionRubricsMap[submissionRubricId].rubricInfo,
      evaluation: getEvaluationOfUser(submissionRubricsMap[submissionRubricId].evaluationsMap, user),
    };
  });
  console.log('computeOwnEvaluations returns: ', rez);
  return rez;
};

export const getEvaluationOfUser = (evaluations: EvaluationsMap, userId: number) => {
  if (!evaluations) return null;
  let ids = Object.keys(evaluations).filter((evaluationId) => evaluations[evaluationId].createdBy.id === userId);
  return ids.length > 0 ? evaluations[ids[0]] : null;
};

export const computeEvaluationsByUsers = (submissionRubricsMap: SubmissionRubricsMap) => {
  console.log('computeEvaluationsByUsers array: submissionRubricsMap/user ', submissionRubricsMap);
  let rez = Object.keys(submissionRubricsMap).map((submissionRubricId) => {
    let evaluations = getEvaluationsByUsers(submissionRubricsMap[submissionRubricId].evaluationsMap);
    let categoryAveragesMap = getCategoriesAveragesMap(evaluations);
    return {
      rubricInfo: submissionRubricsMap[submissionRubricId].rubricInfo,
      evaluations,
      categoryAveragesMap,
    };
  });
  console.log('computeEvaluationsByUsers returns: ', rez);
  return rez;
};

const getCategoriesAveragesMap = (evaluations: EvaluationsMap) => {
  let rezult = {};
  Object.keys(evaluations).forEach((userId) => {
    let rez = { categories: [] } as EvaluationResponse;
    let evaluation: EvaluationResponse = evaluations[userId].evaluation;
    if (evaluation) {
      evaluation.categories.forEach((category: Score, idx) => {
        rez.categories.push({ response: [getCategoryAverage(category.response)] });
      });
    } else {
    }
    rezult[userId] = rez;
  });
  return rezult;
};

export const getCategoryAverage = (response: number[]) => {
  if (!response) return 0;
  let sum = 0;
  let count = 0;
  for (let i = 0; i < response.length; i++) {
    // if (response[i]) {
    count++;
    sum += response[i];
    // }
  }
  //return count ? sum / count : 0;
  return count ? Math.trunc((sum / count) * 100) / 100 : 0;
};

// map:  userId : Evaluation
export const getEvaluationsByUsers = (evaluations: EvaluationsMap) => {
  let rez = emptyEvaluationsMap;
  if (evaluations) {
    rez = {};
    Object.keys(evaluations).forEach((evaluationID) => {
      let evaluation: Evaluation = evaluations[evaluationID];
      rez[evaluation.createdBy.id] = evaluation;
    });
  }
  return rez;
};

export const VERBAL_EMOTION_NONE = 'Other';
export const FACE_ONLY_EMOTIONS = ['Disgust', 'Contempt'];
export const BOTH_FACE_AND_VERBAL_EMOTIONS = ['Joy', 'Fear', 'Sadness', 'Surprise', 'Anger'];
export const ALL_EMOTIONS = BOTH_FACE_AND_VERBAL_EMOTIONS.concat(FACE_ONLY_EMOTIONS); //TODO FFS  settings datagroups ?

export const FAKE_EMOTION_COMPLEXITY = 'Complexity';
export const FAKE_EMOTION_FLUIDITY = 'Fluidity';
export const CATEGORY_EMOTIONS = 'Emotions';
export const CATEGORY_ANALYSIS = 'Analysis';
export const STREAM_NORMALIZED_MAX_VALUE = 100;
// Crtly Complexity includes 7 emotions, so max theoretical value is 700, but in practice it's much smaller
export const COMPLEXITY_MAX_VALUE = ALL_EMOTIONS.length * STREAM_NORMALIZED_MAX_VALUE;

export const addFakeEmotionsInfo = (faceAnalyses: FaceAnalysis[]) => {
  faceAnalyses && faceAnalyses.forEach((analysis) => addFakeEmotions(analysis));
};

const addFakeEmotions = (faceAnalysis: FaceAnalysis) => {
  addComplexity(faceAnalysis);
  addFluidity(faceAnalysis);
  addSummedEmotionsRate(faceAnalysis);
};

const addComplexity = (faceAnalysis: FaceAnalysis) => {
  let finalData: Datum[] = [];
  Object.keys(faceAnalysis.streams).forEach((streamName, index) => {
    if (ALL_EMOTIONS.includes(streamName)) {
      const datum: Datum[] = faceAnalysis.streams[streamName].data;
      datum.forEach((dataATsecond, second) => {
        if (second < finalData.length) {
          finalData[second].conf += dataATsecond.conf;
        } else {
          finalData.push({ conf: dataATsecond.conf });
        }
      });
    }
  });
  faceAnalysis.streams[FAKE_EMOTION_COMPLEXITY] = {
    name: FAKE_EMOTION_COMPLEXITY,
    index: 'sec',
    meta: { conf: 'Confidence Value', req: true },
    data: finalData,
  };
};
const addFluidity = (faceAnalysis: FaceAnalysis) => {
  let finalData: Datum[] = [];
  let dominantEmotion: string[][] = [];
  Object.keys(faceAnalysis.streams).forEach((streamName, index) => {
    if (ALL_EMOTIONS.includes(streamName)) {
      const datum: Datum[] = faceAnalysis.streams[streamName].data;
      datum.forEach((dataATsecond, second) => {
        if (second < finalData.length) {
          if (finalData[second].conf < dataATsecond.conf) {
            finalData[second].conf = dataATsecond.conf;
            dominantEmotion[second] = [streamName];
          } else if (finalData[second].conf === dataATsecond.conf) {
            dominantEmotion[second].push(streamName);
          }
        } else {
          finalData.push({ conf: dataATsecond.conf });
          dominantEmotion.push([streamName]);
        }
      });
    }
  });
  faceAnalysis.streams[FAKE_EMOTION_FLUIDITY] = {
    name: FAKE_EMOTION_FLUIDITY,
    index: 'sec',
    meta: { conf: 'Confidence Value', req: true },
    data: finalData,
  };
  faceAnalysis.dominantEmotion = dominantEmotion;
};

export const addEmotionsRates = (faceAnalyses: FaceAnalysis[]) => {
  faceAnalyses && faceAnalyses.forEach((analysis) => addSummedEmotionsRate(analysis));
};
const addSummedEmotionsRate = (faceAnalysis: FaceAnalysis) => {
  let totalEmotions = 0;
  let totalReducedEmotions = 0;
  let summedEmotionsMap: EmotionsRate = {};

  Object.keys(faceAnalysis.streams).forEach((streamName) => {
    if (ALL_EMOTIONS.includes(streamName)) {
      let total4ThisStream = 0;
      const datum: Datum[] = faceAnalysis.streams[streamName].data;
      datum.forEach((dataATsecond) => {
        total4ThisStream += dataATsecond.conf;
      });
      summedEmotionsMap[streamName] = total4ThisStream;
      totalEmotions += total4ThisStream;
      BOTH_FACE_AND_VERBAL_EMOTIONS.includes(streamName) && (totalReducedEmotions += total4ThisStream);
    }
  });

  let percentageSummedEmotionsMap: EmotionsRate = {};
  let percentageSummedReducedEmotionsMap: EmotionsRate = {};
  Object.keys(summedEmotionsMap).forEach((streamName) => {
    percentageSummedEmotionsMap[streamName] = summedEmotionsMap[streamName] / totalEmotions;
    BOTH_FACE_AND_VERBAL_EMOTIONS.includes(streamName) &&
      (percentageSummedReducedEmotionsMap[streamName] = summedEmotionsMap[streamName] / totalReducedEmotions);
  });
  faceAnalysis.summedEmotionsRate = percentageSummedEmotionsMap;
  faceAnalysis.reducedSummedEmotionsRate = percentageSummedReducedEmotionsMap;
};
/*
const addDominantEmotionsRate = (faceAnalysis: FaceAnalysis) => {
  let { dominantEmotion } = faceAnalysis;
  let fluidityData = faceAnalysis && faceAnalysis.streams[FAKE_EMOTION_FLUIDITY] ? faceAnalysis.streams[FAKE_EMOTION_FLUIDITY].data : [];
  let countNonEmtpy = 0;
  let emotionsMap: EmotionsRate = {},
    normalizedEmotionsMap = {},
    reducedNormalizedEmotionsMap = {};
  let dominants: string[] = null;
  ALL_EMOTIONS.forEach((emotion) => (emotionsMap[emotion] = 0));
  fluidityData.forEach((data, second) => {
    if (data.conf) {
      countNonEmtpy++;
      dominants = dominantEmotion[second];
      dominants.forEach((dominant) => (emotionsMap[dominant] += 1 / dominants.length));
    }
  });
  ALL_EMOTIONS.forEach((emotion) => (normalizedEmotionsMap[emotion] = emotionsMap[emotion] / countNonEmtpy));
  let exclusions = 0;
  FACE_ONLY_EMOTIONS.forEach((excludedEmotion) => (exclusions += emotionsMap[excludedEmotion]));

  BOTH_FACE_AND_VERBAL_EMOTIONS.forEach(
    (emotion) => (reducedNormalizedEmotionsMap[emotion] = emotionsMap[emotion] / (countNonEmtpy - exclusions))
  );
  faceAnalysis.dominantEmotionsRate = normalizedEmotionsMap;
  faceAnalysis.reducedDominantEmotionsRate = reducedNormalizedEmotionsMap;
};
*/
export const NO_LANGUAGE = null as string;
export const ARABIC_LANGUAGE = 'ar';

export const isRTLLanguage = (videoId, language, config: CONFIG) => {
  if (language) return language.startsWith(ARABIC_LANGUAGE) ? true : false;
  return config.transcript.rtlSubmissions && config.transcript.rtlSubmissions.includes(videoId);
};
