import React from 'react';
import { connect } from 'react-redux';
import { IRole } from '../../demo/model/ApiTypes';
import { formatFigaroDate } from '../../demo/utils/utils';
import { IRootState } from '../../shared/reducers';
import Header from './header/Header';

interface IProps {
  firstName: string;
  lastName: string;
  email: string;
  image: string;
  history: any;
  role: IRole;
  createdAt: string;
  updatedAt: string;
}

/*
interface IState {
  newPassword?: string;
  newPasswordRetry?: string;
  firstName: string;
  lastName: string;
  email: string;
  image: string;
}
*/
class Profile extends React.Component<IProps> {
  render() {
    let className = 'figaro-content';
    return (
      <div className="figaro-container">
        <Header selectedMenu="profile" history={this.props.history} />
        <div className={className}>{this.renderUserProfile()}</div>
      </div>
    );
  }

  private renderUserProfile() {
    return (
      <div className="row no-gutters">
        <div className="col-12">
          <div className="fig-upload-form-header">
            <span> User Profile</span>
          </div>
          <div className="col-12 fig-upload-form-content">
            <div className="row">
              <div className="col">
                <div className="fig-upload-form-text-row">
                  <span>Name:</span> <span>{this.props.firstName}</span>
                </div>
                <div className="fig-upload-form-text-row">
                  <span>E-mail:</span> <span>{this.props.email}</span>
                </div>
                <div className="fig-upload-form-text-row">
                  <span>Role:</span> <span>{this.props.role.name}</span>
                </div>
                <div className="fig-upload-form-text-row">
                  <span>Created At:</span>
                  <span> {formatFigaroDate(this.props.createdAt)}</span>
                </div>
                <div className="fig-upload-form-text-row">
                  <span>Updated At:</span> <span>{formatFigaroDate(this.props.updatedAt)}</span>
                </div>
              </div>
            </div>
            `
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IRootState) => {
  return {
    firstName: state.authentication.user.username,
    lastName: state.authentication.user.username,
    email: state.authentication.user.email,
    role: state.authentication.user.role,
    createdAt: state.authentication.user.created_at,
    updatedAt: state.authentication.user.updated_at,
    // image: state.authentication.user.image, TODO FFS
    image: null,
  };
};

export default connect(mapStateToProps, {})(Profile);
