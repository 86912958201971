/* eslint-disable no-console */
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React, { Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import { addHighlight, changeHighlightPrivacy, deleteHighlight, updateHighlight } from '../../actions/figaroApiActions';
import { toggleSelectHighlight4Reporting as toggleSelectHl4Report } from '../../actions/reportActions';
import { areTheSame } from '../../actions/selectionActions/types';
import { highlightSelected } from '../../actions/videoActions';
import { IVideoPlayer } from '../../components/video/VideoRenderer';
import config, { Date_Modified_Earliest, Date_Modified_Latest, Timeline_Earliest, Timeline_Latest } from '../../config/constants';
import { Highlight } from '../../model/Video';
import { generateFakeId } from '../../utils/utils';
import { ISelectableItem } from '../shared/BorderedSelector';
import BorderedSelectorTicks from '../shared/BorderedSelectorTicks';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import AddHighlight, { IAddHighlightState } from './AddHighlight';
import AHighlight from './AHighlight';
import { IEditHighlightState } from './EditHighlight';

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
}
interface IState {
  filterByOpened: boolean;
  filterByItems: ISelectableItem[];

  sortByItems: ISelectableItem[];
  sortByOpened: boolean;

  deleteHighlightDialogOpened: boolean;
  idHighlightToDelete: string;
}
class Highlights extends React.PureComponent<IProps, IState> {
  sortCriteriaListAnchor = null;
  filterCriteriaListAnchor = null;

  private static noInfoForScrolling = {
    mostRecentHighlightTime: -1,
    mostRecentHighlightIdx: -1,
    nextHighlightTime: 1000000,
    nextHighlightIdx: -1,
  };

  infoForScrolling = { ...Highlights.noInfoForScrolling };

  crtGridRef = null;

  constructor(props) {
    super(props);
    this.state = {
      filterByOpened: false,
      sortByOpened: false,
      filterByItems: config.highlights.highlightsFilterByItems,
      sortByItems: config.highlights.highlightsSortByItems,
      deleteHighlightDialogOpened: false,
      idHighlightToDelete: null,
    };
  }

  buildHighlightItemElementId = (nth) => {
    return `Highlights_${nth}`;
  };
  handleSetPrivacy = (highlightId: string, privacy: string) => {
    this.props.changeHighlightPrivacy(this.props.selectedVideoId, highlightId, privacy, this.props.currentToken);
  };

  handleDeleteHighlight = (highlightId: string) => {
    this.setState({ deleteHighlightDialogOpened: true, idHighlightToDelete: highlightId });
  };
  onDeleteHighlightConfirmed = () => {
    this.closeDeleteHighlightConfirmationDialog();
    this.props.deleteHighlight(this.props.selectedVideoId, this.state.idHighlightToDelete, this.props.currentToken);
  };
  closeDeleteHighlightConfirmationDialog = () => {
    this.setState({ deleteHighlightDialogOpened: false, idHighlightToDelete: null });
  };

  renderDeleteHighlightConfirmation() {
    return this.state.deleteHighlightDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteHighlightConfirmed}
        handleClose={this.closeDeleteHighlightConfirmationDialog}
        title={'Delete Highlight'}
        text={`Are you sure you want to delete the highlight?`}
      />
    ) : null;
  }

  handleEditHighlight = (highlightId: string, values: IEditHighlightState) => {
    const newHighlight: Highlight = {
      id: highlightId,
      startTime: Math.trunc(values.startTime * 100) / 100,
      endTime: Math.trunc(values.endTime * 100) / 100,
      text: values.description,
      privacy: values.privacyOptions.find((item) => item.selected).key,
      userID: this.props.currentUser.username,
      name: values.name,
      //TODO FFS DUBIOS ASK
      //this would use the streams selected globally, not the existing streams of this HL
      // voiceThreshold: this.props.voiceThreshold,
      // faceThreshold: this.props.faceThreshold,
      // selectedStreamsVoice: this.props.voiceSelections,
      // selectedStreamsFace: this.props.faceSelections,

      voiceThreshold: values.voiceThreshold,
      faceThreshold: values.faceThreshold,
      selectedStreamsVoice: values.selectedStreamsVoice,
      selectedStreamsFace: values.selectedStreamsFace,
    };
    this.props.updateHighlight(this.props.selectedVideoId, highlightId, newHighlight, this.props.currentToken);
  };
  onToggleHighlightSelected = (highlight: Highlight) => {
    this.props.highlightSelected(this.props.selectedVideoId, highlight);
  };
  onSelect4Reporting = (highlight: Highlight) => {
    this.props.toggleSelectHl4Report(highlight.id);
  };

  handleAddHighlight = (form: IAddHighlightState) => {
    const newHighlight: Highlight = {
      id: generateFakeId(),
      startTime: Math.trunc(form.highlightStartTime * 100) / 100,
      endTime: Math.trunc(form.highlightEndTime * 100) / 100,
      text: form.highlight,
      privacy: form.privacyOptions.find((item) => item.selected).key,
      userID: this.props.currentUser.username,
      name: form.name,
      voiceThreshold: this.props.voiceThreshold,
      faceThreshold: this.props.faceThreshold,
      selectedStreamsVoice: this.props.voiceSelections,
      selectedStreamsFace: this.props.faceSelections,
    };
    this.props.addHighlight(this.props.selectedVideoId, newHighlight, this.props.currentToken);
  };

  updateHighlightStreamsSelections = (highlight: Highlight) => {
    const newHighlight: Highlight = {
      ...highlight,
      voiceThreshold: this.props.voiceThreshold,
      faceThreshold: this.props.faceThreshold,
      selectedStreamsVoice: this.props.voiceSelections,
      selectedStreamsFace: this.props.faceSelections,
    };
    this.props.updateHighlight(this.props.selectedVideoId, highlight.id, newHighlight, this.props.currentToken);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.crtGridRef) return;
    let scrollIdx = -1;

    let causedBySelect = false;

    if (this.props.appSelectedHighlight && prevProps.appSelectedHighlight !== this.props.appSelectedHighlight) {
      scrollIdx =
        this.props.highlights && this.props.highlights.highlights
          ? this.props.highlights.highlights.indexOf(this.props.appSelectedHighlight)
          : -1;
      // console.log(
      //   'prevProps.appSelectedHighlight: ',
      //   prevProps.appSelectedHighlight,
      //   'this.props.appSelectedHighlight',
      //   this.props.appSelectedHighlight,
      //   ' scrollIdx ',
      //   scrollIdx
      // );
      causedBySelect = true;
    } else if (config.highlights.autoscroll && prevProps.currentTime !== this.props.currentTime) {
      console.log(
        'prevpropd.crtTime: ',
        prevProps.currentTime,
        'thisprops.crtTime ',
        this.props.currentTime,
        'this.infoForScrolling.mostRecentHighlightTime',
        this.infoForScrolling.mostRecentHighlightTime,
        'this.infoForScrolling.nextHighlightIdx',
        this.infoForScrolling.nextHighlightIdx
      );
      if (this.infoForScrolling.mostRecentHighlightTime >= 0) {
        scrollIdx = this.infoForScrolling.mostRecentHighlightIdx;
      } else if (this.infoForScrolling.nextHighlightIdx >= 0) {
        scrollIdx = this.infoForScrolling.nextHighlightIdx;
      }
    }
    if (scrollIdx >= 0) {
      let node: any = null;
      let nodeToScrollTo: Element = null;
      const selector = this.buildHighlightItemElementId(scrollIdx);
      // console.log(`should scroll, to show the ${scrollIdx}th item, to element id ${selector}`);
      node = this.crtGridRef.querySelector(`#${selector}`);
      if (node) {
        if (node.length > 1) {
          nodeToScrollTo = node[0];
        } else {
          nodeToScrollTo = node;
        }
        nodeToScrollTo.scrollIntoView({
          block: causedBySelect ? 'nearest' : 'start',
        });
      }
    }
  }
  componentDidMount() {
    this.forceUpdate(); // rerender popovers with known anchor elements
  }

  static NO_HIGHLIGHTS = (<div className="no-highlights-container">No Highlights</div>);
  render() {
    console.log('Player render ', this.props);
    this.infoForScrolling = { ...Highlights.noInfoForScrolling };
    if (!this.props.selectedVideoId) {
      return <div />;
    }
    let style = {} as React.CSSProperties;

    if (this.props.playerHeight) {
      style.height = this.computePanelHeight(this.props.playerHeight);
    }

    return (
      <>
        <Grid container className="fig-video-details-highlights-main-container">
          <Grid item xs={12} style={style}>
            <div className="highlights-filter-container">{this.renderFiltersHeader()}</div>
            {this.renderListOfHighlights()}
          </Grid>

          <Grid item xs={12} className="fig-add-highlight-container">
            <AddHighlight handleFormSubmit={this.handleAddHighlight} />
          </Grid>
        </Grid>
        {this.renderDeleteHighlightConfirmation()}
      </>
    );
  }

  toggleFilterBy = (key: string) => {
    let newFilters: ISelectableItem[] = [...this.state.filterByItems].map((item) => {
      return { selected: item.key === key ? !item.selected : item.selected, key: item.key, displayedValue: item.displayedValue };
    });
    this.setState({ filterByItems: newFilters });

    this.setState({ filterByItems: newFilters }, () => {
      config.highlights.highlightsFilterByItems = newFilters;
    });
  };
  toggleSortBy = (key: string) => {
    let existingSelected = this.state.sortByItems.find((item) => item.key === key).selected;
    if (!existingSelected) {
      let newCriteria: ISelectableItem[] = [...this.state.sortByItems].map((item) => {
        return { selected: item.key === key ? true : false, key: item.key, displayedValue: item.displayedValue };
      });
      this.setState({ sortByItems: newCriteria }, () => {
        config.highlights.highlightsSortByItems = newCriteria;
      });
    }
  };

  toggleSortCriteriaList = () => {
    const opened = this.state.sortByOpened;
    this.setState({ sortByOpened: !opened });
  };
  closeSortCriteriaList = () => {
    if (this.state.sortByOpened) this.setState({ sortByOpened: false });
  };

  toggleFilterCriteriaList = () => {
    const opened = this.state.filterByOpened;
    this.setState({ filterByOpened: !opened });
  };
  closeFilterCriteriaList = () => {
    if (this.state.filterByOpened) this.setState({ filterByOpened: false });
  };

  private renderFiltersHeader() {
    return (
      <div className="row">
        <FormControl
          ref={(e) => {
            this.sortCriteriaListAnchor = e;
          }}
        >
          <InputLabel id="sortby" shrink={false} required={false}>
            Sort By
          </InputLabel>
          <Select
            native={false}
            labelId="sortby"
            id="sortby"
            value={''}
            open={this.state.sortByOpened}
            onOpen={this.toggleSortCriteriaList}
            onClose={this.toggleSortCriteriaList}
            onClick={this.toggleSortCriteriaList}
            className="col fig-modal-dialog-input  fig-selection-list-pointer"
            MenuProps={{
              PaperProps: { elevation: 0, hidden: true },
            }}
            SelectDisplayProps={{ style: { background: 'transparent' } }} //TODO FFS ADI
          ></Select>
          <BorderedSelectorTicks
            anchorRef={this.state.sortByOpened ? this.sortCriteriaListAnchor : null}
            items={this.state.sortByItems}
            toggleSelection={this.toggleSortBy}
            width={200}
            height={140}
            onClose={this.toggleSortCriteriaList}
          ></BorderedSelectorTicks>
        </FormControl>

        <FormControl
          ref={(e) => {
            this.filterCriteriaListAnchor = e;
          }}
        >
          <InputLabel id="privacy-search-label" shrink={false} required={false}>
            Filter By
          </InputLabel>
          <Select
            native={false}
            labelId="privacy-search-label"
            id="privacy-search"
            value={''}
            open={this.state.filterByOpened}
            //onChange={(event) => this.handleChangeSearchPrivacy(event)}
            onOpen={this.toggleFilterCriteriaList}
            onClose={this.toggleFilterCriteriaList}
            onClick={this.toggleFilterCriteriaList}
            className="col fig-modal-dialog-input  fig-selection-list-pointer"
            MenuProps={{
              PaperProps: { elevation: 0, hidden: true },
            }}
            SelectDisplayProps={{ style: { background: 'transparent' } }} //TODO FFS ADI
          ></Select>
        </FormControl>
        <BorderedSelectorTicks
          anchorRef={this.state.filterByOpened ? this.filterCriteriaListAnchor : null}
          items={this.state.filterByItems}
          toggleSelection={this.toggleFilterBy}
          width={200}
          height={140}
          onClose={this.toggleFilterCriteriaList}
        ></BorderedSelectorTicks>
      </div>
    );
  }

  private getFilteredHighlights(): Highlight[] {
    const highlights = this.props.highlights;
    let selectedFilterByItems: string[] = this.state.filterByItems.filter((item) => item.selected).map((item) => item.key);
    const result: Highlight[] = [];
    highlights.highlights.forEach((highlight) => {
      if (selectedFilterByItems.includes(highlight.privacy)) {
        result.push(highlight);
      }
    });
    return result;
  }
  private sortHighlights(highlights: Highlight[]): Highlight[] {
    let selectedSortCriteria: string = this.state.sortByItems.find((item) => item.selected).key;
    if (selectedSortCriteria !== config.filters.DEFAULT_API_SORT_CRITERIA) {
      highlights.sort((h1, h2) => {
        switch (selectedSortCriteria) {
          case Date_Modified_Earliest:
            return h1.updated_at.getTime() - h2.updated_at.getTime();
          case Date_Modified_Latest:
            return -h1.updated_at.getTime() + h2.updated_at.getTime();
          case Timeline_Earliest:
            return h1.startTime - h2.startTime;
          case Timeline_Latest:
            return -h1.startTime + h2.startTime;
        }
        return 0;
      });
    }
    return highlights;
  }

  isSelectedAndHasBeenEdited(highlight: Highlight) {
    if (this.props.appSelectedHighlight && this.props.appSelectedHighlight.id === highlight.id) {
      if (
        this.props.faceThreshold !== highlight.faceThreshold ||
        this.props.voiceThreshold !== highlight.voiceThreshold ||
        //TODO FFS is CLONE selections  into redux state needed ?
        !areTheSame(this.props.faceSelections, highlight.selectedStreamsFace) ||
        !areTheSame(this.props.voiceSelections, highlight.selectedStreamsVoice)
      ) {
        return true;
      }
    } else return false;
  }
  renderListOfHighlights() {
    const highlights = this.sortHighlights(this.getFilteredHighlights());
    if (highlights && highlights.length > 0) {
      return (
        <Fragment>
          <Grid
            container
            className="comment"
            ref={(e) => {
              this.crtGridRef = e;
            }}
          >
            {highlights.map((highlight, idx) => {
              const ctime = highlight.startTime;
              if (ctime > this.infoForScrolling.mostRecentHighlightTime && ctime <= this.props.currentTime) {
                this.infoForScrolling.mostRecentHighlightTime = ctime;
                this.infoForScrolling.mostRecentHighlightIdx = idx;
              }
              if (ctime < this.infoForScrolling.nextHighlightTime && ctime >= this.props.currentTime) {
                this.infoForScrolling.nextHighlightTime = ctime;
                this.infoForScrolling.nextHighlightIdx = idx;
              }

              return (
                <Grid key={highlight.id} item xs={12} id={this.buildHighlightItemElementId(idx)}>
                  <AHighlight
                    highlight={highlight}
                    theVideoPlayer={this.props.theVideoPlayer}
                    handleDelete={this.handleDeleteHighlight}
                    handleEdit={this.handleEditHighlight}
                    handleSetPrivacy={this.handleSetPrivacy}
                    isPlaying={ctime >= Math.floor(this.props.currentTime) && ctime < Math.floor(this.props.currentTime) + 1}
                    isSelected={this.props.appSelectedHighlight && this.props.appSelectedHighlight.id === highlight.id}
                    hasBeenEdited={this.isSelectedAndHasBeenEdited(highlight)}
                    highlightSelected={this.onToggleHighlightSelected}
                    updateStreamsSelections={this.updateHighlightStreamsSelections}
                    getGlobalStreamsSelections={this.getGlobalStreamsSelections}
                    videoDuration={this.props.videoDuration}
                    toggleSelect4Reporting={this.onSelect4Reporting}
                    isSelected4Reporting={
                      this.props.hlsSelected4Reporting &&
                      this.props.hlsSelected4Reporting[highlight.id] !== null &&
                      this.props.hlsSelected4Reporting[highlight.id] !== undefined
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </Fragment>
      );
      // at this point,  this.infoForScrolling knows the idx for the most_recent/next grid item (highlight), which we should scroll to
    } else return Highlights.NO_HIGHLIGHTS;
  }
  //  height: calc(100vh - 337px - 30px - 41px - 72px - 104px);
  computePanelHeight = (videoHeight: number) => {
    //TODO FFS only if player is docked, otherwise consider videoHeight=0
    //console.log('compute comments  height, for video=', videoHeight);
    const rez = window.innerHeight - videoHeight - 30 - 41 - 10 - 94 - 30 - 15;
    //TODO FFS document.documentElement.clientHeight
    console.log('compute HLSTab height for videoH=', videoHeight, ' rez = ', rez, 'window: ', window.innerHeight);
    return rez > 0 ? rez : 0;
  };

  getGlobalStreamsSelections = () => {
    const { voiceSelections, faceSelections, voiceThreshold, faceThreshold } = this.props;
    return { voiceSelections, faceSelections, voiceThreshold, faceThreshold };
  };
}

const mapStateToProps = (state: IRootState) => {
  return {
    selectedVideoId: state.video.currentVideoId,
    highlights: state.video.currentVideoId ? state.video.videosMap[state.video.currentVideoId].highlights : null,
    currentTime: Math.floor(state.video.currentTime),
    videoDuration: state.video.videoDuration,
    currentUser: state.authentication.user,
    currentToken: state.authentication.token,
    voiceThreshold: state.selections.voiceThreshold,
    faceThreshold: state.selections.faceThreshold,
    voiceSelections: state.selections.selectedStreamsVoice,
    faceSelections: state.selections.selectedStreamsFace,
    appSelectedHighlight: state.video.currentHighlight,
    playerHeight: state.utils.playerHeight,
    windowHeight: state.utils.windowHeight,
    hlsSelected4Reporting: state.report.highlights,
  };
};

const mapDispatchToProps = {
  addHighlight,
  deleteHighlight,
  changeHighlightPrivacy,
  highlightSelected,
  updateHighlight,
  toggleSelectHl4Report,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Highlights);
