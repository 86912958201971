/* eslint-disable no-console */
import ReactEcharts from 'echarts-for-react';
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { createSelector } from 'reselect';
import sprites from '../../../../icons/fig-icons.svg';
import { legendHeightUpdated } from '../../../actions/utils';
import { IRootState } from '../../../shared/reducers';
import { addHighlight, deleteHighlight, updateHighlight } from '../../actions/figaroApiActions';
import {
  addImage,
  selectedFaceStreams,
  selectedVoiceStreams,
  setFaceAnalysisDrawerVisibility,
  setFaceThreshold,
  setSelectedLegendTab,
  setVoiceAnalysisDrawerVisibility,
  setVoiceThreshold
} from '../../actions/selectionActions';
import { highlightSelected } from '../../actions/videoActions';
import config, { getColor } from '../../config/constants';
import { events, TIME_WINDOW_SELECTED } from '../../event/CrossEvents';
import { AnalysisItemStatus } from '../../model/ApiTypes';
import {
  COMPLEXITY_MAX_VALUE,
  Datum3,
  FAKE_EMOTION_FLUIDITY,
  getUtteranceTextAtMoment,
  Highlight,
  StreamRef,
  STREAM_NORMALIZED_MAX_VALUE
} from '../../model/Video';
import { DURATION_UNKNOWN, TIME_INITIAL } from '../../reducers/videoReducer';
import { applyOpacity, generateFakeId, seconds2mss } from '../../utils/utils';
import { IVideoPlayer } from '../video/VideoRenderer';
import CategoryLegend from './CategoryLegend';
import LegendTabs from './LegendTabs';
import MenuAddHighlight from './MenuAddHighlight';
import MenuEditHighlight from './MenuEditHighlight';
import MenuFinishEditingHighlight from './MenuFinishEditingHighlight';
import graphUtils, { FaceVoiceData, isComplexityStream, isFluidityStream, streamRef2FriendlyName, streamRef2LegendId } from './utils';

// https://echarts.apache.org/next/examples/en/editor.html?c=dataset-link

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
  isComplexityOnly: boolean;
  // FFS POC hidden charts capture
  setChartsHandle?: any;
}
interface IArea {
  startTime: number;
  endTime: number;
  pxStart: number;
  pxEnd: number;
}

interface IResizeState {
  isResizing: boolean;
  // sidesSeparatorPos: number;
  //on Firefox no coordinates clientX, clientY... are available at onDrag events, but they are present on omDragOver
  //workaround: keep trak of coordinates via onDragOver of the parent container
  mouseX: number;
  mouseY: number;
}
interface IState extends IResizeState {
  areasToBeAdded: IArea[];
  clickSelectedHighlight: {
    selectedHighlightIdx: number;
    offsetX: number;
    offsetY: number;
  };
  highlightEditing: {
    selectedHighlightIdx: number;
    offsetX: number;
    offsetY: number;
  };
  zoomOrBrushMode: boolean;
}

export interface EChartOption {
  dataZoom?: any[];
  graphic?: any;
  toolbox?: any;
  brush?: any;
  legend?: any;
  tooltip?: any;
  dataset: any;
  xAxis?: any;
  yAxis?: any;
  grid?: any;
  series: any;
  title?: any;
  animation?: boolean;
}

function formatXAxisValue(currentTime) {
  return seconds2mss(currentTime);
}

function sizeOfSecondsArray(videoDuration: number) {
  return 2 * Math.ceil(videoDuration);
}
const makeSecondsArray: (number) => string[] = (videoDuration: number) => {
  console.log('SELECTOR makeSecondsArray called with params ', videoDuration);
  return Array.from(Array(sizeOfSecondsArray(videoDuration)).keys()).map((second) => formatXAxisValue(second));
};

const HIGHLIGHTS_SERIE_ID = 'All Highlights';
const PLAYING_SERIE_ID = 'Playing';
const THRESHOLD_SERIE_ID = 'Threshold';
const CURRENT_HIGHLIGHT_SERIE_ID = 'Selected Highlight';
const NO_NEW_OPTIONS = {};
export const FAKE_SERIES = [PLAYING_SERIE_ID, HIGHLIGHTS_SERIE_ID, THRESHOLD_SERIE_ID, CURRENT_HIGHLIGHT_SERIE_ID];
export const FAKE_SERIES_EXCLUDED_FROM_REPORTING = [PLAYING_SERIE_ID, HIGHLIGHTS_SERIE_ID];

export type BaseChartCfg = EChartOption & {
  /**keep record of the selected streams when this echarts basic Option was generated */
  faceSelections?: StreamRef[];
  voiceSelections?: StreamRef[];
  fakeFaceSelections?: string[];
  durationThreshold?: number;
  seriesColors?: { [seriesName: string]: string };
};
export class UnifiedGraph extends React.PureComponent<IProps, IState> {
  echartsReact: ReactEcharts;
  zrClickHandlerInstalled = false;
  graphContainer: any = null;

  baseGraphStructure: BaseChartCfg = null;
  highlightsSerieIdx = -1;
  playingSerieIdx = -1;
  thresholdSerieIdx = -1;
  currentHighlightsSerieIdx = -1;
  thresholdBarPositioned = false;

  onEvents = {
    dblclick: (params) => {
      console.log('dblclick ' + params.name + ', ' + params.value, params);
      if (this.echartsReact != null) {
        // it seems the current Value o XAxis is received as param name
        // current index on XAxis is .dataIndex
        const clickedSecond = params.dataIndex;
        this.props.theVideoPlayer.seek(clickedSecond);
      }
    },
    click: (params) => {
      console.log('click  ', params);
      if (this.echartsReact != null) {
        const clickedIndex = params.dataIndex;
        const clickedSeriesIndex = params.seriesIndex;
        if (!this.state.highlightEditing) {
          if (clickedSeriesIndex === this.highlightsSerieIdx) {
            console.log('highlight clicked #', clickedIndex, ' x:y ', params.event.offsetX, ':', params.event.offsetY);
            console.log('***********************set STATE Highlight *****************');
            this.setState({
              clickSelectedHighlight: {
                selectedHighlightIdx: clickedIndex,
                offsetX: params.event.offsetX,
                offsetY: params.event.offsetY,
              },
            });
            this.dispatchActivateBrushTool();
          } else {
            console.log('*********************set STATE NULL Highlight *****************');
            this.setState({
              clickSelectedHighlight: null,
            });
          }
        }
        if (clickedSeriesIndex === this.thresholdSerieIdx) {
          console.log('threshold clicked #', clickedIndex, ' x:y ', params.event.offsetX, ':', params.event.offsetY);
          // daca   il adaug asa isi ia ca referinta poz intiala a thresholdului, aleaia ii zice 0
          // this.echartsReact.getEchartsInstance().setOption({
          //   graphic: {
          //     id: 'g1',
          //     shape: {
          //       y: params.event.offsetY,
          //     },
          //   },
          // });
        }
      }
    },
    contextmenu(params) {
      console.log('contextmenu  ', params);
    },
    mouseover(params) {
      // console.log('mouseover  ', params);
    },
    /*
    mouseover: params => {
      // vine cand trec peste un PUNCT real din grafic, nu din liniile de legatura generate intre puncte
    // console.log('mouseover ' + params.name + ', ' + params.value, params); // in dataIndex,  si in in data[0], name am secunda
      // si in .data si in .value am valorile de la momentul ala din serii
      // color: "#c4cc"
      *
componentSubType: "line"
componentType: "series"
data: (12) [25, 98.6797, 99.1879, 0.0001, 0.1574, 28.7756, 0.0669, 0.0019, 35.9745, 0.3166, 0, 80.6237]
dataIndex: 25
name: 25
seriesId: "valenceNeg.Sandberg0"
seriesIndex: 10
seriesName: "valenceNeg.Sandberg"
seriesType: "line"
type: "mouseover"*
      if (this.echartsReact != null) {
        const instance = this.echartsReact.getEchartsInstance();
      }
    }, */
    dataZoom: (params) => {
      // console.log('dataZZZoom ', params);
      if (this.echartsReact != null) {
      }
    },
    datazoom(params) {
      // console.log('datazzzoom ', params);
    },
    brushselected(params) {
      // console.log('brushselected ', params);
    },
    brush(params) {
      // console.log('brush ', params);
    },
    brushend: (params) => {
      console.log('brushend ', params);
      const areas: IArea[] =
        params.areas && params.areas.length > 0
          ? params.areas.map((area) => {
              return {
                startTime: area.coordRange[0],
                endTime: area.coordRange[1],
                pxStart: area.range[0],
                pxEnd: area.range[1],
              };
            })
          : [];
      areas.filter((area) => area.startTime !== area.endTime);
      console.log('**************set STATE Brushes *****************, beforestate: ', this.state);
      this.setState({ areasToBeAdded: areas }, () => {
        console.log('**************Finished set STATE Brushes *****************, state: ', this.state);
      });
    },
    globalcursortaken: (params) => {
      console.log('globalcursortaken ', params);
      /* if (params.key === 'dataZoomSelect' && params.dataZoomSelectActive) {
        console.log('globalcursortaken cu ZOOM ACTIVE', params);
        this.setState({ zoomOrBrushMode: true });
      } else */
      if (params.key === 'brush') {
        if (!params.brushOption || (params.brushOption && params.brushOption.brushType)) {
          console.log('globalcursortaken cu Brush ACTIVE', params);
          this.setState({ zoomOrBrushMode: true });
        } else {
          console.log('globalcursortaken cu TOOLS NOT active', params);
          this.setState({ zoomOrBrushMode: false });
          // if (this.state.highlightEditing) {
          this.abortHighlightEdits();
          // }
        }
      }
    },
    legendselectchanged: (params) => {
      console.log('legendselectchanged ', params.name, params);
      // if (
      //   (params.name && params.name === PLAYING_SERIE_ID && params.selected[PLAYING_SERIE_ID]) ||
      //   (params.name && params.name === HIGHLIGHTS_SERIE_ID && params.selected[HIGHLIGHTS_SERIE_ID]) ||
      //   (params.name && params.name === THRESHOLD_SERIE_ID && params.selected[THRESHOLD_SERIE_ID]) ||
      //   (params.name && params.name === CURRENT_HIGHLIGHT_SERIE_ID && params.selected[CURRENT_HIGHLIGHT_SERIE_ID])
      // ) {
      //   // we ceased updating the PLaying position/HL while Playing/HL serie was unselected, so it is not showing corectly unles we rerender
      //   console.log(' FORCE UPDATE');
      //   this.forceUpdate();
      // }
      // else if (FAKE_SERIES.indexOf(params.name) === -1) {
      this.announceLegendSelection(params.selected);
      //}
    },
    legendinverseselect: (params) => {
      // do not comute selection state  for Playing and HL
      console.log('    legendinverseselect  ' + params.name + ', ' + params.value, params);
      const legendSelected = {};
      legendSelected[HIGHLIGHTS_SERIE_ID] = !params.selected[HIGHLIGHTS_SERIE_ID];
      legendSelected[PLAYING_SERIE_ID] = !params.selected[PLAYING_SERIE_ID];
      legendSelected[THRESHOLD_SERIE_ID] = !params.selected[THRESHOLD_SERIE_ID];
      legendSelected[CURRENT_HIGHLIGHT_SERIE_ID] = !params.selected[CURRENT_HIGHLIGHT_SERIE_ID];

      this.echartsReact.getEchartsInstance().setOption({
        legend: {
          selected: legendSelected,
        },
      });
      // this.announceLegendSelection(params.selected);
      this.announceLegendSelection({ ...legendSelected });
    },
    // legendselected: params => {
    //   console.log('legendselectedEvent ' + params.name + ', ' + params.value, params);
    // legendunselected: params => {
    //   console.log('    legendunselectedEvent  ' + params.name + ', ' + params.value, params);
    // legendselectallEvent: params => {
    //   console.log('    legendselectallEvent  ' + params.name + ', ' + params.value, params);
  };
  onChartReadyCallback = (event) => {
    //console.log('onChartReadyCallback ', event);
    this.installZrClickHandlerIfNeeded();
  };

  zrClicked = (event) => {
    //console.log('ZRCLICK on this ', this);
    if (!event.target) {
      // No "target" means that mouse/pointer is not on
      // any of the graphic elements, which is "blank".
      // console.log('CLICKEDONBLANK ', event);
      if (this.echartsReact && this.echartsReact.getEchartsInstance()) {
        let pointInPixel = [event.event.offsetX, event.event.offsetY];
        let pointInGrid = this.echartsReact.getEchartsInstance().convertFromPixel('series', pointInPixel);
        //console.log('BLANK point in grid: ', pointInGrid);
        let clickedSecond = pointInGrid[0];
        if (clickedSecond < 0) {
          clickedSecond = 0;
        }
        this.props.theVideoPlayer.seek(Math.min(this.props.videoDuration, clickedSecond));
      }
    }
  };

  installZrClickHandlerIfNeeded = () => {
    if (!this.zrClickHandlerInstalled && this.echartsReact && this.echartsReact.getEchartsInstance()) {
      console.log('installing ZrClickHandler ', this);
      this.zrClickHandlerInstalled = true;
      this.echartsReact.getEchartsInstance().getZr().on('click', this.zrClicked);
    }
  };
  uninstallZrClickHandler = () => {
    if (this.zrClickHandlerInstalled && this.echartsReact && this.echartsReact.getEchartsInstance()) {
      console.log('uninstalling ZrClickHandler ', this);
      this.echartsReact.getEchartsInstance().getZr().off('click', this.zrClicked);
      this.zrClickHandlerInstalled = false;
    }
  };

  private readonly MARKLINE_THRESHOLD_NAME = `Threshold`;
  legendContainer: HTMLDivElement;

  constructor(props: IProps) {
    super(props);
    this.state = {
      areasToBeAdded: [],
      clickSelectedHighlight: null,
      zoomOrBrushMode: false,
      highlightEditing: null,
      isResizing: false,
      mouseX: 0,
      mouseY: 0,
    };
    console.log(' Ugraph constructor called, props ', props);
  }
  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   console.log('should comp update graph nextProps: ', nextProps, ' nextState: ', nextState);
  //   // return super.shouldComponentUpdate(nextProps, nextState, nextContext);
  //   return true;
  // }

  toggleFaceDrawer = () => {
    this.props.setFaceAnalysisDrawerVisibility(!this.props.faceRightMenuOpen);
  };
  toggleVoiceDrawer = () => {
    this.props.setVoiceAnalysisDrawerVisibility(!this.props.voiceRightMenuOpen);
  };

  announceLegendSelection = (selected: any) => {
    console.warn('TODO FFS UNIFIY announceLegendSelection');
    const ref = this.legendSelected2StreamRefs(selected);
    if (ref) {
      //TODO FFS UNIFIY
      this.props.selectedFaceStreams(ref.streams, ref.fakeStreams);
    }
  };

  resetPosSelectionState() {
    console.log('***********resetPosSelectionState() *****************');
    this.setState({
      areasToBeAdded: [],
      clickSelectedHighlight: null,
    });
  }
  resetEditingSelectionState() {
    console.log('***********reset HL editing STATE *****************');
    this.setState({ highlightEditing: null });
  }
  resetState() {
    this.resetPosSelectionState();
    this.resetEditingSelectionState();
    this.dispatchClearBrushedArea();
    this.dispatchDeactivateBrushTool();
  }

  resetGraph() {
    // todo ffs maybe state
    console.log('resetGraph......');
    this.baseGraphStructure = null;
    this.thresholdBarPositioned = false;
  }

  getCrtZoomSpan = (option) => {
    if (option) {
      return {
        span: option.dataZoom[0].endValue - option.dataZoom[0].startValue,
        startValue: option.dataZoom[0].startValue,
        endValue: option.dataZoom[0].endValue,
      };
    } else {
      const val = Math.ceil(this.props.videoDuration);
      return { span: val, startValue: 0, endValue: val };
    }
  };

  recomputeZoomSpanToRender = (currentDataPoint: number, runningOptions: any) => {
    // console.log('called recomputeZoomSpanToRender ', currentDataPoint, runningOptions);
    const zoomSpan = this.getCrtZoomSpan(runningOptions);
    const isPlayingAndNotHLEditing =
      !this.state.zoomOrBrushMode && !this.state.clickSelectedHighlight && !this.state.highlightEditing && !this.props.isPaused;
    const startValue = isPlayingAndNotHLEditing ? currentDataPoint : zoomSpan.startValue;
    const endValue = isPlayingAndNotHLEditing ? currentDataPoint + zoomSpan.span : zoomSpan.endValue;
    // console.log('computeZoomSpanToRender for ', currentDataPoint, ' return ', startValue, endValue);
    return {
      startValue,
      endValue,
      span: endValue - startValue,
    };
  };

  getChartComputedOptions = () => {
    if (this.baseGraphStructure && this.echartsReact && this.echartsReact.getEchartsInstance()) {
      return this.echartsReact.getEchartsInstance().getOption();
    } else {
      return null;
    }
  };

  getVarGraphStructure = () => {
    const dataPoints = Math.ceil(this.props.videoDuration);
    const currentDataPoint = Math.floor(this.props.currentTime);
    const start = new Date().getTime();
    console.log('getVarGraphStructure called at crt ', currentDataPoint);
    const series = [];
    const highlightsMarks = this.props.highlightMarks;
    const selectedHighlightMark = this.props.selectedHighlightMark;
    const runningOptions = this.getChartComputedOptions();
    // console.log('getVarGraphStructure crtOptions ', runningOptions);
    // console.log('itemStyle:', runningOptions.series[0].itemStyle);

    const rezu: any = {};
    let modified = false;
    let newSelected = {};

    // select into legend what's selected in redux state
    if (runningOptions && runningOptions.legend && runningOptions.legend[0] && runningOptions.legend[0].selected) {
      let legendModified = false;
      let runningSelected = runningOptions.legend[0].selected;
      newSelected = { ...runningSelected };
      Object.keys(runningSelected).forEach((key) => {
        // /*if (!FAKE_SERIES.includes(key)) */ newSelected[key] = false;
        if (!this.props.isComplexityOnly) newSelected[key] = false;
      });
      this.props.faceSelections.concat(this.props.voiceSelections).forEach((streamRef) => {
        newSelected[streamRef2LegendId(streamRef)] = true;
      });
      this.props.fakeFaceSelections.forEach((fakeStream) => {
        if (!this.props.setChartsHandle || !FAKE_SERIES_EXCLUDED_FROM_REPORTING.includes(fakeStream)) {
          newSelected[fakeStream] = true;
        }
      });

      Object.keys(runningSelected).forEach((key) => {
        if (newSelected[key] !== runningSelected[key]) {
          legendModified = true;
        }
      });
      if (legendModified) {
        //todo FFS perf eventual la fiecare care e activa sa setez symbols/symbols size f(isPaused)
        modified = true;
        rezu.legend = {
          selected: newSelected,
        };
      }
    }

    if (newSelected[PLAYING_SERIE_ID]) {
      series.push({
        name: PLAYING_SERIE_ID,
        id: PLAYING_SERIE_ID,
        markArea: {
          // // left top, right
          data: [this.getDataForPlayingMarkAreas(currentDataPoint)],
        },
      });
    }
    if (newSelected[CURRENT_HIGHLIGHT_SERIE_ID]) {
      series.push({
        name: CURRENT_HIGHLIGHT_SERIE_ID,
        id: CURRENT_HIGHLIGHT_SERIE_ID,
        markArea: {
          data: selectedHighlightMark,
        },
      });
    }

    if (newSelected[HIGHLIGHTS_SERIE_ID]) {
      series.push({
        name: HIGHLIGHTS_SERIE_ID,
        id: HIGHLIGHTS_SERIE_ID,
        markArea: {
          data: this.state.highlightEditing
            ? highlightsMarks.map((mark, idx) =>
                idx === this.state.highlightEditing.selectedHighlightIdx
                  ? [{ coord: [mark[0].coord[0], 0] }, { coord: [mark[1].coord[0], 0] }]
                  : mark
              )
            : highlightsMarks,
        },
      });
    }

    if (!this.props.isComplexityOnly) {
      let thresholdSerie: any = {
        id: THRESHOLD_SERIE_ID,
        markLine: {
          data: [
            {
              name: this.MARKLINE_THRESHOLD_NAME,
              yAxis: this.props.faceThreshold,
            },
          ],
        },
      };
      series.push(thresholdSerie);
    }

    if (series.length > 0) {
      rezu.series = series;
      modified = true;
    }

    if (!this.props.isComplexityOnly && !this.thresholdBarPositioned) {
      this.thresholdBarPositioned = true;
      modified = true;
    }
    if (!this.props.isComplexityOnly) rezu.graphic = this.createDrawing();

    let dataZoom = [];
    const nextZoomSpan = this.recomputeZoomSpanToRender(currentDataPoint, runningOptions);
    const crtZoomSpan = this.getCrtZoomSpan(runningOptions);
    if (nextZoomSpan.startValue !== crtZoomSpan.startValue || nextZoomSpan.endValue !== crtZoomSpan.endValue) {
      dataZoom = [
        {
          type: 'slider',
          startValue: nextZoomSpan.startValue,
          endValue: nextZoomSpan.endValue,
          maxValueSpan: dataPoints,
        },
        {
          type: 'inside',
          startValue: nextZoomSpan.startValue,
          endValue: nextZoomSpan.endValue,
          maxValueSpan: dataPoints,
        },
      ];
      rezu.dataZoom = dataZoom;
      modified = true;
    }
    const nextToolboxborderColor = this.state.highlightEditing ? config.highlights.highlightDraftBorder : config.graph.toolBoxSelectedColor;
    const crtToolboxborderColor = runningOptions.toolbox[0].emphasis.iconStyle.borderColor;
    if (nextToolboxborderColor !== crtToolboxborderColor) {
      rezu.toolbox = {
        emphasis: {
          iconStyle: {
            borderColor: nextToolboxborderColor,
          },
        },
      };
      modified = true;
    }

    const newHLToolboxColor = this.getShowAllHLColor();
    if (newHLToolboxColor !== runningOptions.toolbox[0].feature.myToggleAllHighlights.iconStyle.borderColor) {
      const feature = {
        myToggleAllHighlights: {
          iconStyle: {
            borderColor: newHLToolboxColor,
          },
        },
      };
      if (!rezu.toolbox) {
        rezu.toolbox = {
          feature,
        };
      } else {
        rezu.toolbox.feature = feature;
      }
      modified = true;
    }

    let newAnimation = this.allowAnimation();
    if (runningOptions.animation !== newAnimation) {
      rezu.animation = newAnimation;
      modified = true;
    }

    console.log('getVarGraphStructure took ms ', new Date().getTime() - start, ' returned ', modified ? rezu : NO_NEW_OPTIONS);
    return modified ? rezu : NO_NEW_OPTIONS;
  };

  // https://echarts.apache.org/en/api.html#echartsInstance.setOption notMerge, replaceMerge - not available in echartsforreact

  seriesColors = {} as { [seriesName: string]: string };

  isGloballyConfiguredFaceStream = (stream: StreamRef) => {
    if (this.props.globalFaceCategories) {
      return Object.keys(this.props.globalFaceCategories).some((categoryName) =>
        this.props.globalFaceCategories[categoryName].includes(stream.stream)
      );
    }
    return true;
  };
  isGloballyConfiguredVoiceStream = (stream: StreamRef) => {
    if (this.props.globalVoiceCategories) {
      return Object.keys(this.props.globalVoiceCategories).some((categoryName) =>
        this.props.globalVoiceCategories[categoryName].includes(stream.stream)
      );
    }
    return true;
  };

  // this should be called when both analysesData and Duration became available for the video
  initBaseGraphStructure() {
    const start = new Date().getTime();
    const dataPoints = Math.ceil(this.props.videoDuration);
    const currentDataPoint = Math.floor(this.props.currentTime);

    console.log('$$$$$$$$$$$initBaseGraphStructure called at crt ', currentDataPoint);
    const source = [];
    const series = [];
    let legendSelected = null;
    source.push(this.props.secondsArray);

    const runningOptions = this.getChartComputedOptions();
    //console.log('runnign options : ', runningOptions);
    const nextZoomSpan = this.recomputeZoomSpanToRender(currentDataPoint, runningOptions);

    legendSelected = {};
    let analysesData = !this.props.isComplexityOnly
      ? this.props.faceAnalysesData
          .filter((streamInfo) => !isComplexityStream(streamInfo.streamRef))
          .filter((faceStream) => this.isGloballyConfiguredFaceStream(faceStream.streamRef))
          .concat(this.props.voiceAnalysesData.filter((voiceStream) => this.isGloballyConfiguredVoiceStream(voiceStream.streamRef)))
      : this.props.faceAnalysesData.filter((streamInfo) => isComplexityStream(streamInfo.streamRef));

    this.seriesColors = {} as { [seriesName: string]: string };
    analysesData.forEach((streamInfo, index) => {
      //if (index > 5) return; perf test , reduce known series number => +++
      //source.push(index > 3 ? [[]] : streamInfo.valuesArray); //perf test , reduce datapoints =>+
      source.push(streamInfo.valuesArray);
      let color = (this.seriesColors[streamInfo.id] = getColor(series.length)); //GGT TODO FFS PIE COLORS- use same algortihm and order
      let isFluidityChart = isFluidityStream(streamInfo.streamRef);
      let newSerie = {
        name: streamInfo.id,
        id: streamInfo.id,
        type: isFluidityChart ? 'bar' : 'line',
        smooth: true,
        // animation: true,
        seriesLayoutBy: 'row',
        emphasis: { focus: 'series' /*label: { show: true }*/ },

        tooltip: isFluidityChart
          ? {
              formatter: this.getDominantEmotionDescription(streamInfo),
              extraCssText: streamInfo.hidden ? 'text-decoration: line-through' : null,
            }
          : { extraCssText: streamInfo.hidden ? 'text-decoration: line-through' : null },

        sampling: 'none' /* todo todo GRAPHREFACTORING maybe sampling: 'average' f(zoom)*/,
        // sampling: getSampling(nextZoomSpan.span), but should be always adjusted in getVar or in zoom change

        //symbol: this.getGraphPointSymbol,

        lineStyle: { type: streamInfo.hidden ? 'dotted' : 'solid' },
        itemStyle: !isFluidityChart
          ? { color: streamInfo.hidden ? applyOpacity(color, 0.5) : color /*, opacity: 0.1 */ }
          : {
              color: this.getDominantEmotionColor(streamInfo, streamInfo.hidden),
            },

        //symbolSize: this.getGraphPointSymbolSize,
        //symbolSize: null,
        // label: {
        //   show: true,
        //   position: 'right',
        //   formatter: '{b}',
        // },
      } as any;
      if (dataPoints < /*config.graph.downSamplingTrigger*/ this.props.durationThreshold) {
        newSerie.symbolSize = this.getGraphPointSymbolSize;
        newSerie.symbol = this.getGraphPointSymbol;
      } else {
        if (config.graph.highlightCrtSymbols) {
          newSerie.symbolSize = this.getGraphPointSymbolSize;
          newSerie.symbol = this.getGraphPointSymbol;
        }
        newSerie.sampling = 'lttb';
      }
      series.push(newSerie);

      // unselect all series; if not set, echarts consider them selected
      if (!this.props.isComplexityOnly) {
        legendSelected[streamInfo.id] = false;
      } else {
        legendSelected[streamInfo.id] = true;
      }
    });
    if (!this.props.isComplexityOnly) {
      this.props.faceSelections.concat(this.props.voiceSelections).forEach((streamRef) => {
        legendSelected[streamRef2LegendId(streamRef)] = true;
      });
    }
    source.push([[]]);
    series.push(this.createPlayingSerie(this.getDataForPlayingMarkAreas(currentDataPoint)));
    this.playingSerieIdx = series.length - 1;
    legendSelected[PLAYING_SERIE_ID] = false;

    if (!this.props.isComplexityOnly) {
      source.push([[]]);
      series.push(this.createThresholdSerie());
      this.thresholdSerieIdx = series.length - 1;
      legendSelected[THRESHOLD_SERIE_ID] = false;
    }

    const selectedHighlightMark = this.props.selectedHighlightMark;
    source.push([[]]);
    series.push(this.createSelectedHighlightSerie(selectedHighlightMark));
    this.currentHighlightsSerieIdx = series.length - 1;
    legendSelected[CURRENT_HIGHLIGHT_SERIE_ID] = false;

    const highlightsMarks = this.props.highlightMarks;
    source.push([[]]);
    series.push(this.createHighlightsSerie(highlightsMarks));
    this.highlightsSerieIdx = series.length - 1;
    legendSelected[HIGHLIGHTS_SERIE_ID] = false;

    this.props.fakeFaceSelections.forEach((fakeStream) => {
      if (!this.props.setChartsHandle || !FAKE_SERIES_EXCLUDED_FROM_REPORTING.includes(fakeStream)) {
        legendSelected[fakeStream] = true;
      }
    });
    let afeature: any = {
      myOverview: {
        show: true,
        title: 'Zoom-Out',
        icon: 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',
        onclick: this.onOverviewToolClick,
      },
      brush: {
        type: ['lineX' /* 'keep', 'clear'*/],
        title: { lineX: 'Highlighting' /* keep: 'keep', clear: 'clear' */ },
      },
      myToggleAllHighlights: {
        show: true,
        icon:
          // 'path://M432.45,595.444c0,2.177-4.661,6.82-11.305,6.82c-6.475,0-11.306-4.567-11.306-6.82s4.852-6.812,11.306-6.812C427.841,588.632,432.452,593.191,432.45,595.444L432.45,595.444z M421.155,589.876c-3.009,0-5.448,2.495-5.448,5.572s2.439,5.572,5.448,5.572c3.01,0,5.449-2.495,5.449-5.572C426.604,592.371,424.165,589.876,421.155,589.876L421.155,589.876z M421.146,591.891c-1.916,0-3.47,1.589-3.47,3.549c0,1.959,1.554,3.548,3.47,3.548s3.469-1.589,3.469-3.548C424.614,593.479,423.062,591.891,421.146,591.891L421.146,591.891zM421.146,591.891',

          'path://M30.9,53.2C16.8,53.2,5.3,41.7,5.3,27.6S16.8,2,30.9,2C45,2,56.4,13.5,56.4,27.6S45,53.2,30.9,53.2z M30.9,3.5C17.6,3.5,6.8,14.4,6.8,27.6c0,13.3,10.8,24.1,24.101,24.1C44.2,51.7,55,40.9,55,27.6C54.9,14.4,44.1,3.5,30.9,3.5z M36.9,35.8c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H36c0.5,0,0.9,0.4,0.9,1V35.8z M27.8,35.8 c0,0.601-0.4,1-0.9,1h-1.3c-0.5,0-0.9-0.399-0.9-1V19.5c0-0.6,0.4-1,0.9-1H27c0.5,0,0.9,0.4,0.9,1L27.8,35.8L27.8,35.8z',

        // 'image://data:image/gif;base64,R0lGODlhEAAQAMQAAORHHOVSKudfOulrSOp3WOyDZu6QdvCchPGolfO0o/XBs/fNwfjZ0frl3/zy7////wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAkAABAALAAAAAAQABAAAAVVICSOZGlCQAosJ6mu7fiyZeKqNKToQGDsM8hBADgUXoGAiqhSvp5QAnQKGIgUhwFUYLCVDFCrKUE1lBavAViFIDlTImbKC5Gm2hB0SlBCBMQiB0UjIQA7',
        // title: 'Legend',
        // onclick: this.onToggleLegendClick,

        title: 'Show All Highlights',
        onclick: this.onToggleShowAllHighlights,
        iconStyle: {
          // borderColor: 'rgba(51, 255, 0, 1)',
          borderColor: this.getShowAllHLColor(),
        },
      },
    };

    const rez: EChartOption = {
      dataZoom: [
        {
          startValue: nextZoomSpan.startValue,
          type: 'slider',
          endValue: nextZoomSpan.endValue,
          minValueSpan: config.graph.minZoomSpan,
          maxValueSpan: dataPoints,
          orient: 'horizontal',
          moveOnMouseMove: true,
          zoomOnMouseWheel: true,
          filterMode: 'none',
          bottom: 10,
          top: '92%',
          left: '30',
        },
        {
          startValue: nextZoomSpan.startValue,
          endValue: nextZoomSpan.endValue,
          type: 'inside',
          minValueSpan: config.graph.minZoomSpan,
          maxValueSpan: dataPoints,
          orient: 'horizontal',
          moveOnMouseMove: true,
          zoomOnMouseWheel: true,
          filterMode: 'none',
        },
      ],
      graphic: this.props.isComplexityOnly ? undefined : this.createDrawing(),

      toolbox: {
        // top: 0,
        top: 0,
        right: '150',
        z: 40,
        orient: 'horizontal',
        show: true,
        emphasis: {
          iconStyle: {
            borderColor: this.state.highlightEditing ? config.highlights.highlightDraftBorder : config.graph.toolBoxSelectedColor,
            textPosition: 'bottom',
          },
        },
        feature: afeature,
      },

      brush: {
        brushMode: 'single', // brushMode: 'multiple',
        xAxisIndex: 'all',
        // brushLink: 'all',
        brushStyle: {
          borderWidth: 1,
          color: config.highlights.highlightDraftBackground,
          borderColor: config.highlights.highlightDraftBorder,
        },
        throttleType: 'debounce',
        throttleDelay: 300,
        z: 10000,
        removeOnClick: false,
      },
      tooltip: {
        trigger: 'item', // trigger: 'axis',
        axisPointer: { type: 'cross' },
        formatter: this.tooltipFormatter,
        //extraCssText: 'text-decoration: line-through',
        // position: ['100%', '50%'],        // position: ['50%', 0],
        position: function (pos, params, el, elRect, size) {
          if (params.componentType === 'markArea' || params.componentType === 'markLine') {
            el.innerHTML = '';
            el.style.width = '0%';
            el.style.height = '0%';
            el.style.backgroundColor = 'white';
            return { top: 0, right: '50%' };
          } else {
            return pos;
          }
          // var obj = { top: 0 };
          // obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          // console.log('position: ', pos, params, el, elRect, size, obj);
          // return obj;
        },
        confine: false,
        transitionDuration: 0,
        // alwaysShowContent: true,
        // showContent: true,
        // position: 'bottom',
      },
      dataset: {
        source,
      },
      xAxis: { type: 'category', boundaryGap: false },
      yAxis: {
        gridIndex: 0,
        show: true,
        boundaryGap: false /* , silent: true, triggerEvent: false */,
        // max: this.legendSelected2StreamRefs(legendSelected).streams.length > 0 ? null : STREAM_NORMALIZED_MAX_VALUE, // this used to help with repositioning the drawing (i.e. the threshold handle) correctly when maxData changes, i.e. when Complexity stream was toggled, befaore separating it i its own tab
        max: this.maxValueOrNormalizedMax, //should allow displaying default threshold bar at 100
      },
      // grid: { top: '20%', right: '50', left: '30', bottom: '18%' },
      grid: { top: '25', right: '50', left: '30', bottom: '15%' },
      series,
      animation: this.allowAnimation(),
    };
    console.log('set legend');
    // select one serie at initial render - if valid trigger config , the one configured for triggering , otherwise, the first serie (zero based)
    rez.legend = this.initLegend(legendSelected);
    console.log('$$$$$$$$$$$initBaseGraphStructure returned ', rez, ', took: ms ', new Date().getTime() - start);

    let indexesToKeep = [];
    rez.series = rez.series.filter((serie, idx) => {
      if (legendSelected[serie.id]) {
        indexesToKeep.push(idx);
        if (FAKE_SERIES.includes(serie.id)) {
          switch (serie.id) {
            case PLAYING_SERIE_ID:
              this.playingSerieIdx = indexesToKeep.length - 1;
              break;
            case HIGHLIGHTS_SERIE_ID:
              this.highlightsSerieIdx = indexesToKeep.length - 1;
              break;
            case CURRENT_HIGHLIGHT_SERIE_ID:
              this.currentHighlightsSerieIdx = indexesToKeep.length - 1;
              break;
            case THRESHOLD_SERIE_ID:
              this.thresholdSerieIdx = indexesToKeep.length - 1;
              break;
          }
        }
        return true;
      }
      return false;
    });
    rez.dataset = {
      source:
        rez.dataset && rez.dataset.source
          ? [rez.dataset.source[0]].concat(rez.dataset.source.filter((source, idx) => indexesToKeep.includes(idx - 1)))
          : [],
    };
    this.baseGraphStructure = rez;

    //poc attempt 3: give echarts just the streams selected in Redux state, not all streams
    //basically, we give echarts just the currently selected streams, because we determined that perf decreases
    //when the number of series and the size of the dataset increase, even if few or no series are rendered.
    // Whenever  selected streams are changing, we create a brand new basic Option for echarts
    /**keep record of the selected streams   when this echarts basic Option was generated */
    this.baseGraphStructure.faceSelections = this.props.faceSelections;
    this.baseGraphStructure.voiceSelections = this.props.voiceSelections;
    this.baseGraphStructure.fakeFaceSelections = this.props.fakeFaceSelections;

    this.baseGraphStructure.durationThreshold = this.props.durationThreshold;
    this.baseGraphStructure.seriesColors = this.seriesColors; //keep track of colors, needed later in Report->legend
    return this.baseGraphStructure;
  }

  allowAnimation = () => {
    if (this.props.setChartsHandle) {
      //disable animation in reporting scenarios, to avoid unpredictable chart rendering/capture times
      return false;
    }
    const dataPoints = Math.ceil(this.props.videoDuration);
    return this.props.isPaused || dataPoints < /*config.graph.downSamplingTrigger*/ this.props.durationThreshold
      ? true
      : config.graph.animation;
  };
  maxValueOrNormalizedMax = (value) => {
    return value.max < STREAM_NORMALIZED_MAX_VALUE ? STREAM_NORMALIZED_MAX_VALUE : Math.ceil(value.max); //should allow displaying default threshold bar at 100
  };
  createDrawing = () => {
    // console.log('create drawing called');
    return {
      id: 'thresholdHandle',
      type: 'rect',
      $action: 'replace',
      // bounding: 'all', todo ffs
      z: 0,
      shape: {
        width: 30,
        height: 0,
        x: 0,
        y: this.getThresholdPixelPosition(this.props.faceThreshold),
      },
      draggable: true,
      style: {
        fill: this.thresholdBarPositioned ? config.trigger.triggeredBackgroundColour : 'rgba(255, 255,255,0.1)',
        stroke: this.thresholdBarPositioned ? config.trigger.triggeredBorderColour : 'rgba(255, 255,255,0.1)',
        lineWidth: 10,
      },
      cursor: this.thresholdBarPositioned ? 'row-resize' : 'default',
      info: this.onThresholdDragging,
      ondrag: onPointDragging,
      // onclick: onClick,
      // onmouseout: onMouseout,
      // onmousemove: onMousemove,
    };
  };

  createSelectedHighlightSerie(highlightsMarks) {
    return {
      name: CURRENT_HIGHLIGHT_SERIE_ID, // not resolved at click
      id: CURRENT_HIGHLIGHT_SERIE_ID, // not resolved at click
      type: 'line',
      smooth: true,
      seriesLayoutBy: 'row',
      emphasis: { focus: 'series' },
      symbol: 'diamond',
      // silent: true,
      itemStyle: {
        opacity: 1,
        color: !this.props.setChartsHandle ? config.highlights.selectedHighlightBackground : config.trigger.triggeredBackgroundColour,
      },
      zIndex: 1000,
      z: 1000,

      markArea: {
        data: /* this.state.highlightEditing
          ? highlightsMarks.map((mark, idx) => 
              idx === this.state.highlightEditing.selectedHighlightIdx
                ? [{ coord: [mark[0].coord[0], 0] }, { coord: [mark[1].coord[0], 0] }]
                : mark
            )
          :*/ highlightsMarks,

        silent: false,
        itemStyle: {
          opacity: 1,
          color: !this.props.setChartsHandle ? config.highlights.selectedHighlightBackground : config.trigger.triggeredBackgroundColour,
        },
        emphasis: {
          itemStyle: {
            borderColor: !this.props.setChartsHandle ? config.highlights.selectedHighlightBorder : config.trigger.triggeredBorderColour,
            borderWidth: 1,
          },
        },
      },
    };
  }

  createHighlightsSerie(highlightsMarks) {
    return {
      name: HIGHLIGHTS_SERIE_ID, // not resolved at click
      id: HIGHLIGHTS_SERIE_ID, // not resolved at click
      type: 'line',
      smooth: true,
      seriesLayoutBy: 'row',
      emphasis: { focus: 'series' },
      symbol: 'diamond',
      // silent: false,
      itemStyle: {
        opacity: 1,
        color: config.highlights.highlightBackground,
      },
      zIndex: 1001,
      z: 1001,

      // tooltip: {
      //   formatter: function (param) {
      //     param = param[0];
      //     // return [param.name + '<hr size=1 style="margin: 3px 0">', 'Open: ' + param.data[0] + '<br/>'].join('');
      //     return 'bibi';
      //   },
      // },

      markArea: {
        data: this.state.highlightEditing
          ? highlightsMarks.map((mark, idx) =>
              idx === this.state.highlightEditing.selectedHighlightIdx
                ? [{ coord: [mark[0].coord[0], 0] }, { coord: [mark[1].coord[0], 0] }]
                : mark
            )
          : highlightsMarks,

        silent: false,
        itemStyle: {
          opacity: 1,
          color: config.highlights.highlightBackground,
        },
        emphasis: {
          itemStyle: {
            borderColor: config.highlights.highlightBorder,
            borderWidth: 1,
          },
        },
      },
    };
  }

  refreshThresholdHandle = () => {
    // console.log('refreshThresholdHandle');
    if (this.echartsReact && !this.thresholdBarPositioned && !this.props.isComplexityOnly) {
      // console.log('am charts dar nu thresholdBar, fortez render');
      this.forceUpdate();
    }
  };
  componentDidMount() {
    // console.log('DID MOUNT', this.echartsReact);
    events.subscribe(TIME_WINDOW_SELECTED, this.handleTimeWindowEvent);
    this.installZrClickHandlerIfNeeded();
    this.refreshThresholdHandle();
  }
  componentWillUnmount() {
    console.log('WILL UNmount', this.echartsReact);
    events.unsubscribe(TIME_WINDOW_SELECTED, this.handleTimeWindowEvent);
    this.uninstallZrClickHandler();
  }

  complexitySelectionChanged(prevProps: IProps) {
    if (this.props.faceSelections !== prevProps.faceSelections) {
      let crtComplexityStreams = this.props.faceSelections
        .filter((streamRef) => isComplexityStream(streamRef))
        .map((ref) => streamRef2LegendId(ref))
        .sort()
        .toString();
      let prevComplexityStreams = prevProps.faceSelections
        .filter((streamRef) => isComplexityStream(streamRef))
        .map((ref) => streamRef2LegendId(ref))
        .sort()
        .toString();
      // console.log('############################### crt/prev : ', crtComplexityStreams, '/', prevComplexityStreams);
      return crtComplexityStreams !== prevComplexityStreams;
    }
    return false;
  }
  componentDidUpdate(prevProps: IProps, prevState: IState) {
    this.installZrClickHandlerIfNeeded();
    if (
      this.props.selectedVideoId !== prevProps.selectedVideoId ||
      this.props.secondsArray.length !== prevProps.secondsArray.length ||
      this.props.faceAnalysesData !== prevProps.faceAnalysesData ||
      this.props.voiceAnalysesData !== prevProps.voiceAnalysesData ||
      this.props.transcript !== prevProps.transcript ||
      this.props.highlights !== prevProps.highlights ||
      this.complexitySelectionChanged(prevProps) || //when a Complexity stream is toggled, yAxis should recompute its max and we should force rerender to forece redraw the threshold handle ( the drawing)
      this.props.tag2FaceAnalysisIdx !== prevProps.tag2FaceAnalysisIdx
    ) {
      // video was changed
      console.log('video changed, reset state');
      const wasHighlighting = this.state.zoomOrBrushMode;
      this.resetState();
      if (wasHighlighting) {
        this.dispatchActivateBrushTool(); // to preserve the Highlighting mode between HL reloads
      }
      this.resetGraph();
    } else {
      if (
        this.baseGraphStructure &&
        (prevProps.faceSelections !== this.props.faceSelections ||
          prevProps.voiceSelections !== this.props.voiceSelections ||
          prevProps.fakeFaceSelections !== this.props.fakeFaceSelections) &&
        this.state.zoomOrBrushMode &&
        prevState.zoomOrBrushMode
      ) {
        this.dispatchActivateBrushTool(); // to preserve the Highlighting mode between legend toggles
      }
    }
    this.refreshThresholdHandle();
  }
  handleTimeWindowEvent = (event) => {
    if (event.detail && event.detail.timeWindow) {
      const timeWindow = event.detail.timeWindow;
      console.log('will dispatch zoom to ', timeWindow.startTime, timeWindow.endTime);
      this.resetPosSelectionState();
      this.resetEditingSelectionState();
      this.dispatchClearBrushedArea();
      this.dispatchZoomTo(Math.floor(timeWindow.startTime), Math.ceil(timeWindow.endTime));
    }
  };

  onThresholdDragging = (thresholdLineY) => {
    // console.log('THIS on threshold dragging ', this, thresholdLineY);
    const coord = this.getThresholdLinePosition(thresholdLineY);
    this.props.setFaceThreshold(coord);
    this.props.setVoiceThreshold(coord);
  };

  render() {
    console.log('*****Ugraph RENDER*********** at crtTime ', this.props.currentTime);
    if (
      !this.props.selectedVideoId ||
      this.props.videoDuration === DURATION_UNKNOWN ||
      !this.props.faceAnalysesData ||
      /*this.props.faceAnalysesData.length === 0 ||*/
      !this.props.voiceAnalysesData ||
      this.props.voiceAnalysesData.length === 0
    ) {
      console.log('*****RENDER*********** AGraph intoarce div kior');
      return <div />;
    }
    const start = new Date().getTime();
    // console.log('*****RENDER*********** AGraph for ', this.props.currentTime);
    let legendDidntChanged =
      this.baseGraphStructure &&
      this.baseGraphStructure.faceSelections === this.props.faceSelections &&
      this.baseGraphStructure.voiceSelections === this.props.voiceSelections &&
      this.baseGraphStructure.fakeFaceSelections === this.props.fakeFaceSelections;

    let durationThresholdDidntChanged =
      this.baseGraphStructure && this.baseGraphStructure.durationThreshold === this.props.durationThreshold;

    const rez = (
      <div className="row">
        <div
          className={'col no-padding fig-video-details-left-side__chartContainer '}
          style={{
            position: 'relative',
          }}
          ref={(internalComp) => {
            this.graphContainer = internalComp;
          }}
          onDragOver={(event: any) => {
            //console.log('dragover event/X/Y', event, event.clientX, event.clientY);
            this.setState({ mouseX: event.clientX, mouseY: event.clientY });
          }}
        >
          <ReactEcharts
            ref={(e) => {
              this.echartsReact = e;
              //this.zrClickHandlerInstalled = false;
              if (this.props.setChartsHandle) {
                this.props.setChartsHandle(e);
              } // FFS POC hidden charts capture
            }}
            //poc 3
            option={legendDidntChanged && durationThresholdDidntChanged ? this.getVarGraphStructure() : this.initBaseGraphStructure()}
            notMerge={!(legendDidntChanged && durationThresholdDidntChanged)}
            style={this.props.chartStyle}
            onEvents={this.onEvents}
            theme={config.theme.name}
            onChartReady={this.onChartReadyCallback}
          />
          <div
            className="horizontal-resize-iconbox"
            id="graphResizeHandle"
            style={{
              position: 'absolute',
              bottom: `${this.props.legendHeight}%`,
              zIndex: 200,
            }}
            draggable
            //onClick={this.toggleResizeState}
            onDrag={(event) => {
              //console.log('UGraph onDrag, ', event);
              this.recalcSidesSeparatorPosition(event);
            }}
            onDragStart={(event) => {
              //console.log('UGraph onDragStart, ', event);
              this.activateResizing(event);
            }}
            onDragEnd={(event) => {
              //console.log('UGraph onDragEnd, ', event);
              this.recalcSidesSeparatorPosition(event);
              this.stopResizing(event);
            }}
          >
            <svg className="fig-resize-icon">
              <use href={sprites + '#resize-icon-x'} />
            </svg>
          </div>
          <div
            className="row fig-video-details-main-legend-container"
            style={{ height: `calc(${this.props.legendHeight}% - 0px)` }}
            ref={(internalComp) => {
              this.legendContainer = internalComp;
            }}
          >
            <LegendTabs
              initialSelected={this.props.isComplexityOnly ? UnifiedGraph.TAB_IDX_UNIQ : this.props.selectedLegendTab}
              seriesColors={this.seriesColors}
              containerHeight={this.legendContainer ? this.legendContainer.clientHeight : 3 * CategoryLegend.LEGEND_ITEM_HEIGHT}
              toggleFaceDrawer={this.toggleFaceDrawer}
              toggleVoiceDrawer={this.toggleVoiceDrawer}
              handleChangeLegendTab={this.props.isComplexityOnly ? this.nop : this.props.setSelectedLegendTab}
              isComplexityOnly={this.props.isComplexityOnly}
              speakers={this.props.speakers}
              speakersFriendlyNames={this.props.speakersFriendlyNames}
              speakersHiddenStatus={this.props.analysisHiddenStatus}
            />
          </div>
        </div>
        {this.renderMenuAddHighlight()}
        {this.renderMenuEditHighlight()}
        {this.renderMenuFinishEditingHighlight()}
      </div>
    );
    console.log('*****RENDER*********** AGraph took millis ', new Date().getTime() - start);
    return rez;
  }
  static TAB_IDX_UNIQ = 0;
  nop = () => {};
  onMenuAddHighlightClose = (event: React.SyntheticEvent) => {
    this.resetPosSelectionState();
  };
  onMenuEditHighlightClose = (event: React.SyntheticEvent) => {
    this.resetPosSelectionState();
  };
  onMenuFinishEditingClose = (event: React.SyntheticEvent) => {
    this.resetPosSelectionState();
  };

  makeHlText(time) {
    if (this.props.transcript) {
      return getUtteranceTextAtMoment(this.props.transcript, time);
    } else return '';
  }

  addHighlightAndSeek(goto: boolean) {
    this.state.areasToBeAdded.forEach((area: IArea) => {
      const newHighlight: Highlight = {
        id: generateFakeId(),
        startTime: area.startTime,
        endTime: area.endTime,
        text: this.makeHlText(area.startTime) + '...' + this.makeHlText(area.endTime),
        privacy: config.highlights.getDefaultPrivacy().key,
        userID: this.props.currentUser.username,
        name: `Highlight made via Graph `, //at ` + now.toLocaleString(),
        voiceThreshold: this.props.voiceThreshold,
        faceThreshold: this.props.faceThreshold,
        selectedStreamsVoice: this.props.voiceSelections,
        selectedStreamsFace: this.props.faceSelections,
      };
      if (newHighlight.startTime < this.props.videoDuration) {
        // do not add if start > videoDuration
        if (newHighlight.endTime > this.props.videoDuration) {
          // adjust end to max videoDuration
          newHighlight.endTime = this.props.videoDuration;
        }
        this.props.addHighlight(this.props.selectedVideoId, newHighlight, this.props.currentToken);
      }
    });
    const { startTime } = this.state.areasToBeAdded[0];
    this.resetPosSelectionState();
    this.dispatchClearBrushedArea();
    // FFS todo dispatchZoom so that the highlight is zoomed?
    if (goto) {
      // this.dispatchDeactivateBrushTool();  FFS would that be useful?
      this.props.theVideoPlayer.seek(startTime);
    }
  }
  onMenuAddHighlight = (event: React.MouseEvent) => {
    this.addHighlightAndSeek(false);
  };
  onMenuAddHighlightAndGoto = (event: React.MouseEvent) => {
    this.addHighlightAndSeek(true);
  };
  onMenuFocus = (event: React.MouseEvent) => {
    this.resetPosSelectionState();
    const { startTime, endTime } = this.state.areasToBeAdded[0];
    this.dispatchClearBrushedArea();
    this.dispatchZoomTo(startTime, endTime);
    this.props.theVideoPlayer.seek(startTime);
  };

  dispatchDeactivateBrushTool = () => {
    if (this.echartsReact)
      this.echartsReact.getEchartsInstance().dispatchAction({
        type: 'takeGlobalCursor', // disable brush tool
      });
  };
  dispatchActivateBrushTool = () => {
    if (this.echartsReact)
      this.echartsReact.getEchartsInstance().dispatchAction({
        type: 'takeGlobalCursor',
        key: 'brush',
        brushOption: {
          brushType: 'lineX',
        },
      });
  };

  dispatchClearBrushedArea = () => {
    if (this.echartsReact)
      this.echartsReact.getEchartsInstance().dispatchAction({
        type: 'brush',
        areas: [],
        command: 'clear',
      });
  };
  dispatchCreateBrushArea = (startTime, endTime) => {
    if (this.echartsReact)
      this.echartsReact.getEchartsInstance().dispatchAction({
        type: 'brush',
        areas: [
          {
            xAxisIndex: 0,
            brushType: 'lineX',
            coordRange: [startTime, endTime],
          },
        ],
      });
  };

  dispatchZoomTo = (startTime, endTime) => {
    if (this.echartsReact)
      this.echartsReact.getEchartsInstance().dispatchAction({
        type: 'dataZoom',
        // data value at starting location
        startValue: startTime,
        // data value at ending location
        endValue: endTime,
      });
  };

  onToggleShowAllHighlights = () => {
    let newFakeStreams = [...this.props.fakeFaceSelections /*.concat(this.props.fakeVoiceSelections)*/];
    if (newFakeStreams.includes(HIGHLIGHTS_SERIE_ID)) {
      newFakeStreams = newFakeStreams.filter((fakeStream) => fakeStream !== HIGHLIGHTS_SERIE_ID);
    } else {
      newFakeStreams.push(HIGHLIGHTS_SERIE_ID);
    }
    this.props.selectedFaceStreams(null, newFakeStreams);
  };
  onToggleLegendClick = () => {
    const runningOptions = this.getChartComputedOptions();
    if (runningOptions) {
      this.echartsReact.getEchartsInstance().setOption({ legend: { show: !runningOptions.legend[0].show } });
    }
  };
  onOverviewToolClick = () => {
    console.log('%%%% OVERVIEW TOOL CLIKED, props ', this.props);
    this.dispatchZoomTo(0, Math.ceil(this.props.videoDuration));
  };
  onMenuDeleteHighlight = (event: React.MouseEvent) => {
    this.resetPosSelectionState();
    console.log('deleting hl ', this.state.clickSelectedHighlight);
    this.props.deleteHighlight(
      this.props.selectedVideoId,
      this.props.highlights[this.state.clickSelectedHighlight.selectedHighlightIdx].id,
      this.props.currentToken
    );
  };
  onMenuEditHighlight = (event: React.MouseEvent) => {
    console.log('editing ', this.state.clickSelectedHighlight);
    const edited = this.state.clickSelectedHighlight;
    // : { selectedHighlightIdx: clickedIndex, offsetX: params.event.offsetX, offsetY: params.event.offsetY },
    this.resetPosSelectionState();
    // console.log('editing2 ', edited);
    const { startTime, endTime } = this.props.highlights[edited.selectedHighlightIdx];
    this.setState({ highlightEditing: edited });
    this.dispatchCreateBrushArea(startTime, endTime);
  };
  onMenuCommitHighlight = (event: React.MouseEvent) => {
    console.log('commiting ', this.state.highlightEditing);
    const edited = this.state.highlightEditing;
    this.updateHighlight(this.props.highlights[edited.selectedHighlightIdx]);
    this.resetEditingSelectionState();
    this.resetPosSelectionState();
    this.dispatchClearBrushedArea();
  };

  onMenuAbortHighlight = (event: React.MouseEvent) => {
    this.abortHighlightEdits();
  };
  onMenuDiscard = (event: React.MouseEvent) => {
    console.log('aborting highlight creation', this.state.highlightEditing);
    this.resetPosSelectionState();
    this.dispatchClearBrushedArea();
  };

  abortHighlightEdits = () => {
    console.log('aborting ', this.state.highlightEditing);
    this.resetEditingSelectionState();
    this.resetPosSelectionState();
    this.dispatchClearBrushedArea();
  };

  onMenuGotoHighlight = (event: React.MouseEvent) => {
    this.resetPosSelectionState();
    console.log('goingto hl  ', this.state.clickSelectedHighlight);
    const { startTime, endTime } = this.props.highlights[this.state.clickSelectedHighlight.selectedHighlightIdx];
    this.dispatchZoomTo(startTime, endTime);
    this.props.theVideoPlayer.seek(startTime);
  };
  onMenuSelectHighlight = (event: React.MouseEvent) => {
    this.resetPosSelectionState();
    console.log('selecting hl  ', this.state.clickSelectedHighlight);

    this.props.highlightSelected(
      this.props.selectedVideoId,
      this.props.appSelectedHighlight &&
        this.props.highlights[this.state.clickSelectedHighlight.selectedHighlightIdx].id === this.props.appSelectedHighlight.id
        ? null
        : this.props.highlights[this.state.clickSelectedHighlight.selectedHighlightIdx]
    );
  };

  updateHighlight(highlight: Highlight) {
    this.state.areasToBeAdded.forEach((area: IArea) => {
      const newHighlight: Highlight = {
        ...highlight,
        startTime: area.startTime,
        endTime: area.endTime,
      };
      if (newHighlight.startTime < this.props.videoDuration) {
        // do not add if start > videoDuration
        if (newHighlight.endTime > this.props.videoDuration) {
          // adjust end to max videoDuration
          newHighlight.endTime = this.props.videoDuration;
        }
        this.props.updateHighlight(this.props.selectedVideoId, highlight.id, newHighlight, this.props.currentToken);
      }
    });
  }

  renderMenuAddHighlight = () => {
    const shouldOpen = this.state.areasToBeAdded && this.state.areasToBeAdded.length > 0 && !this.state.highlightEditing;
    // eslint-disable-next-line prefer-const
    let { left, top, height } =
      shouldOpen && this.graphContainer ? this.graphContainer.getBoundingClientRect() : { left: 0, top: 0, height: 0 };
    if (shouldOpen) {
      left = this.state.areasToBeAdded[0].pxEnd + left + 2;
      top = 0.2 * height + top;
    }
    return (
      <MenuAddHighlight
        shouldOpen={shouldOpen}
        left={left}
        top={top}
        height={height}
        onMenuAddHighlightClose={this.onMenuAddHighlightClose}
        onMenuAddHighlight={this.onMenuAddHighlight}
        onMenuAddHighlightAndGoto={this.onMenuAddHighlightAndGoto}
        onMenuFocus={this.onMenuFocus}
        onMenuDiscard={this.onMenuDiscard}
      />
    );
  };

  renderMenuEditHighlight() {
    const shouldOpen = this.state.clickSelectedHighlight !== null && !this.state.highlightEditing;
    // eslint-disable-next-line prefer-const
    let { left, top, height } =
      shouldOpen && this.graphContainer ? this.graphContainer.getBoundingClientRect() : { left: 0, top: 0, height: 0 };

    if (shouldOpen) {
      left = this.state.clickSelectedHighlight.offsetX + left + 2;
      top = this.state.clickSelectedHighlight.offsetY + top;
    }

    return (
      <MenuEditHighlight
        shouldOpen={shouldOpen}
        left={left}
        top={top}
        height={height}
        onMenuEditHighlightClose={this.onMenuEditHighlightClose}
        onMenuGotoHighlight={this.onMenuGotoHighlight}
        onMenuDeleteHighlight={this.onMenuDeleteHighlight}
        onMenuEditHighlight={this.onMenuEditHighlight}
        onMenuSelectHighlight={this.onMenuSelectHighlight}
        doSelect={
          this.props.appSelectedHighlight &&
          this.state.clickSelectedHighlight &&
          this.props.highlights[this.state.clickSelectedHighlight.selectedHighlightIdx].id === this.props.appSelectedHighlight.id
            ? false
            : true
        }
      />
    );
  }

  renderMenuFinishEditingHighlight = () => {
    const shouldOpen =
      this.state.highlightEditing !== null &&
      this.state.areasToBeAdded &&
      this.state.areasToBeAdded.length > 0 &&
      !this.state.clickSelectedHighlight;
    // eslint-disable-next-line prefer-const
    let { left, top, height } =
      shouldOpen && this.graphContainer ? this.graphContainer.getBoundingClientRect() : { left: 0, top: 0, height: 0 };
    if (shouldOpen) {
      left = this.state.areasToBeAdded[0].pxEnd + left + 2;
      top = 0.2 * height + top;
    }

    return (
      <MenuFinishEditingHighlight
        shouldOpen={shouldOpen}
        left={left}
        top={top}
        height={height}
        onMenuCommitHighlight={this.onMenuCommitHighlight}
        onMenuFinishEditingClose={this.onMenuFinishEditingClose}
        onMenuAbortHighlight={this.onMenuAbortHighlight}
      />
    );
  };

  // should be large enought to accommodate max data values, e.g. Complexity's max value.
  static MARKAREA_MAX_Y = COMPLEXITY_MAX_VALUE;
  getDataForPlayingMarkAreas = (currentDataPoint) => {
    return [
      { coord: [formatXAxisValue(currentDataPoint), UnifiedGraph.MARKAREA_MAX_Y] },
      {
        coord: [formatXAxisValue(currentDataPoint + config.graph.currentPlayingPointsWidth), 0],
      },
    ];
  };

  createPlayingSerie = (playingMark) => {
    return {
      name: PLAYING_SERIE_ID, // not resolved at click
      id: PLAYING_SERIE_ID, // not resolved at click
      type: 'line',
      seriesLayoutBy: 'row',
      silent: true,
      symbol: 'roundRect',
      itemStyle: {
        opacity: 1,
        color: config.graph.currentPlayingBorderColor,
      },
      zIndex: 1005,
      z: 1005,
      markArea: {
        animation: false,
        data: [playingMark],
        silent: true,
        itemStyle: {
          color: config.graph.currentPlayingBackground,
          opacity: config.graph.currentPlayingOpacity,
          shadowOffsetY: -4,
          shadowOffsetX: 4,
          shadowColor: config.graph.currentPlayingBorderColor,
          shadowBlur: 3.5,
          borderColor: config.graph.currentPlayingBorderColor,
          borderWidth: 1,
        },
        emphasis: {
          itemStyle: {
            borderColor: config.graph.currentPlayingBorderColor,
            borderWidth: 1,
          },
        },
      },
    };
  };

  getThresholdPixelPosition = (y) => {
    if (this.baseGraphStructure && this.echartsReact && this.echartsReact.getEchartsInstance() && y !== null) {
      // console.log('convertToPixelY: ', y, ' returns: ', this.echartsReact.getEchartsInstance().convertToPixel({ yAxisIndex: 0 }, y));
      return this.echartsReact.getEchartsInstance().convertToPixel({ yAxisIndex: 0 }, y);
    } else {
      // console.log('convertToPixelY: ', y, ' returns: O hardocded');
      return 0;
    }
  };

  getThresholdLinePosition = (pixelY) => {
    if (this.baseGraphStructure && this.echartsReact && this.echartsReact.getEchartsInstance()) {
      let rez = this.echartsReact.getEchartsInstance().convertFromPixel({ yAxisIndex: 0 }, pixelY);
      console.log('convertFromPixelY: ', pixelY, ' returns: ', rez);
      return rez > STREAM_NORMALIZED_MAX_VALUE ? STREAM_NORMALIZED_MAX_VALUE : rez < 0 ? 0 : rez;
    } else {
      console.log('convertFromPixelY: ', pixelY, ' returns: O hardocded');
      return 0;
    }
  };

  createThresholdSerie = () => {
    return {
      name: THRESHOLD_SERIE_ID, // not resolved at click
      id: THRESHOLD_SERIE_ID, // not resolved at click
      type: 'line',
      seriesLayoutBy: 'row',
      silent: false,
      symbol: 'roundRect',
      zIndex: 1004,
      z: 1004,
      itemStyle: {
        opacity: 1,
        color: config.trigger.triggeredBorderColour,
      },
      markLine: {
        data: [
          {
            name: this.MARKLINE_THRESHOLD_NAME,
            yAxis: this.props.faceThreshold,
          },
        ],
        lineStyle: {
          width: config.graph.markLineWidth,
          // type: 'dashed', dottet solid
        },
        silent: false,
      },
    };
  };

  initLegend(legendSelected) {
    return {
      selected: legendSelected,
      selector: [
        {
          type: 'all or inverse',
          title: 'o',
        },
      ],
      selectorPosition: 'start',
      left: '1',
      right: '50',
      show: false,
      bottom: 0,
      z: 30,
      // top: 0,
    };
  }
  getGraphPointSymbol = (vals, pars) => {
    if (this.props.setChartsHandle) {
      //Report context
      return config.graph.symbolRegular;
    } else return pars.dataIndex === Math.floor(this.props.currentTime) ? config.graph.symbolSelected : config.graph.symbolRegular;
  };
  // CPU killer
  getGraphPointSymbolSize = (vals, pars) => {
    if (this.props.setChartsHandle) {
      //Report context
      return config.graph.symbolSizeRegular;
    } else return pars.dataIndex === Math.floor(this.props.currentTime) ? config.graph.symbolSizeSelected : config.graph.symbolSizeRegular;
  };

  legendId2StreamRef = (id: string) => {
    if (!id) return null;
    const data = this.props.faceAnalysesData.concat(this.props.voiceAnalysesData);
    const found = data.find((faceVoiceData) => faceVoiceData.id === id);
    if (found) {
      return found.streamRef as StreamRef;
    }
  };

  legendSelected2StreamRefs = (legendSelected: any) => {
    let streams = [] as StreamRef[];

    if (legendSelected) {
      streams = Object.keys(legendSelected)
        .filter((keyName: string) => legendSelected[keyName] && -1 === FAKE_SERIES.indexOf(keyName))
        .map((keyName: string) => this.legendId2StreamRef(keyName));
    }
    let fakeStreams = [] as string[];

    if (legendSelected) {
      fakeStreams = Object.keys(legendSelected).filter((keyName: string) => legendSelected[keyName] && -1 !== FAKE_SERIES.indexOf(keyName));
    }

    return { streams: streams, fakeStreams: fakeStreams };
  };

  tooltipFormatter = (params) => {
    // console.log('formatter params : ', params);
    return [
      (config.dev.realLegendId ? `(${params.seriesName})` : '') +
        streamRef2FriendlyName(this.legendId2StreamRef(params.seriesName)) +
        '<br/>',
      params.marker + params.data[0] + ':  ' + params.data[params.seriesIndex + 1],
    ].join('');
  };

  getDominantEmotionDescription = (streamInfo: FaceVoiceData) => (param: any) => {
    let second = param.dataIndex,
      niceSecond = param.name;
    let fluidityRef = streamInfo.streamRef;
    // console.log('param/second / info: ', param, second, streamInfo);
    if (
      this.props.dominantEmotions &&
      this.props.dominantEmotions[fluidityRef.analysisIdx] &&
      this.props.dominantEmotions[fluidityRef.analysisIdx].length > second
    ) {
      //there is data for that second
      // let analysisIdx = fluidityRef.analysisIdx;
      let streams = this.props.dominantEmotions[fluidityRef.analysisIdx][second];
      //let analysisTag = fluidityRef.analysisTag;
      let analysisFriendlyTag = fluidityRef.tagFriendlyName;
      //return `<div> ${streams.toString()}(${analysisTag}): ${streamInfo.valuesArray[second]} </div>`;
      //return `${param.marker} ${streams.toString()}.${analysisFriendlyTag}: ${streamInfo.valuesArray[second]}`;
      return [
        `${FAKE_EMOTION_FLUIDITY}.` +
          (config.dev.realLegendId ? `(${param.seriesName})` : '') +
          `${analysisFriendlyTag}: ${streams.toString()}` +
          '<br/>',
        param.marker + param.data[0] + `:  ${streamInfo.valuesArray[second]}`,
      ].join('');
    } else {
      return `<div>${niceSecond}</div>`;
    }
  };
  getDominantEmotionColor = (streamInfo: FaceVoiceData, hidden: boolean) => (param: any) => {
    let second = param.dataIndex;
    let legendId = this.getDominantEmotionLegendId(second, streamInfo.streamRef);
    return legendId ? applyOpacity(this.seriesColors[legendId], hidden ? 0.5 : 1) : 'pink';
  };
  getDominantEmotionLegendId = (second: number, fluidityRef: StreamRef) => {
    if (
      this.props.dominantEmotions &&
      this.props.dominantEmotions[fluidityRef.analysisIdx] &&
      this.props.dominantEmotions[fluidityRef.analysisIdx].length > second
    ) {
      //there is data for that second
      let newRef: StreamRef = {
        analysisIdx: fluidityRef.analysisIdx,
        stream: this.props.dominantEmotions[fluidityRef.analysisIdx][second][0],
        analysisTag: fluidityRef.analysisTag,
        tagFriendlyName: fluidityRef.tagFriendlyName,
      };
      return streamRef2LegendId(newRef);
    } else {
      return null;
    }
  };
  getShowAllHLColor() {
    return this.props.fakeFaceSelections.includes(HIGHLIGHTS_SERIE_ID)
      ? getColor(5) //;config.highlights.highlightBorder
      : getColor(0);
  }

  recalcSidesSeparatorPosition = (e) => {
    //console.log('recalclegendSeparatorPos event/clientX/clientY ', e, e.clientX, e.clientY);
    const containerRelCoords = this.graphContainer ? this.graphContainer.getBoundingClientRect() : null;
    //console.log('recalclegendSeparatorPos containerCoords/y/height: ', containerRelCoords, containerRelCoords.y, containerRelCoords.height);

    const y = e.clientY ? e.clientY : this.state.mouseY; //on Firefox no coordinates clientX, clientY... are available
    const x = e.clientX ? e.clientX : this.state.mouseX;

    if (containerRelCoords.height) {
      let pos = (containerRelCoords.bottom - y) / containerRelCoords.height;
      pos = 100 * pos;
      if (pos > config.graph.maxLegendHeight) {
        console.log(`forcing legendSeparatorPos ${config.graph.maxLegendHeight}%`);
        pos = config.graph.maxLegendHeight;
      } else if (pos < config.graph.minLegendHeight) {
        //console.log('forcing min legendSeparatorPos ', UnifiedGraph.MIN_LEGEND_HEIGHT);
        pos = config.graph.minLegendHeight;
      }
      if (this.state.isResizing && !(y === 0 && x === 0) && pos >= 0 && pos !== this.props.legendHeight) {
        //console.log('recalcedlegendSeparatorPos %: ', pos);
        this.props.legendHeightUpdated(pos);
      }
    }
  };
  activateResizing = (event) => {
    console.log('activateResizeLegend ....', event);
    this.setState({ isResizing: true });
  };
  stopResizing = (event) => {
    console.log('stopResizeLegend ....', event);
    this.setState({ isResizing: false });
  };
  toggleResizeState = (event) => {
    console.log('toggleResize ....', event);
    let old = this.state.isResizing;
    this.setState({ isResizing: !old });
  };
}

const sampleHlMarkArea = [
  { coord: [formatXAxisValue(Math.floor(0)), STREAM_NORMALIZED_MAX_VALUE] },
  {
    coord: [formatXAxisValue(Math.ceil(1)), 0],
  },
];
export type HlMarkArea = typeof sampleHlMarkArea;

function computeHighlightsMarkAreas(highlights: Highlight[]): HlMarkArea[] {
  console.log('graph: computeHighlightsMarkAreas called with new SELECTED params ', highlights);
  if (highlights.length > 0) {
    const data = highlights.map((item) => {
      return [
        { coord: [formatXAxisValue(Math.floor(item.startTime)), UnifiedGraph.MARKAREA_MAX_Y] },
        {
          coord: [formatXAxisValue(Math.ceil(item.endTime)), 0],
        },
      ];
    });
    return data;
  } else return [];
}

const emptyHighlights = [];
const emptyTranscript: Datum3[] = [];
const selectorDuration = (state: IRootState) => state.video.videoDuration;
const selectorTranscript: (IRootState) => Datum3[] = (state: IRootState) => {
  //console.log('graph: SELECTOR transcript called with params ', state);
  return state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].transcript &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance &&
    state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance.data
    ? state.video.videosMap[state.video.currentVideoId].transcript.streams.utterance.data
    : emptyTranscript;
};
const selectorHighlights: (IRootState) => Highlight[] = (state: IRootState) => {
  //console.log('graph: SELECTOR HLs called with params ', state);
  return state.video.currentVideoId &&
    state.video.videosMap[state.video.currentVideoId] &&
    state.video.videosMap[state.video.currentVideoId].highlights &&
    state.video.videosMap[state.video.currentVideoId].highlights.highlights
    ? state.video.videosMap[state.video.currentVideoId].highlights.highlights
    : emptyHighlights;
};
const selectorCrtHighlight: (IRootState) => Highlight = (state: IRootState) => {
  //  console.log('graph: SELECTOR CRT HL called with params ', state);
  return state.video.currentHighlight;
};

const selectorFaceAnalyses = (state: IRootState) => {
  // console.log('graph: SELECTOR faces called with params ', state);
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].faceAnalyses;
  } else return null;
};
const selectorMetaFaceAnalysesMap = (state: IRootState) => {
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].metaFaceAnalysesMap;
  } else return null;
};
const selectorVoiceAnalyses = (state: IRootState) => {
  //console.log('graph: SELECTOR voices called with params ', state);
  if (state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]) {
    return state.video.videosMap[state.video.currentVideoId].voiceAnalyses;
  } else return null;
};
// const selectorVideo = (state: IRootState) => {
//   return state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
//     ? state.video.videosMap[state.video.currentVideoId]
//     : null;
// };
const tag2FaceAnalysisIdxSelector = (state: IRootState) =>
  state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
    ? state.video.videosMap[state.video.currentVideoId].tag2FaceAnalysisIdx
    : null;
const tag2FriendlyNameSelector = (state: IRootState) =>
  state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
    ? state.video.videosMap[state.video.currentVideoId].tag2FriendlyName
    : null;

const retrieveSpeakers = createSelector([tag2FaceAnalysisIdxSelector], (tag2FaceAnalysisIdx) =>
  tag2FaceAnalysisIdx ? Object.keys(tag2FaceAnalysisIdx) : SPEAKERS_NONE
);
const retrieveSpeakersFriendlyNames = createSelector(
  [tag2FaceAnalysisIdxSelector, tag2FriendlyNameSelector],
  (tag2FaceAnalysisIdx, tag2FriendlyName) => {
    if (tag2FaceAnalysisIdx) {
      let speakersTags = Object.keys(tag2FaceAnalysisIdx);
      return speakersTags.map((tag) => tag2FriendlyName[tag]);
    } else return SPEAKERS_NONE;
  }
);

const selectorLegendHeight = (state: IRootState) => state.utils.legendHeight;
const selectEChartStyle = createSelector([selectorLegendHeight], (legendHeight) => {
  return { height: `${100 - legendHeight}%`, width: '100%' };
});

const retrieveTimeline = createSelector([selectorDuration], (videoDuration) => makeSecondsArray(videoDuration));
// const retrieveTranscript = createSelector([selectorTranscript], (transcript) => {
//   // console.log('graph:  transcript called with SELECTED params ', transcript);
//   return transcript;
// });
const retrieveHighlightMarks = createSelector([selectorHighlights], (highlights) => computeHighlightsMarkAreas(highlights));
const retrieveSelectedHighlightMark = createSelector([selectorCrtHighlight], (highlight) =>
  computeHighlightsMarkAreas(highlight ? [highlight] : [])
);
// const retrieveHighlights = createSelector([selectorHighlights], (highlights) => {
//   // console.log('graph: retrieveHighlights called with new SELECTED params ', highlights);
//   return highlights;
// });

const retrieveFaceAnalyses = createSelector([selectorFaceAnalyses, selectorMetaFaceAnalysesMap], (analyses, metaFaceAnalysesMap) =>
  graphUtils.getAllFaceAnalysesData(analyses, metaFaceAnalysesMap)
);

const retrieveAnalysisHiddenStatus = createSelector(
  [selectorFaceAnalyses, selectorMetaFaceAnalysesMap, tag2FaceAnalysisIdxSelector],
  (analyses, metaFaceAnalysesMap, tag2FaceAnalysisIdx) => {
    let speakers = tag2FaceAnalysisIdx ? Object.keys(tag2FaceAnalysisIdx) : SPEAKERS_NONE;
    let rez = speakers.map(
      (speaker) => metaFaceAnalysesMap[analyses[tag2FaceAnalysisIdx[speaker]].urlid].status === AnalysisItemStatus.hidden
    );
    return rez;
  }
);

const retrieveVoiceAnalyses = createSelector([selectorVoiceAnalyses], (analyses) => graphUtils.getAllVoiceAnalysesData(analyses));
const retrieveDominantEmotions = createSelector([selectorFaceAnalyses], (analyses) =>
  analyses ? analyses.map((analysis) => analysis.dominantEmotion) : null
);
const approxCurrentTime = (state: IRootState) => {
  return state.video.videoDuration !== DURATION_UNKNOWN
    ? state.video.videoDuration > /*config.graph.downSamplingTrigger*/ state.utils.durationThreshold && config.graph.reduceRefresh
      ? config.graph.refreshRate * Math.floor(Math.floor(state.video.currentTime) / config.graph.refreshRate) // refresh every config.graph.refreshRate seconds for videos longer than downSamplingTrigger
      : Math.floor(state.video.currentTime) // refresh every second
    : TIME_INITIAL;
};
const SPEAKERS_NONE = [] as string[];
//Object.keys(this.props.video.tag2FaceAnalysisIdx)
const mapStateToProps = (state: IRootState) => {
  // console.log('map state to pros called with state ', state);
  return {
    selectedVideoId: state.video.currentVideoId,
    tag2FaceAnalysisIdx: tag2FaceAnalysisIdxSelector(state),
    speakers: retrieveSpeakers(state),
    speakersFriendlyNames: retrieveSpeakersFriendlyNames(state), //1:1 with speakers
    analysisHiddenStatus: retrieveAnalysisHiddenStatus(state), //1:1 with speakers
    faceAnalysesData: retrieveFaceAnalyses(state),
    voiceAnalysesData: retrieveVoiceAnalyses(state),
    dominantEmotions: retrieveDominantEmotions(state),
    highlights: selectorHighlights(state),
    highlightMarks: retrieveHighlightMarks(state),
    secondsArray: retrieveTimeline(state),
    //transcript: retrieveTranscript(state), // needed for creating default HL text
    transcript: selectorTranscript(state), // needed for creating default HL text
    currentTime: approxCurrentTime(state),
    videoDuration: state.video.videoDuration,
    currentUser: state.authentication.user,
    currentToken: state.authentication.token,
    isPaused: state.video.isPaused,
    appSelectedHighlight: state.video.currentHighlight,
    selectedHighlightMark: retrieveSelectedHighlightMark(state),
    globalFaceCategories: state.settings.allFaceCategories,
    globalVoiceCategories: state.settings.allVoiceCategories,
    voiceThreshold: state.selections.voiceThreshold,
    faceThreshold: state.selections.faceThreshold,
    voiceSelections: state.selections.selectedStreamsVoice,
    faceSelections: state.selections.selectedStreamsFace,
    selectedLegendTab: state.selections.selectedLegendTab,
    // fakeVoiceSelections: state.selections.selectedFakeStreamsVoice,
    fakeFaceSelections: state.selections.selectedFakeStreamsFace,
    faceRightMenuOpen: state.selections.faceAnalysisOptionsDrawerOpened,
    voiceRightMenuOpen: state.selections.voiceAnalysisOptionsDrawerOpened,
    windowHeight: state.utils.windowHeight, //to rerender legend
    playerHeight: state.utils.playerHeight, //to rerender legend
    legendHeight: state.utils.legendHeight,
    durationThreshold: state.utils.durationThreshold,
    chartStyle: selectEChartStyle(state),
  };
};
const mapDispatchToProps = {
  deleteHighlight,
  addHighlight,
  updateHighlight,
  setVoiceThreshold,
  setFaceThreshold,
  selectedFaceStreams,
  selectedVoiceStreams,
  highlightSelected,
  setVoiceAnalysisDrawerVisibility,
  setFaceAnalysisDrawerVisibility,
  legendHeightUpdated,
  setSelectedLegendTab,
  addImage,
};
const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(UnifiedGraph);

function onPointDragging() {
  console.log('on point dragging ', this);
  console.log('thresholdLineY ', this.position[1] + this.shape.y);
  this.info(this.position[1] + this.shape.y);
}
