/* eslint-disable no-console */
import { Grid } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import React, { Fragment } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { IRootState } from '../../../shared/reducers';
import {
  addAnnotation,
  addComment,
  changeCommentPrivacy,
  deleteAnnotation,
  deleteComment,
  updateAnnotation,
  updateComment,
} from '../../actions/figaroApiActions';
import { annotationSelected, noAnnotationSelected } from '../../actions/videoActions';
import AddComment, { IAddCommentState } from '../../components/comment/AddComment';
import CommentRenderer from '../../components/comment/CommentRenderer';
import { IVideoPlayer } from '../../components/video/VideoRenderer';
import config, { Date_Modified_Earliest, Date_Modified_Latest, Timeline_Earliest, Timeline_Latest } from '../../config/constants';
import { Annotation, Comment, toStringId } from '../../model/Video';
import { ANNOTATION_COORD_NONE } from '../../reducers/videoReducer';
import { generateFakeId } from '../../utils/utils';
import { ISelectableItem } from '../shared/BorderedSelector';
import BorderedSelectorTicks from '../shared/BorderedSelectorTicks';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import { IEditCommentState } from './EditComment';

interface IProps extends PropsFromRedux {
  theVideoPlayer: IVideoPlayer;
}
interface IState {
  filterByOpened: boolean;
  filterByItems: ISelectableItem[];

  sortByItems: ISelectableItem[];
  sortByOpened: boolean;

  deleteCommentDialogOpened: boolean;
  idCommentToDelete: string;
}
class Comments extends React.PureComponent<IProps, IState> {
  sortCriteriaListAnchor = null;
  filterCriteriaListAnchor = null;

  private static noInfoForScrolling = {
    mostRecentCommentTime: -1,
    mostRecentCommentIdx: -1,
    nextCommentTime: 1000000,
    nextCommentIdx: -1,
  };

  infoForScrolling = { ...Comments.noInfoForScrolling };

  crtGridRef = null;

  constructor(props) {
    super(props);
    this.state = {
      filterByOpened: false,
      sortByOpened: false,
      filterByItems: config.comments.commentsFilterByItems,
      sortByItems: config.comments.commentsSortByItems,
      deleteCommentDialogOpened: false,
      idCommentToDelete: null,
    };
  }

  buildCommentItemElementId = (nth: number) => {
    return `Comments_${nth}`;
  };

  handleAnnotate = (comment: Comment) => {
    // console.log('AComments handle annotate comment with  params', comment);
    this.props.theVideoPlayer.pause();
    this.props.theVideoPlayer.seek(comment.time);
    if (this.props.currentAnnotatingStatus) {
      // we were already editing an annotation
      if (this.props.currentAnnotatingStatus.video.id === this.props.selectedVideoIdString) {
        // we were editing this video
        if (this.props.currentAnnotatingStatus.comment === comment) {
          // if we were editing THIS one, save/update it
          if (comment.annotation && this.props.currentAnnotatingStatus.draftAnnotation) {
            // we had an annotation on this comment and we modified/deleted it
            if (
              this.props.currentAnnotatingStatus.draftAnnotation.x !== ANNOTATION_COORD_NONE &&
              this.props.currentAnnotatingStatus.draftAnnotation.y !== ANNOTATION_COORD_NONE
            ) {
              // this.props.videoAnnotationUpdated(this.props.selectedVideo.id, comment.id, {
              //   x: this.props.currentAnnotatingStatus.draftAnnotation.x,
              //   y: this.props.currentAnnotatingStatus.draftAnnotation.y,
              //   id: comment.annotation.id,
              // } as Annotation);
              this.props.updateAnnotation(
                this.props.selectedVideoIdString,
                comment.id,
                {
                  x: this.props.currentAnnotatingStatus.draftAnnotation.x,
                  y: this.props.currentAnnotatingStatus.draftAnnotation.y,
                  id: comment.id,
                } as Annotation,
                this.props.currentToken
              );
            } else {
              this.props.deleteAnnotation(this.props.selectedVideoIdString, comment.id, this.props.currentToken);
            }
          } else {
            // null comment.annotation
            if (this.props.currentAnnotatingStatus.draftAnnotation) {
              this.props.addAnnotation(
                this.props.selectedVideoIdString,
                comment.id,
                {
                  x: this.props.currentAnnotatingStatus.draftAnnotation.x,
                  y: this.props.currentAnnotatingStatus.draftAnnotation.y,
                  id: comment.id,
                },
                this.props.currentToken
              );

              // this.props.videoAnnotationAdded(this.props.selectedVideo.id, comment.id, {
              //   x: this.props.currentAnnotatingStatus.draftAnnotation.x,
              //   y: this.props.currentAnnotatingStatus.draftAnnotation.y,
              //   id: '' + Date(),
              // } as Annotation);
            }
          }
          this.props.noAnnotationSelected();
        } else {
          // while editing another one, we clicked Annotate on this comment
          this.props.annotationSelected(this.props.selectedVideoIdString, comment.id);
        }
      } else {
        // we were editing another video, dump taht and edit this one
        this.props.annotationSelected(this.props.selectedVideoIdString, comment.id);
      }
    } else {
      // enter edit annotation mode
      this.props.annotationSelected(this.props.selectedVideoIdString, comment.id);
    }
  };

  handleSetPrivacy = (commentId: string, privacy: string) => {
    // console.log('AComments  handle set privacy comment with  params', commentId, privacy);
    // this.props.videoCommentPrivacyChanged(this.props.selectedVideo.id, commentId, privacy);
    this.props.changeCommentPrivacy(this.props.selectedVideoIdString, commentId, privacy, this.props.currentToken);
  };

  handleDeleteComment = (commentId: string) => {
    // console.log('AComments  handle delete comment with  params', commentId);

    //this.props.deleteComment(this.props.selectedVideo.id, commentId, this.props.currentToken);
    this.setState({ deleteCommentDialogOpened: true, idCommentToDelete: commentId });
  };

  onDeleteCommentConfirmed = () => {
    this.closeDeleteCommentConfirmationDialog();
    // console.log('AComments  handle delete comment with  params', commentId);
    // this.props.videoCommentDeleted(this.props.selectedVideo.id, commentId);
    this.props.deleteComment(this.props.selectedVideoIdString, this.state.idCommentToDelete, this.props.currentToken);
  };
  closeDeleteCommentConfirmationDialog = () => {
    this.setState({ deleteCommentDialogOpened: false, idCommentToDelete: null });
  };

  renderDeleteCommentConfirmation() {
    return this.state.deleteCommentDialogOpened ? (
      <DeleteConfirmationDialog
        handleDelete={this.onDeleteCommentConfirmed}
        handleClose={this.closeDeleteCommentConfirmationDialog}
        title={'Delete Comment'}
        text={`Are you sure you want to delete the comment?`}
      />
    ) : null;
  }

  handleAddComment = (form: IAddCommentState) => {
    // console.log('AComments instance handle add comment with form params', form, ' myTime: ', this.props.currentTime);
    const newComment: Comment = {
      id: generateFakeId(),
      time: form.commentTime,
      text: form.comment,
      privacy: form.privacyOptions.find((item) => item.selected).key,
      userID: this.props.currentUser.username,
      /* annotation: null,*/
    };
    this.props.addComment(this.props.selectedVideoIdString, newComment, this.props.currentToken);
    // this.props.videoCommentAdded(this.props.selectedVideo.id, newComment);
  };
  handleEditComment = (commentId: string, values: IEditCommentState) => {
    const newComment: Comment = {
      id: commentId,
      time: Math.trunc(values.time * 100) / 100,
      text: values.text,
      privacy: values.privacyOptions.find((item) => item.selected).key,
      userID: this.props.currentUser.username,
    };
    this.props.updateComment(this.props.selectedVideoIdString, commentId, newComment, this.props.currentToken);
  };

  componentDidUpdate(prevProps, prevState) {
    if (!this.crtGridRef) return;
    if (this.props.currentAnnotatingStatus) return;
    let nodeToScrollTo: Element = null;
    let node: any = null;
    let scrollIdx = -1;
    if (config.comments.autoscroll && prevProps.currentTime !== this.props.currentTime) {
      if (this.infoForScrolling.mostRecentCommentTime >= 0) {
        scrollIdx = this.infoForScrolling.mostRecentCommentIdx;
      } else if (this.infoForScrolling.nextCommentIdx >= 0) {
        scrollIdx = this.infoForScrolling.nextCommentIdx;
      }
      if (scrollIdx >= 0) {
        const selector = this.buildCommentItemElementId(scrollIdx);
        // console.log(`should scroll, to show the ${scrollIdx}th item, to element id ${selector}`);
        node = this.crtGridRef.querySelector(`#${selector}`);
        if (node) {
          if (node.length > 1) {
            nodeToScrollTo = node[0];
          } else {
            nodeToScrollTo = node;
          }
          nodeToScrollTo.scrollIntoView({
            block: 'start',
          });
        }
      }
    }
  }
  componentDidMount() {
    this.forceUpdate(); // rerender popovers with known anchor elements
  }

  static NO_COMMENTS = (<div className="no-comments-container">No Comments</div>);

  render() {
    console.log('Comments render ', this.props);
    this.infoForScrolling = { ...Comments.noInfoForScrolling };
    if (!this.props.comments || !this.props.selectedVideoIdString) {
      return <div />;
    }
    let style = {} as React.CSSProperties;
    if (this.props.playerHeight) {
      style.height = this.computePanelHeight(this.props.playerHeight);
    }

    return (
      <>
        <Grid container className="fig-video-details-comments-main-container">
          <Grid item xs={12} className="" style={style}>
            <div className="comments-filter-container">{this.renderFiltersHeader()}</div>
            {this.renderListOfComments()}
          </Grid>

          <Grid item xs={12} className="fig-add-comment-container">
            <AddComment handleFormSubmit={this.handleAddComment} />
          </Grid>
        </Grid>
        {this.renderDeleteCommentConfirmation()}
      </>
    );
  }

  toggleFilterBy = (key: string) => {
    let newFilters: ISelectableItem[] = [...this.state.filterByItems].map((item) => {
      return { selected: item.key === key ? !item.selected : item.selected, key: item.key, displayedValue: item.displayedValue };
    });
    this.setState({ filterByItems: newFilters }, () => {
      config.comments.commentsFilterByItems = newFilters;
    });
  };
  toggleSortBy = (key: string) => {
    let existingSelected = this.state.sortByItems.find((item) => item.key === key).selected;
    if (!existingSelected) {
      let newCriteria: ISelectableItem[] = [...this.state.sortByItems].map((item) => {
        return { selected: item.key === key ? true : false, key: item.key, displayedValue: item.displayedValue };
      });
      this.setState({ sortByItems: newCriteria }, () => {
        config.comments.commentsSortByItems = newCriteria;
      });
    }
  };

  toggleSortCriteriaList = () => {
    const opened = this.state.sortByOpened;
    this.setState({ sortByOpened: !opened });
  };
  closeSortCriteriaList = () => {
    if (this.state.sortByOpened) this.setState({ sortByOpened: false });
  };

  toggleFilterCriteriaList = () => {
    const opened = this.state.filterByOpened;
    this.setState({ filterByOpened: !opened });
  };
  closeFilterCriteriaList = () => {
    if (this.state.filterByOpened) this.setState({ filterByOpened: false });
  };

  private renderFiltersHeader() {
    return (
      <div className="row">
        <FormControl
          ref={(e) => {
            this.sortCriteriaListAnchor = e;
          }}
        >
          <InputLabel id="sortby" shrink={false} required={false} className="abababa">
            Sort By
          </InputLabel>
          <Select
            onClick={this.toggleSortCriteriaList}
            native={false}
            labelId="sortby"
            id="sortby"
            value={''}
            open={this.state.sortByOpened}
            onOpen={this.toggleSortCriteriaList}
            onClose={this.toggleSortCriteriaList}
            className="col fig-modal-dialog-input fig-selection-list-pointer"
            MenuProps={{
              //TODO FFS ADI
              PaperProps: {
                elevation: 0,
                hidden: true,
              },
            }}
            SelectDisplayProps={{ style: { background: 'transparent' } }} //TODO FFS ADI
          ></Select>
          <BorderedSelectorTicks
            anchorRef={this.state.sortByOpened ? this.sortCriteriaListAnchor : null}
            items={this.state.sortByItems}
            toggleSelection={this.toggleSortBy}
            width={200}
            height={145}
            onClose={this.toggleSortCriteriaList}
          ></BorderedSelectorTicks>
        </FormControl>

        <FormControl
          ref={(e) => {
            this.filterCriteriaListAnchor = e;
          }}
        >
          <InputLabel id="privacy-search-label" shrink={false} required={false}>
            Filter By
          </InputLabel>
          <Select
            onClick={this.toggleFilterCriteriaList}
            native={false}
            labelId="privacy-search-label"
            id="privacy-search"
            open={this.state.filterByOpened}
            //onChange={(event) => this.handleChangeSearchPrivacy(event)}
            onOpen={this.toggleFilterCriteriaList}
            onClose={this.toggleFilterCriteriaList}
            className="col fig-modal-dialog-input fig-selection-list-pointer"
            MenuProps={{
              //TODO FFS ADI
              PaperProps: { elevation: 0, hidden: true },
            }}
            SelectDisplayProps={{ style: { background: 'transparent' } }} //TODO FFS ADI
            //value={''}
            value={-1}
          ></Select>
        </FormControl>
        <BorderedSelectorTicks
          anchorRef={this.state.filterByOpened ? this.filterCriteriaListAnchor : null}
          items={this.state.filterByItems}
          toggleSelection={this.toggleFilterBy}
          width={200}
          height={140}
          onClose={this.toggleFilterCriteriaList}
        ></BorderedSelectorTicks>
      </div>
    );
  }

  /*preserves order*/
  private getFilteredComments(): Comment[] {
    const { comments } = this.props.comments;
    let selectedFilterByItems: string[] = this.state.filterByItems.filter((item) => item.selected).map((item) => item.key);
    const result: Comment[] = [];
    comments.forEach((comment) => {
      if (selectedFilterByItems.includes(comment.privacy)) {
        result.push(comment);
      }
    });
    return result;
  }

  private sortComments(comments: Comment[]): Comment[] {
    let selectedSortCriteria: string = this.state.sortByItems.find((item) => item.selected).key;
    if (selectedSortCriteria !== config.filters.DEFAULT_API_SORT_CRITERIA) {
      comments.sort((comm1, comm2) => {
        switch (selectedSortCriteria) {
          case Date_Modified_Earliest:
            return comm1.updated_at.getTime() - comm2.updated_at.getTime();
          case Date_Modified_Latest:
            return -comm1.updated_at.getTime() + comm2.updated_at.getTime();
          case Timeline_Earliest:
            return comm1.time - comm2.time;
          case Timeline_Latest:
            return -comm1.time + comm2.time;
        }
        return 0;
      });
    }
    return comments;
  }

  renderListOfComments() {
    const comments = this.sortComments(this.getFilteredComments());
    if (comments && comments.length > 0) {
      return (
        <Fragment>
          <Grid
            container
            className="comment"
            ref={(e) => {
              this.crtGridRef = e;
            }}
          >
            {comments.map((comment, idx) => {
              const ctime = comment.time;
              if (ctime > this.infoForScrolling.mostRecentCommentTime && ctime <= this.props.currentTime) {
                this.infoForScrolling.mostRecentCommentTime = ctime;
                this.infoForScrolling.mostRecentCommentIdx = idx;
              }
              if (ctime < this.infoForScrolling.nextCommentTime && ctime >= this.props.currentTime) {
                this.infoForScrolling.nextCommentTime = ctime;
                this.infoForScrolling.nextCommentIdx = idx;
              }

              return (
                <Grid key={comment.id} item xs={12} className="comment" id={this.buildCommentItemElementId(idx)}>
                  <CommentRenderer
                    comment={comment}
                    theVideoPlayer={this.props.theVideoPlayer}
                    handleDelete={this.handleDeleteComment}
                    handleSetPrivacy={this.handleSetPrivacy}
                    handleAnnotate={this.handleAnnotate}
                    handleEdit={this.handleEditComment}
                    isAnnotating={this.props.currentAnnotatingStatus && this.props.currentAnnotatingStatus.comment === comment}
                    isPlaying={ctime >= Math.floor(this.props.currentTime) && ctime < Math.floor(this.props.currentTime) + 1}
                    videoDuration={this.props.videoDuration}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Fragment>
      );
      // at this point,  this.infoForScrolling knows the idx for the most_recent/next grid item (comment), which we should scroll to
    } else return Comments.NO_COMMENTS;
  }

  computePanelHeight = (videoHeight: number) => {
    //TODO FFS only if player is docked, otherwise consider videoHeight=0
    //console.log('compute comments  height, for video=', videoHeight);
    // return `calc(100vh - ${videoHeight}px - 30px - 41px - 72px - 104px)`;

    //const rez = window.innerHeight - videoHeight - 30 - 41 - 72 - 104 - 30;
    const rez = window.innerHeight - videoHeight - 30 - 10 - 25 - 94 - 30 - 15 - 16; //TODO FFS ADI am mai taiat 16!!!
    //TODO FFS document.documentElement.clientHeight
    console.log('compute CommentsTab height for videoH=', videoHeight, ' rez = ', rez, 'window: ', window.innerHeight);
    return rez > 0 ? rez : 0;
  };
}

const mapStateToProps = (state: IRootState) => {
  return {
    //selectedVideoId: state.video.currentVideoId,
    selectedVideoIdString: state.video.currentVideoId ? toStringId(state.video.currentVideoId) : null,
    comments:
      state.video.currentVideoId && state.video.videosMap[state.video.currentVideoId]
        ? state.video.videosMap[state.video.currentVideoId].comments
        : null,
    currentTime: Math.floor(state.video.currentTime),
    currentUser: state.authentication.user,
    currentToken: state.authentication.token,
    currentAnnotatingStatus: state.video.currentAnnotatingStatus,
    playerHeight: state.utils.playerHeight,
    windowHeight: state.utils.windowHeight,
    videoDuration: state.video.videoDuration,
  };
};

const mapDispatchToProps = {
  // videoCommentAdded,
  // videoCommentDeleted,
  // videoCommentPrivacyChanged,
  // videoAnnotationAdded,
  // videoAnnotationUpdated,
  // videoAnnotationDeleted,
  annotationSelected,
  noAnnotationSelected,

  addComment,
  deleteComment,
  updateComment,
  changeCommentPrivacy,
  addAnnotation,
  deleteAnnotation,
  updateAnnotation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(Comments);
