/* eslint-disable no-console */
import { Button, Dialog, TextField } from '@material-ui/core';
import React from 'react';
import config from '../../config/constants';
import { Highlight, StreamRef } from '../../model/Video';
import BoldCloseButton from '../shared/BoldCloseButton';
import { ISelectableItem } from '../shared/BorderedSelector';
import { SelectionList } from '../shared/SelectionList';

interface IProps {
  highlight: Highlight;
  handleFormSubmit: (form: IEditHighlightState) => void;
  handleEdit: (highlightId: string, values: IEditHighlightState) => void;
  handleClose: () => void;
  updateStreamsSelections: (highlight: Highlight) => void;
  getGlobalStreamsSelections: () => {
    voiceSelections: StreamRef[];
    faceSelections: StreamRef[];
    voiceThreshold: number;
    faceThreshold: number;
  };
  videoDuration: number;
}

export interface IEditHighlightState {
  privacyOptions: ISelectableItem[];

  description: string;
  startTime: number;
  endTime: number;
  name: string;

  voiceThreshold: number;
  faceThreshold: number;
  selectedStreamsVoice: StreamRef[];
  selectedStreamsFace: StreamRef[];

  highlightRequired: boolean;
}

export default class EditHighlight extends React.PureComponent<IProps, IEditHighlightState> {
  constructor(props: IProps) {
    super(props);
    console.log('ctor edit hl, props', this.props);
    this.state = {
      //??
      highlightRequired: true,
      name: this.props.highlight.name,
      privacyOptions: config.highlights.defaultPrivacyItems.map((item) => ({
        ...item,
        selected: item.key === this.props.highlight.privacy ? true : false,
      })),
      description: this.props.highlight.text,
      startTime: this.props.highlight.startTime,
      endTime: this.props.highlight.endTime,

      voiceThreshold: this.props.highlight.voiceThreshold,
      faceThreshold: this.props.highlight.faceThreshold,
      selectedStreamsVoice: [...this.props.highlight.selectedStreamsVoice],
      selectedStreamsFace: [...this.props.highlight.selectedStreamsFace],
    };
  }

  handleTextChange = (event) => {
    this.setState({ description: event.target.value });
  };

  handleNameChange = (event) => {
    this.setState({ name: event.target.value });
  };

  handleTypeChange = (privacyOptions: ISelectableItem[]) => {
    this.setState({ privacyOptions });
  };

  handleStartTimeChange = (event) => {
    this.setState({ startTime: event.target.value !== '' ? Math.min(event.target.value, this.props.videoDuration) : 0 });
  };
  handleEndTimeChange = (event) => {
    this.setState({ endTime: event.target.value !== '' ? Math.min(event.target.value, this.props.videoDuration) : 0 });
  };

  //TODO REUSE
  trunc2 = (a: number) => {
    return Math.trunc(a * 100) / 100;
  };

  onFormSubmit = (event) => {
    console.log('onFormSubmit', this.state);
    this.onSubmitEditHLDialog(event);
  };
  onSubmitEditHLDialog = (event) => {
    //event.preventDefault();
    console.log('onSubmitEditHLDialog', this.state);
    if (
      this.state.startTime === null ||
      this.state.endTime === null ||
      isNaN(this.state.startTime) ||
      isNaN(this.state.endTime) ||
      this.state.description.length === 0 ||
      this.state.name.length === 0
    ) {
      return;
    }
    const res: IEditHighlightState = { ...this.state };
    if (Number.parseInt(res.startTime as unknown as string, 10) > Number.parseInt(res.endTime as unknown as string, 10)) {
      res.startTime = this.state.endTime;
      res.endTime = this.state.startTime;
    }
    this.props.handleEdit(this.props.highlight.id, res);
    // this.setState(this.initState());
  };

  private initState() {
    const rez: IEditHighlightState = {
      privacyOptions: this.state && this.state.privacyOptions ? this.state.privacyOptions : config.highlights.defaultPrivacyItems,
      description: '',
      highlightRequired: false,
      startTime: null,
      endTime: null,
      name: '',
      faceThreshold: 100,
      voiceThreshold: 100,
      selectedStreamsFace: [],
      selectedStreamsVoice: [],
    };
    return rez;
  }

  componentDidUpdate(prevProps, prevState) {
    console.log('componentDidUpdate, props', this.props, ' , state: ', this.state, ', oldProps: ', prevProps, ', oldState: ', prevState);
  }

  render() {
    return (
      <Dialog open fullWidth maxWidth="sm" onClose={this.props.handleClose}>
        <form className="row" onSubmit={this.onFormSubmit}>
          <div className="col-12 fig-modal-dialog-main-container">
            <BoldCloseButton width={16} height={16} top={10} right={10} handleClose={this.props.handleClose}></BoldCloseButton>
            <div className="row">
              <TextField
                className="col-9 fig-modal-dialog-input"
                label={`COMMENT @ ${this.props.highlight.startTime}-${this.props.highlight.endTime}`}
                //TODO FFS new edited times, or initla prop times ?
                value={this.state.name}
                onChange={this.handleNameChange}
                required
                error={!this.state.name || !this.state.name.length}
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                placeholder={'Enter Comment'}
              />
              {/* <PrivacyButtons
                privacy={this.state.privacy}
                handlePrivacyChange={this.handleTypeChange}
                classNames="col-3 fig-modal-dialog-input"
              /> */}
              <SelectionList listItems={this.state.privacyOptions} selectionChanged={this.handleTypeChange} />
            </div>
            <div className="row">
              <TextField
                className="col-12 fig-modal-dialog-input"
                label="HIGHLIGHT"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                placeholder={'Enter Description'}
                value={this.state.description}
                onChange={this.handleTextChange}
                required
                error={!this.state.description || !this.state.description.length}
              />
            </div>
            <div className="row">
              <TextField
                className="col-6 fig-modal-dialog-input"
                label="HIGHLIGHT START"
                value={this.state.startTime}
                onChange={this.handleStartTimeChange}
                InputProps={{ inputProps: { min: 0, max: this.props.videoDuration } }}
                // required
                type="number"
                error={this.state.startTime === null || isNaN(this.state.startTime)}
              />
              <TextField
                className="col-6 fig-modal-dialog-input"
                label="HIGHLIGHT END"
                value={this.state.endTime}
                InputProps={{ inputProps: { min: 0, max: this.props.videoDuration } }}
                onChange={this.handleEndTimeChange}
                // required
                type="number"
                error={this.state.endTime === null || isNaN(this.state.endTime)}
              />
            </div>
            <div className="row">
              <TextField
                multiline
                disabled
                className="col-12 fig-modal-dialog-input"
                label="SELECTED STREAMS"
                InputLabelProps={{
                  shrink: true,
                  required: false,
                }}
                placeholder={'Streams'}
                value={this.getStreamsDescriptionFromState()}
              />
            </div>

            <div className="col-12 no-padding fig-space-between">
              <div className="modal-button-box ">
                <Button className="fig-button in-modal-dialog" color="primary" variant="outlined" onClick={this.onUpdateStreamsSelections}>
                  Get Current Chart Selection
                </Button>
              </div>

              <div className="modal-button-box ">
                <Button className="fig-button in-modal-dialog" color="primary" variant="outlined" onClick={this.onSubmitEditHLDialog}>
                  Save Highlight
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Dialog>
    );
  }
  onUpdateStreamsSelections = (event) => {
    //this.props.updateStreamsSelections(this.props.highlight);
    let globalStreams = this.props.getGlobalStreamsSelections();
    this.setState({
      selectedStreamsFace: globalStreams.faceSelections,
      selectedStreamsVoice: globalStreams.voiceSelections,
      faceThreshold: globalStreams.faceThreshold,
      voiceThreshold: globalStreams.voiceThreshold,
    });
  };
  getStreamsDescriptionFromState = () => {
    let faceStreams = '',
      voiceStreams = '';
    if (this.state.selectedStreamsFace && this.state.selectedStreamsFace.length) {
      //this.state.selectedStreamsFace.forEach((stream) => (faceStreams += `${stream.stream}${stream.analysisTag}, `));
      this.state.selectedStreamsFace.forEach((stream) => (faceStreams += `${stream.stream} ${stream.tagFriendlyName}, `));
    }
    if (this.state.selectedStreamsVoice && this.state.selectedStreamsVoice.length) {
      this.state.selectedStreamsVoice.forEach((stream) => (voiceStreams += `${stream.stream}, `));
    }
    return `Face Analysis:  ${faceStreams ? faceStreams : 'None'} \n\nVoice Analysis:  ${
      voiceStreams ? voiceStreams : 'None'
    } \n\nThreshold:  ${this.state.faceThreshold ? 0.01 * Math.trunc(this.state.faceThreshold * 100) : 'None'}`;
  };

  getStreamsDescriptionFromProps = () => {
    let faceStreams = '',
      voiceStreams = '';
    if (this.props.highlight.selectedStreamsFace && this.props.highlight.selectedStreamsFace.length) {
      // this.props.highlight.selectedStreamsFace.forEach((stream) => (faceStreams += `${stream.stream}${stream.analysisTag}, `));
      this.props.highlight.selectedStreamsFace.forEach((stream) => (faceStreams += `${stream.stream} ${stream.tagFriendlyName}, `));
    }
    if (this.props.highlight.selectedStreamsVoice && this.props.highlight.selectedStreamsVoice.length) {
      this.props.highlight.selectedStreamsVoice.forEach((stream) => (voiceStreams += `${stream.stream}, `));
    }
    return `Face Analysis:  ${faceStreams ? faceStreams : 'None'} \n\nVoice Analysis:  ${
      voiceStreams ? voiceStreams : 'None'
    } \n\nThreshold:  ${this.props.highlight.faceThreshold ? 0.01 * Math.trunc(this.props.highlight.faceThreshold * 100) : 'None'}`;
  };
}
