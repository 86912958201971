import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  List,
  TextField,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { FormEvent } from 'react';
import { getUsers } from '../../actions/figaroApiActions';
import { IUser } from '../../model/ApiTypes';
import BoldCloseButton from '../shared/BoldCloseButton';
import { EllipseAvatarIcon } from '../shared/EllipseAvatarIcon';

interface IState {
  term: string;
  users: IUser[];
  selectedUsers: { [userId: string]: IUser };
  selectedUncheckedAddresses: { [address: string]: string };
}
type IProps = {
  onClose: () => void;
  onShare: (addresses: string[]) => void;
  submission: number;
  token: string;
  alreadySharedTo: string[];
  setGlobalAlert: any;
};

class ShareVideo extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = { term: '', users: [], selectedUsers: {}, selectedUncheckedAddresses: {} };
  }
  componentDidMount() {
    //we no longer list all users by default
    if (false) this.queryUsers();
  }

  shareSubmission = (e) => {
    this.props.onShare(this.getAddressesToShareTo());
    e.preventDefault();
    e.stopPropagation();
  };

  getAddressesToShareTo() {
    let users = Object.values(this.state.selectedUsers).map((user) => user.email);
    let unchecked = Object.keys(this.state.selectedUncheckedAddresses);

    users.push(...unchecked);
    return users;
  }
  render() {
    return (
      <Dialog
        open
        fullWidth
        maxWidth="sm"
        onClose={this.props.onClose}
        onClick={this.onClick}
        disableEscapeKeyDown
        scroll="paper"
        className="fig-modal-dialog-main-container-v2"
      >
        <BoldCloseButton width={14} height={14} top={15} right={15} handleClose={this.props.onClose}></BoldCloseButton>

        <DialogTitle id="scroll-dialog-title" disableTypography>
          <div className="row padding-top--35">
            <div className="col-3 no-padding fig-vertical-middle">
              <span className="modal-title">Share Video</span>
            </div>
            <div className="col-9 no-padding">{this.renderSearch()}</div>
          </div>
        </DialogTitle>
        <DialogContent dividers={false}>
          <div className="row">{this.renderUsersList()}</div>
        </DialogContent>
        <Divider light />
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {this.renderSelection()}
          <div className="row modal-dialog-footer">
            {/* TODO FFS ADI 0*/}
            <div className="col-12 no-padding">
              <div className="modal-button-box">
                <Button className="fig-button in-modal-dialog fig-button__white" onClick={this.shareSubmission}>
                  SHARE
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
  renderSearch() {
    return (
      <div>
        <div className="row">
          <TextField
            className="col-11 no-padding modal-searchbox"
            placeholder="Search by email address"
            value={this.state.term}
            onChange={this.handleInputChange}
            onKeyUp={this.onKeyUp}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {/* <IconButton onClick={this.onSearch} size="small">
                    <SearchIcon fontSize="inherit" />
                  </IconButton> */}
                  <SearchIcon />
                  <IconButton className="delete-search" aria-label="clear" onClick={this.onClearSearch}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <IconButton aria-label="add" size="small" onClick={this.onAddUncheckedAddress} className="modal-add-button" disabled={false}>
            <AddIcon fontSize="inherit" />
          </IconButton>
        </div>
      </div>
    );
  }
  renderUsersList() {
    return (
      <div className="col-12 no-padding">
        <div className="modal-list-box">
          <List component="div" aria-labelledby="nested-list-subheader">
            {this.state.users.map((user, idx) => (
              <div key={user.id}>{this.renderUser(user, idx)}</div>
            ))}
          </List>
        </div>
      </div>
    );
  }
  renderSelection() {
    return (
      <div className="modal-shared-to-chips-container">
        {/* TODO FFS ADI 95%?*/}
        {Object.keys(this.state.selectedUsers).map((userId, idx) => (
          <Tooltip title={this.renderUserTooltip(this.state.selectedUsers[userId])}>
            <Chip
              //icon={<FaceIcon />}
              icon={<EllipseAvatarIcon style={{ width: 26, height: 25, fill: 'none' }} viewBox="0 0 36 35" />}
              label={this.state.selectedUsers[userId].username}
              onDelete={this.onToggleSelectUser(this.state.selectedUsers[userId])}
              variant="outlined"
              color="secondary"
            />
          </Tooltip>
        ))}
        {Object.keys(this.state.selectedUncheckedAddresses).map((address, idx) => (
          <Chip
            //icon={<FaceIcon />}
            icon={<EllipseAvatarIcon style={{ width: 26, height: 25, fill: 'none' }} viewBox="0 0 36 35" />}
            label={address}
            onDelete={this.onRemoveUncheckedAddress(address)}
            variant="outlined"
            color="secondary"
          />
        ))}
      </div>
    );
  }

  renderUserTooltip(user: IUser) {
    const el = (
      <div style={{ padding: 1, display: 'flex', alignItems: 'center' }}>
        <EllipseAvatarIcon style={{ width: 26, height: 25, fill: 'none' }} viewBox="0 0 36 35" />
        <div style={{ display: 'flex', padding: 0, flexDirection: 'column' }}>
          <div style={{ paddingLeft: 2, fontSize: 'medium' }}>{user.username}</div>
          <div style={{ paddingLeft: 2, fontSize: 'smaller' }}>{user.email}</div>
        </div>
      </div>
    );

    return el;
  }

  renderUser = (user: IUser, idx: number) => {
    const selected = this.state.selectedUsers[user.id];
    return (
      <div className="row modal-list-item" key={user.id} onClick={this.onToggleSelectUser(user)}>
        <div className="col-8 no-padding fig-vertical-middle modal-user" /*onClick={this.onToggleSelectUser(user)}*/>
          <EllipseAvatarIcon viewBox="0 0 36 35" />
          <div className="margin-left--10">
            <div className="username-name">{user.username}</div>
            <div className="username-adress">{user.email}</div>
          </div>
        </div>
        <div className="col-4 no-padding fig-vertical-middle fig-align-right">
          <Button className="fig-button fig-button--primary" color="inherit" variant="contained" disabled onClick={null} id={`${user.id}`}>
            {user && user.role ? user.role.name : ''}
          </Button>

          <Button
            className="fig-button fig-button--primary margin-left--10 fig-align-right"
            color={selected ? 'secondary' : 'primary'}
            variant={selected ? 'contained' : 'outlined'}
            // onClick={this.onToggleSelectUser(user)}
            id={`${user.id}`}
          >
            {selected ? 'Selected' : 'Select'}
          </Button>
        </div>
      </div>
    );
  };

  onToggleSelectUser = (user: IUser) => (event) => {
    let newState = { ...this.state.selectedUsers };
    if (newState[user.id]) {
      delete newState[user.id];
    } else {
      newState[user.id] = user;
    }
    this.setState({ selectedUsers: newState });
  };

  onAddUncheckedAddress = () => {
    let { term } = this.state;
    if (
      term &&
      term.length &&
      term.includes('@') &&
      !this.state.selectedUncheckedAddresses[term] &&
      !this.props.alreadySharedTo.includes(term)
    ) {
      let newAddresses = { ...this.state.selectedUncheckedAddresses };
      newAddresses[term] = term;
      this.setState({ selectedUncheckedAddresses: newAddresses });
    } else if (this.props.alreadySharedTo.includes(term) || this.state.selectedUncheckedAddresses[term]) {
      this.props.setGlobalAlert('This user already has access to this submission');
    }
  };

  onRemoveUncheckedAddress = (address) => () => {
    let newState = { ...this.state.selectedUncheckedAddresses };
    if (newState[address]) {
      delete newState[address];
      this.setState({ selectedUncheckedAddresses: newState });
    }
  };

  onClearSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.reInitialize();
  };

  private reInitialize(): void {
    this.setState(
      {
        term: '',
        users: [],
      }
      // () => this.queryUsers()
    );
  }

  handleInputChange = (event) => {
    this.setState({ term: event.target.value });
  };

  onKeyUp = (e: any) => {
    if (e.which === 13) {
      this.onSearch(e);
    } else if (e.which === 27) {
      this.onClearSearch(e);
    }
  };
  onSearch = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.queryUsers();
  };

  queryUsers = () => {
    getUsers(this.state.term, this.props.token).then(
      (users: IUser[]) =>
        this.setState({
          users: users.filter((user) => !this.props.alreadySharedTo.includes(user.email)),
        }),
      (err) => {
        console.log('getUsers err ', err);
      }
    );
  };
  onClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };
}
export default ShareVideo;
