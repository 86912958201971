import { createTheme } from '@material-ui/core/styles';
import registerTheme, { primaryColor, secondaryColor } from '../components/echarts/macarons';
import { applyOpacity } from '../utils/utils';

const theme = registerTheme();
export const getColor = (idx: number) => theme.theme.color[idx % theme.theme.color.length];

const videoConfig = {
  annotationVisibilityTime: 3,
  annotationCircle2VideoRatio: 0.06,
  annotationColor: '#0f0',
  faceTagColor: '#0ff',
  annotationColorDraft: applyOpacity(getColor(7), 0.4),
  annotationZIndex: 100,
  annotattionZIndexDraft: 100 + 1,
  faceTagZIndex: 100,
  faceTagZIndexDraft: 100 + 1,
  videoContainerZIndex: 99,
  overlaysZIndex: 300,
};

const graphConfig = {
  minZoomSpan: 3, // no smaller than currentPlayingPointsWidth
  symbolSizeRegular: 4,
  symbolSizeSelected: 18,
  symbolRegular: 'emptyCircle',
  symbolSelected: 'diamond',
  toolBoxSelectedColor: applyOpacity(getColor(1), 1),
  currentPlayingBackground: getColor(1),
  currentPlayingOpacity: 0.2,
  currentPlayingBorderColor: applyOpacity(getColor(1), 0.5),
  currentPlayingPointsWidth: 3,
  markLineWidth: 2,

  downSamplingTrigger: 5 * 60, // starting from this length, down-sample data to increase perf, increase transcript activationDistance if Analysis is showing (cfg), reduce Analysis refresh rate (cfg), disable animations (cfg)
  reduceRefresh: true, //if true and video is longer than downSamplingTrigger, refresh graph each refreshRate seconds, instead of 1 second
  highlightCrtSymbols: false, //considered for videos longer than downSamplingTrigger: if true, show increased size symbols at current time (this loads CPU), if false, show same size symbols all the time
  refreshRate: 1,
  animation: false, //considered for videos longer than downSamplingTrigger: if true, use animations, if false, do not use them

  maxLegendHeight: 50, //percentage
  minLegendHeight: 15, //percentage
  minWidth: 20,
};

/* exactly one selected */
const DEFAULT_PRIVACY_MENU_OPTIONS = [
  { key: 'public', displayedValue: 'Public', selected: true },
  { key: 'private', displayedValue: 'Private', selected: false },
  { key: 'shared', displayedValue: 'Shared', selected: false },
  { key: 'removed', displayedValue: 'Removed', selected: false },
];

const getDefaultPrivacy = () => {
  let sel = DEFAULT_PRIVACY_MENU_OPTIONS.find((item) => item.selected);
  return sel;
};

export const Date_Modified_Earliest = 'Update Earliest';
export const Date_Modified_Latest = 'Update Latest';
export const Timeline_Earliest = 'Timeline Earliest';
export const Timeline_Latest = 'Timeline Latest';

const filtersConfig = {
  DEFAULT_API_SORT_CRITERIA: Timeline_Earliest, //this matches the sort order used with the API TODO check

  defaultFilterByItems: [
    { key: 'public', displayedValue: 'Public', selected: true },
    { key: 'private', displayedValue: 'Private', selected: true },
    { key: 'shared', displayedValue: 'Shared', selected: true },
    { key: 'removed', displayedValue: 'Removed', selected: true },
  ],
  defaultSortByItems: [
    { key: Date_Modified_Earliest, displayedValue: Date_Modified_Earliest, selected: false },
    { key: Date_Modified_Latest, displayedValue: Date_Modified_Latest, selected: false },
    { key: Timeline_Earliest, displayedValue: Timeline_Earliest, selected: true },
    { key: Timeline_Latest, displayedValue: Timeline_Latest, selected: false },
  ],
};

const commentsConfig = {
  getDefaultPrivacy: getDefaultPrivacy,
  autoscroll: false, // actually this controls autoscrolling in comments views in sync with video time
  defaultPrivacyItems: DEFAULT_PRIVACY_MENU_OPTIONS,
  commentsFilterByItems: filtersConfig.defaultFilterByItems,
  commentsSortByItems: filtersConfig.defaultSortByItems,
};

const DEFAULT_RUBRIC_PRIVACY_MENU_OPTIONS = [
  { key: 'draft', displayedValue: 'Draft', selected: true },
  { key: 'public', displayedValue: 'Public', selected: false },
  { key: 'private', displayedValue: 'Private', selected: false },
];

const rubricsConfig = {
  getDefaultPrivacy: () => {
    let sel = DEFAULT_RUBRIC_PRIVACY_MENU_OPTIONS.find((item) => item.selected);
    return sel;
  },
  defaultPrivacyItems: DEFAULT_RUBRIC_PRIVACY_MENU_OPTIONS,
};
const highlightsConfig = {
  getDefaultPrivacy: getDefaultPrivacy,
  defaultPrivacyItems: DEFAULT_PRIVACY_MENU_OPTIONS,
  highlightBackground: applyOpacity(getColor(5), 0.1),
  highlightBorder: applyOpacity(getColor(5), 0.5),
  highlightDraftBackground: applyOpacity(getColor(7), 0.3),
  highlightDraftBorder: applyOpacity(getColor(7), 1),
  showHighlightsOnTranscript: false,
  // showHighlightsOnGraph: true,

  selectedHighlightBackground: applyOpacity(getColor(4), 0.3),
  selectedHighlightBorder: applyOpacity(getColor(4), 1),
  autoscroll: false, // this controls autoscrolling of  highlights in sync with video time
  highlightsFilterByItems: filtersConfig.defaultFilterByItems,
  highlightsSortByItems: filtersConfig.defaultSortByItems,

  reportCaptureBorder: 2, //how many seconds before/after highlight's window to capture in the report
};
const transcriptConfig = {
  rawVersion: false, // render&diff all utterances at each cycle
  useChunks: true, // render chunks of utterances, to improve perf
  chunkLength: 200, // how many utterances to render as one chunk
  useClauses: false, // renders consecutive utterances until punctuation mark as a single DOM element
  punctuation: [',', '.', ';', '!', '?', "'"],
  wordsSeparator: ' ', // separator to use when concatenating transcript slots
  renderAtSecond: false, // renders once per second, instead of ~x4/sec
  activationDistance: 0.1, // max distance of the  "active" utterances to the current moment; hint: use 1 if renderAtSecond, 0.1 otherwise
  distanceMultiplier: 3, // factor to multiply activationDistance with, if video is longer than downSamplingTrigger, and Graphs tab is active
  maxGap: 0.99, // max time distance  between consecutive transcript slots, without creating a Gap (fake utterance that covers the time between real utterances)
  gapFakeText: '_', // what to use as transcript text for Gaps
  lengthInGapText: false, // put transcript gap length into gap's text
  fluencyColor: applyOpacity(getColor(1), 0.8),
  fluencySeekColor: applyOpacity(getColor(13), 0.95),
  autoscroll: true, // this controls autoscrolling of  transcript in sync with video time
  rtlSubmissions: [209],
};

const thresholdConfig = {
  triggerVoice: false,
  showExceeded: true,
  triggerLevel: 100,
  triggeredBackgroundColour: applyOpacity(getColor(6), 0.2),
  triggeredBorderColour: applyOpacity(getColor(6), 0.8),
};

const themeMui = createTheme({
  palette: {
    primary: {
      // main: getColor(0),
      main: primaryColor,
    },
    secondary: {
      // main: getColor(1),
      main: secondaryColor,
    },
  },
});
function getMuiTheme() {
  return themeMui;
}
function getPopupMenuColor() {
  // return getColor(0);
  return primaryColor;
}

const submissionsConfig = {
  // coach: false, // true => getSubmissionsAskedToOthers, false => getSubmissionsToUpload
  parallel: true, // true to get info abaut a subscription with mutiple parallel requests, (false to get it in sequence - deprecated, not fully implemented, no submissionrubrics)
};

const config = {
  filters: filtersConfig,
  graph: graphConfig,

  video: videoConfig,

  comments: commentsConfig,

  trigger: thresholdConfig,

  highlights: highlightsConfig,

  transcript: transcriptConfig,

  submissions: submissionsConfig,
  rubrics: rubricsConfig,

  dev: {
    simulateReportView: false,
    reportChunkSize: 400, //render long transcript/comment as 400 words chunks
    video: false, // show currentTime as seconds on video
    zoomVideo: false, //show player height % controls on Video
    transcript: false, // show start and end mm:ss of utterance
    annotations: false, // show annotation x, y on comment
    tags: false, // show tag's corner coords on tag list
    highlights: false, // show highlight's startTime and endTime
    realLegendId: false, //show real echarts legendId on tooltips
    //idOnCard: true, //show video ID on hover over card
  },
  theme: { ...theme, getMuiTheme, getPopupMenuColor },
  report: {
    application: 'Figaro Analytics',
    reportTitle: 'Figaro Analytics Report',
  },
};
export default config;
export type CONFIG = typeof config;
export const ENV_VALUE_ENABLED = '1';
