import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Popover,
  Popper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DragHandleIcon from '@material-ui/icons/DragHandle';
//import FolderIcon from '@material-ui/icons/Folder';
//import FolderIcon from '@material-ui/icons/FolderOpen';
//import PermMediaIcon from '@material-ui/icons/PermMedia';
import FolderIcon from '@material-ui/icons/FolderSpecialOutlined';
//import FolderIcon from '@material-ui/icons/FolderSpecialOutlined';
import PersonOutline from '@material-ui/icons/PersonOutline';
import React from 'react';
import sprites from '../../../../icons/fig-icons.svg';
import { s3URL } from '../../apis/figaroApi';
import { Folder, FolderSubmission, IUser, Submission, SubmissionState } from '../../model/ApiTypes';
import { primaryColor, secondaryColor } from '../echarts/macarons';
import { ISelectableItem } from '../shared/BorderedSelector';
import BorderedSelectorSquares from '../shared/BorderedSelectorSquares';
import EditSubmission, { IEditSubmissionState } from './EditSubmission';

interface IProps {
  highlighted: boolean;
  submission: Submission;
  onSelect: any;

  allFolders: Folder[];
  taggedFolders: FolderSubmission[];

  deleteFolderSubmission: (folderSubmission: FolderSubmission) => void;
  addFolderSubmission: (folder: Folder, submission: Submission) => void;

  provided4Dragable: any;
  snapshot4Dragable: any;

  isSelectedForDnD: boolean;
  toggleDnDSelectionFor: (submission: Submission) => void;
  getSelectedForDnDCounter: () => number;
  getSelectedForDnDNames: () => string[];

  login: IUser;
  saveSubmissionDetails: (submission: Submission, values: IEditSubmissionState) => void;
  deleteSubmission: (submission: Submission) => void;
  setGlobalAlert: any;
}

interface IState {
  raised: boolean;
  showFoldersSelector: boolean;
  editDialogOpened: boolean;

  imgErr: boolean;
}

// UNCOMMENT THESE FOR CHECKING RENDERING PERF issues
// export default class SubmissionCard extends React.Component<IProps, IState> {
//   shouldComponentUpdate(nextProps, nextState, nextContext) {
//     console.log(
//       'SubmissionCard shouldComponentUpdate, id:  ',
//       this.props.submission.id,
//       ' props/state/ctxt: ',
//       this.props,
//       this.state,
//       this.context
//     );
//     console.log(
//       'SubmissionCard shouldComponentUpdate, id:  ',
//       this.props.submission.id,
//       'NEXT props/state/ctxt: ',
//       nextProps,
//       nextState,
//       nextContext
//     );
//     if (nextProps !== this.props) {
//       console.log('props differs?');
//       if (nextProps.highlighted !== this.props.highlighted) {
//         console.log('hl differss');
//         return true;
//       } else if (nextProps.taggedFolders !== this.props.taggedFolders) {
//         console.log('taggedFolders differss');
//         return true;
//       } else if (nextProps.allFolders !== this.props.allFolders) {
//         console.log('allFolders differss');
//         return true;
//       } else if (nextProps.submission !== this.props.submission) {
//         console.log('submission differss');
//         return true;
//       } else if (nextProps.providedDragableRubric !== this.props.providedDragableRubric) {
//         console.log('providedDragableRubric differss');
//         return true;
//       } else if (nextProps.snapshotDragableRubric !== this.props.snapshotDragableRubric) {
//         console.log('snapshotDragableRubric differss');
//         return true;
//       } else if (nextProps.isSelectedForDnD !== this.props.isSelectedForDnD) {
//         console.log('isSelectedForDnD differss');
//         return true;
//       }
//     }
//     if (nextState !== this.state) {
//       console.log('state differs?');

//       if (nextState.raised !== this.state.raised) {
//         console.log('raised differss');
//         return true;
//       } else if (nextState.showFolders !== this.state.showFolders) {
//         console.log('showFolders differss');
//         return true;
//       }
//     }
//     return false;
//   }

export default class SubmissionCard extends React.PureComponent<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      raised: false,
      showFoldersSelector: false,
      editDialogOpened: false,
      imgErr: false,
    };
  }

  toggleRaised = () => this.setState({ raised: !this.state.raised });
  onSubmissionCardClick = () => {
    if (this.props.submission.state === SubmissionState.deleted) {
      this.props.setGlobalAlert('Submission has been deleted, details are no longer available');
    } else {
      this.props.onSelect(this.props.submission);
    }
  };

  componentDidMount = () => {
    // console.log('SubmissionCard did mount', this.props);
  };
  componentWillUnmount = () => {
    // console.log('SubmissionCard will unmount ', this.props);
  };

  render() {
    console.log('SubmissionCard render, id:  ', this.props.submission.id, ' props/state: ', this.props, this.state);
    let editingAllowed =
      this.props.submission.createdby.id === this.props.login.id && this.props.submission.state !== SubmissionState.deleted;
    const imgSrc = this.getThumbnailSrc();
    const isS3 = this.isS3ThumbnailNotLocal(imgSrc);
    //const title = `${this.props.submission.name} ${config.dev.idOnCard ? `(video/${this.props.submission.id})` : ''}`;
    const title = `#video/${this.props.submission.id}`;
    return (
      <>
        <Card
          className="fig-video-panel-card"
          onMouseEnter={this.toggleRaised}
          onMouseLeave={this.toggleRaised}
          raised={this.state.raised}
          ref={(e) => {
            this.cardEl = e;
          }}
        >
          <CardActions className="fig-video-panel-card__actions">
            <Tooltip title="Manage folder tags" className="fig-video-panel-card__actions-label">
              <IconButton
                ref={(e) => {
                  this.anchor4FoldersTagsPanelEl = e;
                }}
                onClick={this.newToggleShowFolderTags}
                size="small"
                style={{ marginTop: '8px' }}
              >
                {/* <StarOutlineIcon color={this.state.showFolders ? 'secondary' : 'inherit'} /> */}
                <FolderIcon
                  color={this.state.showFoldersSelector ? 'secondary' : 'primary'}
                  htmlColor={this.state.showFoldersSelector ? 'secondary' : 'primary'}
                  fontSize={'small'}
                />
              </IconButton>
            </Tooltip>
            {/* {this.renderFoldersSelectorPopper()} */}
            {/* {this.renderFoldersSelectorMenu()} */}
            {/*this.renderFoldersSelectorPopover()*/}
            {this.renderFoldersSelectorBordered()}
            {editingAllowed ? (
              <Tooltip title="Edit">
                <IconButton
                  onClick={this.openEditCommentDialog}
                  size="small"
                  style={{
                    marginTop: '8px',
                    marginLeft: 0,
                    width: `28px`,
                    height: `28px`,
                  }}
                >
                  <svg className="fig-edit-icon">
                    <use href={sprites + '#edit-icon'} />
                  </svg>
                </IconButton>
              </Tooltip>
            ) : null}
            <div
              style={{
                maxWidth: 30,
                minWidth: 30,
                maxHeight: 24,
                display: 'flex',
                flexDirection: 'row',
                marginLeft: 0,
                marginRight: 0,
                paddingLeft: 0,
              }}
            >
              {this.renderDragHandle(false)}
              {this.props.snapshot4Dragable.isDragging && this.renderDragHandle(true)}
            </div>
            <Tooltip title="Toggle selection" className="fig-video-panel-card__checkbox">
              <Checkbox
                size="small"
                onClick={this.onDnDSelectClick}
                checked={this.props.isSelectedForDnD || (this.props.snapshot4Dragable && this.props.snapshot4Dragable.isDragging)}
              />
            </Tooltip>
          </CardActions>
          <CardContent style={{ /*paddingBottom: '1', paddingTop: '1',*/ cursor: 'pointer' }} onClick={this.onSubmissionCardClick}>
            {isS3 && !this.state.imgErr ? (
              <CardMedia
                component="img"
                alt="" //to avoid the broken link image
                height={140}
                src={imgSrc}
                onError={this.onImgError}
                title={title}
              />
            ) : (
              <div className="fig-card-image">
                <img
                  alt="" //to avoid the broken link image
                  //style={{ width: 64, height: 64 }}
                  src={imgSrc}
                  onError={this.onImgError}
                  title={title}
                />
              </div>
            )}
            <Typography className="fig-card-title">{this.props.submission.name}</Typography>
            <Typography component="p" className="fig-card-date">
              {this.props.submission.state === SubmissionState.available
                ? new Date(this.props.submission.submitdate).toLocaleString()
                : this.props.submission.state === SubmissionState.requested
                ? `${this.props.submission.state} (deadline: ${new Date(this.props.submission.deadline).toLocaleString()})`
                : this.props.submission.state === SubmissionState.processing
                ? `${this.props.submission.state} (updated: ${new Date(this.props.submission.updated_at).toLocaleString()})`
                : `${this.props.submission.state} (updated: ${new Date(this.props.submission.updated_at).toLocaleString()})`}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p" className="fig-card-user">
              <PersonOutline />
              {this.props.submission.user ? this.props.submission.user.username : this.props.submission.email}
            </Typography>
            <Typography variant="body2" color="secondary" component="p" className="fig-card-coach">
              <PersonOutline />
              {this.props.submission.createdby.username + ' [Coach] '}
            </Typography>
          </CardContent>
        </Card>
        {/* </div> */}
        {this.renderEditDialog()}
        {/* </Grid> */}
      </>
    );
  }

  onImgError = () => {
    this.setState({ imgErr: true });
  };
  newToggleShowFolderTags = () => {
    let crtShow = this.state.showFoldersSelector;
    this.setState(!crtShow ? { showFoldersSelector: true } : { showFoldersSelector: false });
  };

  toggleShowFolderTags = (event) => {
    let crtShow = this.state.showFoldersSelector;
    this.setState(!crtShow ? { showFoldersSelector: true } : { showFoldersSelector: false });
    event.preventDefault();
    event.stopPropagation();
  };

  openEditCommentDialog = (event) => {
    this.setState({ editDialogOpened: true });
  };
  closeEditDialog = () => {
    this.setState({ editDialogOpened: false });
  };
  deleteSubmission = () => {
    this.props.deleteSubmission(this.props.submission);
    this.closeEditDialog();
  };
  saveSubmission = (values: IEditSubmissionState) => {
    this.props.saveSubmissionDetails(this.props.submission, values);
    this.closeEditDialog();
  };

  renderEditDialog = () => {
    return this.state.editDialogOpened ? (
      <EditSubmission
        submission={this.props.submission}
        saveSubmission={this.saveSubmission}
        deleteSubmission={this.deleteSubmission}
        handleClose={this.closeEditDialog}
      ></EditSubmission>
    ) : null;
  };

  getFolderTaggingState() {
    return this.props.allFolders.map((folder) => {
      let folderSubmission = this.props.taggedFolders.find((taggedFolder) => taggedFolder.name === folder.name);
      let rez: ISelectableItem = {
        key: `${folder.id}`,
        displayedValue: folder.name,
        selected: folderSubmission ? true : false,
        object: { folder, folderSubmission },
      };
      return rez;
    });
  }
  toggleFolderTagging = (folderId, callback) => {
    let folder = callback ? callback.folder : null;
    if (folder) {
      let folderSubmission = callback ? callback.folderSubmission : null;
      console.log('folder clicked: ', folder, folderSubmission);
      if (folderSubmission) {
        //folder was tagged, untag it
        this.props.deleteFolderSubmission(folderSubmission);
      } else {
        this.props.addFolderSubmission(folder, this.props.submission);
      }
    }
  };
  renderFoldersSelectorBordered() {
    let taggingState = this.getFolderTaggingState();
    let height = 32 * (taggingState.length + 1); //TODO ADI check
    let width = this.cardEl ? this.cardEl.getBoundingClientRect().width - 36 : 250;

    return this.state.showFoldersSelector ? (
      <BorderedSelectorSquares
        anchorRef={this.state.showFoldersSelector ? this.anchor4FoldersTagsPanelEl : null}
        items={taggingState}
        toggleSelection={this.toggleFolderTagging}
        width={width}
        height={height}
        onClose={this.newToggleShowFolderTags}
        position="left"
        marginLeft={10}
      ></BorderedSelectorSquares>
    ) : null;
  }

  anchor4FoldersTagsPanelEl = null;
  cardEl = null;

  renderFoldersSelectorPopover() {
    return this.state.showFoldersSelector ? (
      <>
        <Popover
          open={true}
          anchorEl={this.anchor4FoldersTagsPanelEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={this.toggleShowFolderTags}
        >
          <div
            style={{
              padding: 10,
              borderStyle: 'solid',
              borderColor: '#CDD3DD',
              borderWidth: 0.5,
              borderRadius: 3,
              background: 'transparent', //'white',
              minHeight: 150,
              maxHeight: 300,
              minWidth: 250,
            }}
          >
            <div style={{ width: '100%', height: '100%' }}>
              {this.props.allFolders.map((folder) => {
                let folderSubmission = this.props.taggedFolders.find((taggedFolder) => taggedFolder.name === folder.name);
                return (
                  <div
                    key={folder.id + '.' + folder.name}
                    className="row"
                    style={{ maxHeight: 30, padding: 5, alignItems: 'center' /*width: this.state.width*/ }}
                  >
                    <div className="col-10">
                      <ListItemText primary={folder.name} />
                    </div>
                    <div className="col-2">
                      <Checkbox checked={folderSubmission ? true : false} onClick={this.onFolderCheckboxClick(folder, folderSubmission)} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Popover>
      </>
    ) : null;
  }

  renderFoldersSelectorPopper() {
    return this.state.showFoldersSelector ? (
      <>
        <Popper
          open={true}
          anchorEl={this.anchor4FoldersTagsPanelEl}
          placement="bottom-start"
          modifiers={{
            flip: {
              enabled: false,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: this.anchor4FoldersTagsPanelEl, //'scrollParent',
              padding: 0,
            },
          }}
        >
          <div
            style={{
              padding: 10,
              borderStyle: 'solid',
              borderColor: '#CDD3DD',
              borderWidth: 0.5,
              borderRadius: 3,
              background: 'white',
              minHeight: 40,
              // maxHeight: 400,
              //overflowY: 'scroll',
              overflowX: 'clip',
            }}
          >
            <div style={{ width: '100%' }}>
              {this.props.allFolders.map((folder) => {
                let folderSubmission = this.props.taggedFolders.find((taggedFolder) => taggedFolder.name === folder.name);
                return (
                  <div
                    key={folder.id + '.' + folder.name}
                    className="row"
                    style={{ maxHeight: 30, padding: 5, alignItems: 'center' /*width: this.state.width*/ }}
                  >
                    <div className="col-10">
                      <ListItemText primary={folder.name} />
                    </div>
                    <div className="col-2">
                      <Checkbox checked={folderSubmission ? true : false} onClick={this.onFolderCheckboxClick(folder, folderSubmission)} />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </Popper>
      </>
    ) : null;
  }
  handleFoldersSelectorClose = () => {
    // console.log('handleCLose');
    this.setState({ showFoldersSelector: false });
  };

  renderFoldersSelectorMenu() {
    return (
      <Menu anchorEl={this.anchor4FoldersTagsPanelEl} open={this.state.showFoldersSelector} onClose={this.handleFoldersSelectorClose}>
        {this.props.allFolders.map((folder) => {
          let folderSubmission = this.props.taggedFolders.find((taggedFolder) => taggedFolder.name === folder.name);
          return (
            <MenuItem key={folder.id + '.' + folder.name} value={folder.name} style={{ maxHeight: 30, paddingRight: 0 }}>
              <ListItemText primary={folder.name} />
              <Checkbox checked={folderSubmission ? true : false} onClick={this.onFolderCheckboxClick(folder, folderSubmission)} />
            </MenuItem>
          );
        })}
      </Menu>
    );
  }

  onFolderCheckboxClick = (folder: Folder, folderSubmission: FolderSubmission) => (event) => {
    // console.log('folder clicked: ', folder, folderSubmission);
    if (folderSubmission) {
      //folder was tagged, untag it
      this.props.deleteFolderSubmission(folderSubmission);
    } else {
      this.props.addFolderSubmission(folder, this.props.submission);
    }
  };

  onDnDSelectClick = (event) => {
    this.props.toggleDnDSelectionFor(this.props.submission);
  };

  renderDragHandle = (isClone: boolean) => {
    // console.log('renderDragHandle, clone: ', isClone);
    let howManyWillDrag = this.getCardsDnDCounter();
    let rez = (
      <div
        key={`itemsubmissioncard_${this.props.submission.id}.${isClone}`}
        {...(!isClone ? { ref: this.props.provided4Dragable.innerRef } : {})}
        {...(!isClone ? this.props.provided4Dragable.draggableProps : {})}
        //style={getAnimationStyle(this.props.provided4Dragable.draggableProps.style, this.props.snapshot4Dragable)}
      >
        <div
          style={{
            border: 'solid',
            borderRadius: 3,
            margin: 0,
            fontStyle: 'normal',
            //color: '#1B335E',
            // color: primaryColor,

            //borderColor: !isClone && this.props.snapshot4Dragable.isDragging ? '#E0E0E0' : 'transparent',
            borderColor: !isClone && this.props.snapshot4Dragable.isDragging ? secondaryColor : 'transparent',
            borderWidth: !isClone && this.props.snapshot4Dragable.isDragging ? 2 : 0,
            maxHeight: 30,
            // minWidth: (335 * 75) / 100,
            minWidth: 60,
            backgroundColor: !isClone && this.props.snapshot4Dragable.isDragging ? '#F2F2F2' : 'inherit',
            display: 'flex',
          }}
        >
          {!isClone && !this.props.snapshot4Dragable.isDragging ? (
            <Tooltip title={`Drag ${howManyWillDrag > 1 ? howManyWillDrag + ' Submissions' : ' Submission'} to a Folder`}>
              <IconButton size="small" {...this.props.provided4Dragable.dragHandleProps} style={{ cursor: 'grab' }}>
                <DragHandleIcon color="primary" />
              </IconButton>
            </Tooltip>
          ) : null}
          {!isClone && this.props.snapshot4Dragable.isDragging ? (
            <Tooltip title={this.renderTooltipText(this.props.getSelectedForDnDNames())} open>
              <IconButton
                size="small"
                {...this.props.provided4Dragable.dragHandleProps}
                style={{
                  cursor: 'grab',
                  padding: 0,
                  fontWeight: 800,
                  color: primaryColor,
                  fontStyle: 'normal',
                  fontSize: 13,
                  lineHeight: 16,
                }}
              >
                <AddIcon />
                {howManyWillDrag}
              </IconButton>
            </Tooltip>
          ) : null}
          {isClone ? (
            <IconButton size="small" style={{ cursor: 'grab' }}>
              <DragHandleIcon color="primary" />
            </IconButton>
          ) : null}
        </div>
      </div>
    );
    return rez;
  };

  getCardsDnDCounter() {
    //all selected for Dnd plus this one, if that was not selected
    return this.props.isSelectedForDnD ? this.props.getSelectedForDnDCounter() : 1 + this.props.getSelectedForDnDCounter();
  }
  renderTooltipText(draggingSubmissions: string[]) {
    if (!draggingSubmissions || !draggingSubmissions.length) {
      draggingSubmissions = [this.props.submission.name];
    } else {
      if (!draggingSubmissions.includes(this.props.submission.name)) {
        draggingSubmissions = [...draggingSubmissions, this.props.submission.name];
      }
    }
    const el = (
      <div>
        {draggingSubmissions.map((submission, index) => (
          <div key={index} style={{ paddingRight: '5px', paddingBottom: '5px' }}>
            {`+ ${submission}`}
          </div>
        ))}
      </div>
    );
    return el;
  }

  isS3ThumbnailNotLocal = (imgSrc: string) => {
    return imgSrc !== null && imgSrc.startsWith(s3URL);
  };
  getThumbnailSrc = () => {
    if (this.state.imgErr) {
      return `fig-picture.svg`;
    }
    switch (this.props.submission.state) {
      case SubmissionState.available: {
        if (this.props.submission.analysis.thumbnail.url) {
          return `${s3URL}${this.props.submission.uuid}/${this.props.submission.analysis.thumbnail.url}`;
        } else {
          return `fig-picture.svg`;
        }
      }
      case SubmissionState.requested: {
        return 'fig-cloud-upload.svg';
      }
      case SubmissionState.processing: {
        return `fig-cogs.svg`;
      }
      case SubmissionState.deleted: {
        return `fig-ban.svg`;
      }
    }
    console.log('UNEXPECTED SUBMISSION STATE ', this.props.submission.state);
    return `fig-picture.svg`;
  };
}

//FFS change animation destination ?
//https://github.com/atlassian/react-beautiful-dnd/blob/master/docs/guides/drop-animation.md
// function getAnimationStyle(style, snapshot) {
//   if (!snapshot.isDropAnimating) {
//     return style;
//   }
//   console.log('animation : style/snapshot ', style, snapshot);
//   const { moveTo, curve, duration } = snapshot.dropAnimation;
//   // move to the right spot
//   const translate = `translate(${moveTo.x}px, ${moveTo.y}px)`;
//   //const translate = `translate(100%, ${moveTo.y}px)`;

//   // add a bit of turn for fun
//   //const rotate = 'rotate(0.5turn)';

//   // patching the existing style
//   return {
//     ...style,
//     //transform: `${translate} ${rotate}`,
//     transform: `${translate}`,
//     // slowing down the drop because we can
//     transition: `all ${curve} ${duration + 1}s`,
//   };
// }
